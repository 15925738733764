import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { deleteMultiProjects } from "api/projects";

import { FULL_DATE_FORMAT_WITH_COMMA } from "constants/date-formats.constants";
import { ROUTES } from "constants/routes.constants";
import { INPUT_SHARED_CLASSNAME } from "constants/shared/fom-elements.constants";
import { ModalVariants } from "constants/shared/modal.constants";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import { useBoolean } from "hooks/useBoolean";

import { getOneProject, getProjectsList } from "storage/actions/project";
import { selectIsAvailableToCreateBudget } from "storage/selectors/user";
import { openPopup } from "storage/slices/popup-slice";

import { Modal } from "shared/components/modal/Modal";
import { ModalControlButtons } from "shared/components/modal/ModalControlButtons";
import Alert from "shared/components/toasts";
import { DashboardLayout } from "shared/layouts/DashboardLayout";

import { ReactComponent as SortButton } from "assets/icons/downButton.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trashIcon.svg";

import { Table } from "./Budget/BudgetListTable";
import ChannelTagsWrapper from "./Budget/ChannelTagsWrapper";
import { IndeterminateCheckbox } from "./Budget/component/CheckBox";

export default function Budget() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const projectsState = useAppSelector(state => state.project.projectList);
  const isAvailableToCreateBudget = useAppSelector(selectIsAvailableToCreateBudget);
  const [search, setSearch] = useState<string>("");
  const [sortOrder, setSortOrder] = React.useState<"asc" | "desc">("desc");
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isModalOpen, _, handleOpenModal, handleCloseModal] = useBoolean();
  const [deleting, setDeleting] = useState<boolean>(false);
  const columnHelper = createColumnHelper<any>();
  const handleViewBudget = async (rowData: any) => {
    const project_id = rowData.project_id;
    await dispatch(getOneProject(Number(project_id)));
    if (rowData.status !== "active") {
      navigate(ROUTES.editBudgetForm + "/" + project_id);
    } else {
      navigate(`/budget/${project_id}/budget-intelligence`);
    }
  };
  const columns = [
    columnHelper.accessor("select", {
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    }),
    columnHelper.accessor("name", {
      header: "Project Name",
      cell: info => <p className='text-sm font-medium text-[#1A212B] capitalize'>{info.getValue() ? info.getValue() : "Untitled"}</p>,
    }),
    columnHelper.accessor("channels", {
      header: "Channels",
      cell: info => <ChannelTagsWrapper value={info.getValue()} />,
    }),
    columnHelper.accessor("lastModified", {
      header: "Last modified",
      cell: info => {
        return dayjs(info.getValue()).format(FULL_DATE_FORMAT_WITH_COMMA);
      },
    }),
    columnHelper.accessor("date_created", {
      header: "Created",
      cell: info => dayjs(info.getValue()).format(FULL_DATE_FORMAT_WITH_COMMA),
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: info => {
        const value = info.getValue();
        return (
          <div
            className={"text-xs font-semibold px-3 py-1 rounded-full w-fit flex items-center capitalize"}
            style={{ backgroundColor: value === "active" ? "#DFFFE2" : "#F2F9FF", color: value === "active" ? "#02A613" : "#5C8DFF" }}
          >
            <div className='w-1 h-1 mr-1.5 rounded-full' style={{ backgroundColor: value === "active" ? "#02A613" : "#5C8DFF" }} />{" "}
            {value ? value : "Unknown"}
          </div>
        );
      },
    }),
    columnHelper.accessor("workflow_id", {
      id: "action",
      header: "Action",
      enableSorting: true,
      cell: info => (
        <button className='rounded-xl text-sm font-semibold text-[#766CE4]' onClick={() => handleViewBudget(info.row.original)}>
          View
        </button>
      ),
    }),
  ];
  const table = useReactTable({
    data: projectsState || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      globalFilter: search,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableRowSelection: true,
    onGlobalFilterChange: setSearch,
    globalFilterFn: "includesString",
  });

  // To get the selected rows, you can use:

  useEffect(() => {
    dispatch(getProjectsList({ page: 1 }));
  }, []);

  const handleSearch = (e: any) => {
    setSearch(e.target.value);
  };

  const handleSortButtonClick = () => {
    setSortOrder(prevSortOrder => (prevSortOrder === "desc" ? "asc" : "desc"));
    setSorting([{ id: "date_created", desc: sortOrder === "asc" }]);
  };

  const handleCreateNewBudget = () => {
    if (!isAvailableToCreateBudget) {
      dispatch(openPopup("MembershipsModal"));
      return;
    }

    dispatch(openPopup("CreateBudget"));
  };
  const deleteSelectedProjects = () => {
    const ids: number[] = [];
    selectedRows.forEach(row => {
      console.log("Row", row.original.project_id);
      if (row.original.project_id) {
        ids.push(row.original.project_id);
      }
    });
    const apiData = {
      project_ids: ids,
    };
    setDeleting(true);
    deleteMultiProjects(apiData)
      .then(res => {
        console.log("Delete Response", res);
        table.resetRowSelection();
        dispatch(getProjectsList({ page: 1 }));
        handleCloseModal();
      })
      .catch(err => {
        Alert("error", err?.message, "Error");
      })
      .finally(() => {
        setDeleting(false);
      });
  };
  const selectedRows = table.getSelectedRowModel().rows;
  const selectedRowsLength = selectedRows?.length;
  
  return (
    <DashboardLayout title='Budget Intelligence'>
      <div className='bg-[#F7F9FB] rounded-xl flex-1 min-h-full flex flex-col items-start gap-y-6 py-6 px-5'>
        <div className='relative flex items-center justify-between w-full'>
          <div className='absolute cursor-pointer inset-y-0 start-0 flex items-center ps-3 '>
            <svg style={{ cursor: "pointer" }} width='16' height='16' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z'
                stroke='#2C1A49'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path opacity='0.4' d='M22 22L20 20' stroke='#2C1A49' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
          </div>
          <input
            type='search'
            className={twMerge("max-w-[325px] py-[9px] pl-10 pr-3.5 ", INPUT_SHARED_CLASSNAME)}
            placeholder='Search budgets'
            required
            value={search}
            onChange={handleSearch}
          />
          {/* <div className='flex items-center cursor-pointer gap-x-2'>
            <div className='text-[#a6a6a6] font-medium text-sm' onClick={handleSortButtonClick}>
              {sortOrder === "desc" ? "Older" : "Newest"}
            </div>
            <SortButton />
          </div> */}
          <button
            className='flex items-center gap-x-2 bg-[#766CE4] text-white px-11 py-2 rounded-xl text-md font-bold'
            onClick={handleCreateNewBudget}
          >
            Create
          </button>
        </div>
        {/* <div className='flex items-center flex-wrap w-full gap-3'>
          <div
            className='max-w-[342px] w-full flex flex-col p-3 border-2 border-dashed border-[#766CE4] rounded-2xl cursor-pointer gap-y-3'
            onClick={handleCreateNewBudget}
          >
            <div className='flex items-center gap-x-2 text-[#766CE4] text-md font-bold'>
              <AddNew /> New budget
            </div>
            <div className='text-[#a6a6a6] text-sm font-normal'>
              Create a new budget and start to
              <br /> automate your customer touch points.
            </div>
          </div>
          {sortedProjects?.map((project: ProjectListItem) => <BudgetCard key={generateUniqueId()} project={project} />)}
        </div> */}
        <div className='w-full bg-white rounded-2xl p-6'>
          <div className='flex items-center justify-between mb-4'>
            <div className='flex items-center '>
              <p className='text-lg font-bold text-[#1F2228] pr-6 min-h-8 '>Recent projects</p>
              {selectedRowsLength > 0 && (
                <div className='pl-6 border-l border-l-[#E9EAEC] '>
                  <button onClick={handleOpenModal} className='p-1 rounded-lg border border-[#E9EAEC] '>
                    <TrashIcon height={20} width={20} />
                  </button>
                </div>
              )}
            </div>
            <div className='flex items-center cursor-pointer gap-x-2' onClick={handleSortButtonClick}>
              <div className='text-[#a6a6a6] font-medium text-sm'>{sortOrder === "desc" ? "Newest" : "Older"}</div>
              <SortButton />
            </div>
          </div>
          <Table table={table} selectedRows={selectedRows} />
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title='Are you sure?'
        titleClass='!text-[28px] !font-semibold'
        popupClass='!p-16'
        description={`Deleting ${selectedRowsLength > 1 ? "these" : "this"} ${selectedRowsLength} item${selectedRowsLength > 1 ? "s" : ""} is permanent and cannot be undone.`}
        variant={ModalVariants.FIT_CONTENT}
      >
        <ModalControlButtons
          buttonClass='px-[42px] w-fit text-nowrap'
          submitButtonText='Yes, Delete'
          handleSubmit={deleteSelectedProjects}
          handleCancel={handleCloseModal}
        />
      </Modal>
    </DashboardLayout>
  );
}
