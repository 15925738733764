import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

import { ROUTES } from "constants/routes.constants";

import { ReactComponent as LogoIcon } from "assets/icons/full-logo.svg";

interface Props {
  children: ReactNode;
}

export const AuthLayout = ({ children }: Props) => (
  <main className='flex flex-col w-full relative bg-[#F7F9FB]'>
    <div className='py-4 px-7 absolute'>
      <Link to={ROUTES.signIn}>
        <LogoIcon className='cursor-pointer' />
      </Link>
    </div>
    {children}
  </main>
);
