import React, { ReactNode } from "react";

interface Props {
  charsCount?: number;
  minCount?: number;
  children?: ReactNode;
}

export const HighlightedCountMessage = ({ charsCount = 0, minCount = 0, children }: Props) => {
  if (children) return <> {children} </>;

  if (charsCount === minCount) return <p className='text-xs font-medium text-[#26BD6C]'>All done!</p>;

  if (charsCount && charsCount < minCount)
    return (
      <p className='text-xs font-medium text-[#9A91A8]'>
        You need at least <span className='text-[#E36C74]'>{minCount - charsCount}</span> more characters
      </p>
    );

  return null;
};
