import cn from "classnames";
import React, { ButtonHTMLAttributes, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import { ButtonSharedProps } from "interfaces/shared/button.interfaces";

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement>, ButtonSharedProps {
  children: ReactNode;
}

export const ButtonWithGradient = ({ className, children, ...props }: Props) => {
  return (
    <button
      className={twMerge(
        cn(
          "w-full flex justify-center items-center p-0.5 rounded-xl bg-gradient-purple-3 hover:shadow-ring1 transition-all duration-300",

          className,
        ),
      )}
      {...props}
    >
      <div className='w-full bg-white py-[7.25px] px-6 rounded-[10px]'>
        <div className='text-transparent bg-gradient-purple-3 bg-clip-text text-center text-sm font-semibold leading-default'>
          {children}
        </div>
      </div>
    </button>
  );
};
