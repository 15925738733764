import React, { ReactNode, useEffect } from "react";

interface Props {
  open?: boolean;
  handleIsOpen?: (value: boolean) => void;
  children: ReactNode;
}

export const DropdownContentWrapper = ({ open, handleIsOpen, children }: Props) => {
  useEffect(() => {
    if (open === undefined || !handleIsOpen) return;

    handleIsOpen(open);
  }, [open]);

  return <>{children}</>;
};
