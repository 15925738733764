import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { getUserData } from "api/user";

import { AuthState } from "../slices/auth-slice";

const USER_SLICE_NAME = "user";

// TODO - use createAsyncThunk - for all actions... + rename getMe...getMeData...etc
export const getMeData = createAsyncThunk(`${USER_SLICE_NAME}/getMeData`, async (_, { dispatch, rejectWithValue }) => {
  try {
    const user = await getUserData();
    dispatch(setAuthData({ user, isAuthenticated: true }));

    return user;
  } catch (e: any) {
    dispatch(setAuthData({ user: null, isAuthenticated: false }));
    return rejectWithValue(e);
  }
});

export const setAuthData = createAction<AuthState>("SET_AUTH_DATA");
