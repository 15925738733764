import { Option } from "../interfaces/shared/form-elements.interfaces";
import { WorkflowStrategyAdditionalFields } from "../interfaces/workflows.interfaces";

export const BRAND_SELECT_OPTIONS = [
  { label: "Empowering", value: "empowering" },
  { label: "Professional", value: "professional" },
  { label: "Vibrant", value: "vibrant" },
  { label: "Creative", value: "creative" },
];

export const SOCIAL_PLATFORM_OPTIONS = [
  { label: "TikTok", value: "TikTok" },
  { label: "Instagram", value: "Instagram" },
  { label: "Facebook", value: "Facebook" },
  { label: "X / Twitter", value: "X / Twitter" },
];

export const AD_PLATFORM_OPTIONS = [
  { label: "Google Ads", value: "Google Ads" },
  { label: "Bing Ads", value: "Bing Ads" },
];

export const GOAL_SELECT_OPTIONS = [
  {
    label: "Increase Sales",
    value: "Increase Sales",
  },
  {
    label: "Increase Traffic",
    value: "Increase Traffic",
  },
  {
    label: "Lower CAC",
    value: "Lower CAC",
  },
  {
    label: "Increase CLTV",
    value: "Increase CLTV",
  },
  {
    label: "Improve CSAT",
    value: "Improve CSAT",
  },
  {
    label: "Increase Revenue",
    value: "Increase Revenue",
  },
  // {
  //   label: "Increasing profit margin",
  //   value: "Increasing profit margin",
  // },
];

export const PERFORMANCE_PLAN_SELECT_DATA: {
  [key in WorkflowStrategyAdditionalFields]?: { label: string; options: Option[]; multiple?: boolean };
} = {
  goal: { label: "What is your goal?", options: GOAL_SELECT_OPTIONS, multiple: false },
  goals: { label: "What are your goals?", options: GOAL_SELECT_OPTIONS, multiple: true },
  brand: { label: "Choose your brand tone", options: BRAND_SELECT_OPTIONS, multiple: false },
  ad_platform: { label: "Choose your platform", options: AD_PLATFORM_OPTIONS, multiple: false },
  ad_platforms: { label: "Choose your platforms", options: AD_PLATFORM_OPTIONS, multiple: true },
  social_platform: { label: "Choose your social platform", options: SOCIAL_PLATFORM_OPTIONS, multiple: false },
  social_platforms: { label: "Choose your social platforms", options: SOCIAL_PLATFORM_OPTIONS, multiple: true },
};
