import { BreadCrumb } from "interfaces/shared/breadcrumbs.interfaces";

import { ReactComponent as ArrowRightIcon } from "assets/icons/arrow-right.svg";

import { BreadcrumbsItem } from "./BreadcrumbsItem";

interface Props {
  breadcrumbs: BreadCrumb[];
}

export const Breadcrumbs = ({ breadcrumbs }: Props) => {
  return (
    <ul className='flex items-center'>
      {breadcrumbs?.map((breadcrumb, index) => (
        <BreadcrumbsItem key={breadcrumb.label} {...breadcrumb}>
          {index !== breadcrumbs?.length - 1 && <ArrowRightIcon />}
        </BreadcrumbsItem>
      ))}
    </ul>
  );
};
