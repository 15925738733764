import React, { ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

export const TableHeader = ({ children }: Props) => {
  return (
    <th className='table-cell p-3 first:rounded-tl-2xl last:rounded-tr-2xl text-sm text-[#1F2228] font-bold text-left border-t border-b border-[#EAECF0] first:border first:border-r-0 last:border-r'>
      {children}
    </th>
  );
};
