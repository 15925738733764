import { useContext } from "react";

import { ReactComponent as AiStarIcon } from "assets/icons/aiStarPurple.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import { ReactComponent as CircularCheckIcon } from "assets/icons/tickblack.svg";

import { ConnectSourceContext } from "../ConnectDataSource";

import DataSheetTableLD from "./components/DataSheetTable_LD";



const LocateData = () => {
  const {locateDataLoading } = useContext(ConnectSourceContext);
  return (
    <>
      <div className='px-6 py-4'>
        <div className='flex flex-col gap-6 p-6 rounded-[22px] bg-white'>
            
              <div className="flex flex-row items-center"><p className="text-base font-bold text-[#2E323A]">Locate your budget data</p>
              {/* <InfoIcon className="h-5 w-5 ml-4"/> */}
              </div>
              <div className="flex flex-row items-center"><AiStarIcon className={`h-4 w-5 mr-3 ${locateDataLoading? "animate-spin":""}`}/><p  className="text-sm font-medium text-[#333740]">Please review and select the Y-axis, X-axis, and the row where your data starts. Our AI may have made pre-selections for you, but feel free to adjust if necessary.</p></div>
              <div>
                <ul className="rounded-xl border border-dashed border-purple p-4">
                  <li className="text-base font-bold text-[#2E323A] flex flex-row items-center mb-3">Select and confirm the following</li>
                  <li className="text-sm font-medium text-[#717684] flex flex-row items-center mt-1 flex-wrap ">{locateDataLoading ? <span className="h-6 w-6 flex justify-center items-center text-wrap"><AiStarIcon className="animate-ping h-2 w-2 mr-2"/></span>:<CircularCheckIcon className="h-6 w-6"/>}<span className="font-semibold mr-3 text-nowrap">Y-axis</span> Select the column that contains the main categories or items in your budget (e.g., marketing channels)</li>
                  <li className="text-sm font-medium text-[#717684] flex flex-row items-center mt-1 flex-wrap ">{locateDataLoading ? <span className="h-6 w-6 flex justify-center items-center text-wrap"><AiStarIcon className="animate-ping h-2 w-2 mr-2"/></span>:<CircularCheckIcon className="h-6 w-6"/>}<span className="font-semibold mr-3 text-nowrap">X-axis</span> Choose the row that contains the headers for your data (e.g., months)</li>
                  <li className="text-sm font-medium text-[#717684] flex flex-row items-center mt-1 flex-wrap ">{locateDataLoading ? <span className="h-6 w-6 flex justify-center items-center text-wrap"><AiStarIcon className="animate-ping h-2 w-2 mr-2"/></span>:<CircularCheckIcon className="h-6 w-6"/>}<span className="font-semibold mr-3 text-nowrap">Data starts</span> Select the row where the actual budget data starts, excluding headers.</li>
                </ul>
            </div>
            <DataSheetTableLD />
        </div>
      </div>

    </>
  );
};

export default LocateData;
