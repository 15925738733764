import React from "react";

import Button from "shared/components/auth-buttons/Button";

import { ReactComponent as WorkflowIcon } from "assets/icons/navigation-icons/pageWorkflow.svg";

interface Props {
  onClick: VoidFunction;
}

export const WorkflowCreateProject = ({ onClick }: Props) => {
  return (
    <div className='bg-[#F3F4FB] rounded-xl flex-1 min-h-full  overflow-hidden flex flex-col items-center justify-center gap-y-6'>
      <WorkflowIcon />
      <div className='text-[40px] font-bold'>Start creating a project</div>
      <div className='text-md font-medium text-center pb-10'>
        Create a new project and start to automate your
        <br /> customer touch points.
      </div>
      <Button classes='max-w-[240px]' onClick={onClick}>
        Create project
      </Button>
    </div>
  );
};
