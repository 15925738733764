import { FC } from "react";

type Props = { className?: string; width?: string; height?: string; color?: string };

const ContentIcon: FC<Props> = ({ className, width = "22", height = "22", color = "#4B80FC" }) => {
  return (
    <svg
      width={width}
      height={height}
      color={color}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M13.8048 17.7743C13.2365 18.1593 12.5215 18.4801 11.6507 18.7643L10.2023 19.2409C6.56316 20.4143 4.64732 19.4334 3.46482 15.7943L2.29149 12.1734C1.11816 8.53428 2.08982 6.60928 5.72899 5.43594L7.17732 4.95928C7.55316 4.84011 7.91066 4.73928 8.24982 4.67511C7.97482 5.23428 7.75482 5.91261 7.57149 6.69178L6.67316 10.5326C5.77482 14.3643 6.95732 16.2526 10.7798 17.1601L12.3198 17.5268C12.8515 17.6551 13.3465 17.7376 13.8048 17.7743Z'
        stroke='currentColor'
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.8558 9.57003L18.9575 13.4017C18.1875 16.7109 16.6658 18.0492 13.8058 17.7742C13.3475 17.7375 12.8525 17.655 12.3208 17.5267L10.7808 17.16C6.95832 16.2525 5.77582 14.3642 6.67415 10.5325L7.57248 6.69169C7.75582 5.91253 7.97582 5.23419 8.25082 4.67503C9.32332 2.45669 11.1475 1.86086 14.2091 2.58503L15.74 2.94253C19.5808 3.84086 20.7541 5.73836 19.8558 9.57003Z'
        stroke='currentColor'
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.5859 7.81921L16.0318 8.94671'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.6875 11.3666L13.3458 12.045'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ContentIcon;
