import React, { FC } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { UserPlan } from "api/user/types";

import { ROUTES } from "constants/routes.constants";
import { UserPlans } from "constants/user.constants";

import { PersonalizationData } from "interfaces/personalization.interfaces";

export const ProtectedConditionalRoute: FC<{
  personalizeData: PersonalizationData | null;
  isAuth: boolean;
  membershipPlan?: UserPlan;
}> = ({ personalizeData, membershipPlan, isAuth }) => {
  const location = useLocation();

  if (!isAuth) return <Outlet />;

  const isPersonalizeRoute = location.pathname.includes(ROUTES.personalize);
  const isVerifyEmailRoute = location.pathname.includes(ROUTES.verifyEmail);
  const isMembershipsRoute = location.pathname.includes(ROUTES.memberships);

  const isMembershipPlanExists = membershipPlan?.plan_id !== null;

  if (personalizeData?.role_id && !isMembershipsRoute && !location.search?.includes("session_id") && !isMembershipPlanExists)
    return <Navigate to={ROUTES.memberships} />;

  if (personalizeData?.role_id && isMembershipsRoute && isMembershipPlanExists && membershipPlan?.name_tech !== UserPlans.FREE)
    return <Navigate to={ROUTES.dashboard} />;

  if (personalizeData?.role_id && isPersonalizeRoute && !isVerifyEmailRoute) return <Navigate to={ROUTES.dashboard} />;

  if (personalizeData === null && !isPersonalizeRoute && !isVerifyEmailRoute) {
    return null;
  }

  if (!personalizeData?.role_id && !isPersonalizeRoute && !isVerifyEmailRoute) return <Navigate to={ROUTES.personalize} />;
  return <Outlet />;
};
