import cn from "classnames";
import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import { BoxStyles, BoxVariants } from "constants/shared/box.constants";
import { TypographyVariants } from "constants/shared/typography.constants";

import { Typography } from "./Typography";

interface Props {
  children: ReactNode;
  title?: string;
  className?: string;
  titleClassName?: string;
  childrenClassName?: string;
  variant?: BoxVariants;
}

export const Box = ({ title, children, className, titleClassName, childrenClassName, variant = BoxVariants.NORMAL }: Props) => {
  return (
    <div className={twMerge("bg-white", BoxStyles[variant], className)}>
      {title && (
        <Typography variant={TypographyVariants.H5} className={twMerge("text-[#464A53]", titleClassName)}>
          {title}
        </Typography>
      )}
      <div className={twMerge(cn({ "mt-4": title }, childrenClassName))}>{children}</div>
    </div>
  );
};
