import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useMemo } from "react";

import { getFormattedDateString } from "utils/dates.utils";

import { useAppSelector } from "hooks/appHooks";

dayjs.extend(utc);

export const useSubscriptionPlanInfo = () => {
  const { name_tech: userPlan, plan_expiration_date: planExpirationDate } = useAppSelector(state => state.auth?.user?.plan) || {};

  const subscriptionInfo = useMemo(() => {
    const currentDate = dayjs(new Date());
    const expirationDate = dayjs(planExpirationDate);

    const startSubscriptionDate = expirationDate.subtract(7, "day");

    const differenceInDays = expirationDate.diff(currentDate, "day");
    const differenceInHours = expirationDate.diff(currentDate, "hour");
    const differenceInMinutes = expirationDate.diff(currentDate, "minute");
    const differenceInSeconds = expirationDate.diff(currentDate, "second");

    const totalSubscriptionDuration = expirationDate.diff(startSubscriptionDate, "minute");
    const fromStartToCurrentDateDuration = currentDate.diff(startSubscriptionDate, "minute");

    const calculatedPercentage = (fromStartToCurrentDateDuration / totalSubscriptionDuration) * 100;

    const fullDateString = getFormattedDateString({
      days: differenceInDays,
      hours: differenceInHours,
      minutes: differenceInMinutes,
      seconds: differenceInSeconds,
    });

    return { daysLeft: differenceInDays, percentage: calculatedPercentage, fullDateString };
  }, [planExpirationDate]);

  return { ...subscriptionInfo, userPlan };
};
