import { useContext } from "react";

import { useAppSelector } from "hooks/appHooks";

import { ConnectSourceContext } from "../ConnectDataSource";

import DataSheetTableGRP from "./components/DataSheetTable_GRP";


const ReviewBudget = () => {
  const { dataType } = useAppSelector(state => state.uploadedBudget);
  const { reviewBudgetError, fetchBudgetTableData } = useContext(ConnectSourceContext);
  return (
    <>
      <div className='px-6 py-4'>
        <div className='flex flex-col gap-6 p-6 rounded-[22px] bg-white'>
        <div className=''>
            {!reviewBudgetError ? <div className='flex-1'>
              <div className=''>
                <p className='text-base font-bold text-[#2E323A]'>Review a budget</p>
              </div>
              <div className=''>
                <p className='text-sm font-medium text-[#333740] mt-4 leading-5'>
                  Please take a time to verify your uploaded budget is correct to proceed with strategy generation.
                </p>
              </div>
            </div>:
            <div>
            <p className='text-base font-bold text-[#2E323A]'>Something went wrong! Things you need to check.</p>
            <div className='items-center mt-2'>
                <p className='text-sm font-medium text-[#333740] mt-1 ml-2'>
                  1. Check your worksheet if it contains essential months row and relevant data with numeric budget list.
                </p>
                <p className='text-sm font-medium text-[#333740] mt-1 ml-2'>
                  2. Go back and check if you have selected correct coordinates in Locate Data page.
                </p>
                <p className='text-sm font-medium text-[#333740] mt-1 ml-2'>
                  3. Check your internet connection.
                </p>
              </div>
              <div>
                <button className="p-4 rounded-xl bg-purpleP0 mt-4" onClick={()=>{fetchBudgetTableData()}}> 
                  <p className="text-sm font-medium">Click here to try again</p>
                </button>
              </div>
            </div>}
            
          </div>
            {!reviewBudgetError && <DataSheetTableGRP />}
        </div>
      </div>

    </>
  );
};

export default ReviewBudget;
