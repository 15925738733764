import React from "react";

export default function FilterIcon({ color, className }: any) {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M4.50028 1.75H15.5003C16.417 1.75 17.167 2.5 17.167 3.41667V5.25C17.167 5.91667 16.7503 6.75 16.3336 7.16667L12.7503 10.3333C12.2503 10.75 11.917 11.5833 11.917 12.25V15.8333C11.917 16.3333 11.5836 17 11.167 17.25L10.0003 18C8.91695 18.6667 7.41695 17.9167 7.41695 16.5833V12.1667C7.41695 11.5833 7.08362 10.8333 6.75028 10.4167L3.58362 7.08333C3.16695 6.66667 2.83362 5.91667 2.83362 5.41667V3.5C2.83362 2.5 3.58362 1.75 4.50028 1.75Z'
        stroke={color}
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
