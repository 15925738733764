import cn from "classnames";
import React, { useState } from "react";

import { StringOrNull } from "interfaces/shared-types.interfaces";

import { ReactComponent as CheckMarkIcon } from "assets/icons/check-mark.svg";
import { ReactComponent as CopyIcon } from "assets/icons/editor-copy-icon.svg";

interface Props {
  contentToCopy?: StringOrNull;
}

export const CopyButton = ({ contentToCopy }: Props) => {
  const [isCopied, setIsCopied] = useState(false);

  const Icon = isCopied ? CheckMarkIcon : CopyIcon;

  const handleCopy = async () => {
    if (!contentToCopy) return null;

    await navigator.clipboard.writeText(String(contentToCopy));
    setIsCopied(true);

    const timer = setTimeout(() => {
      setIsCopied(false);
    }, 5000);

    return () => clearTimeout(timer);
  };

  return (
    <button
      className={cn("absolute top-[2px] right-6 bg-white rounded-full p-2", { "cursor-not-allowed": isCopied })}
      disabled={isCopied}
      onClick={handleCopy}
    >
      <Icon className='w-[18px] h-[18px] text-[#1F2228]' />
    </button>
  );
};
