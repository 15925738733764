import cn from "classnames";
import React, { FC, forwardRef, ReactNode, SVGProps } from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { DropdownItem } from "interfaces/shared/dropdown.interfaces";

import { ReactComponent as ArrowExternalIcon } from "assets/icons/arrow-external.svg";

interface Props extends Pick<DropdownItem, "href" | "action" | "id"> {
  children: ReactNode;
  className?: string;
  additionalIcon?: FC<SVGProps<SVGSVGElement>>;
  onClick?: VoidFunction;
}

export const DropdownItemWrapper = forwardRef<HTMLDivElement, Props>(
  ({ id, children, href, action, className, additionalIcon, onClick }, ref) => {
    const AdditionalIcon = additionalIcon || ArrowExternalIcon;
    if (href)
      return (
        <div ref={ref}>
          <Link to={href} className={className}>
            <div className='flex'>{children}</div>
            <AdditionalIcon className='ml-1 mt-1.5 transition-all duration-200 min-w-3 min-h-3' />
          </Link>
        </div>
      );

    return (
      <div ref={ref}>
        <button
          className={twMerge(cn("w-full text-left", className))}
          onClick={
            action
              ? () => {
                  action(id);
                  onClick?.();
                }
              : undefined
          }
        >
          <div className='flex'>{children}</div>
          {additionalIcon && <AdditionalIcon className='ml-1 mt-1.5 transition-all duration-200 min-w-3 min-h-3' />}
        </button>
      </div>
    );
  },
);

DropdownItemWrapper.displayName = "DropdownItemWrapper";
