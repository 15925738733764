export enum InputVariants {
  LARGE = "large",
  MEDIUM = "medium",
  SMALL = "small",
}

export const InputsOptions: Record<InputVariants, { className: string; iconClassName?: string }> = {
  [InputVariants.LARGE]: { className: "py-[9px] px-3.5", iconClassName: "w-[18px] h-[18px] top-[calc(40px/2-9px)]" },
  [InputVariants.MEDIUM]: { className: "py-[7px] px-3.5", iconClassName: "w-4 h-4 top-[calc(36px/2-8px)]" },
  [InputVariants.SMALL]: { className: "py-[5px] px-3.5", iconClassName: "w-4 h-4 top-[calc(32px/2-8px)]" },
};
