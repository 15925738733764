import cn from "classnames";
import dayjs from "dayjs";
import { useEffect, useState, type FC } from "react";
import { twMerge } from "tailwind-merge";

import { ReactComponent as LeftArrowIcon } from "../../../../../assets/icons/arrowLeft.svg";
import { ReactComponent as RightArrowIcon } from "../../../../../assets/icons/arrowRight.svg";
import { ReactComponent as NotFoundIcon } from "../../../../../assets/icons/not-found.svg";
import { useAppSelector } from "../../../../../hooks/appHooks";
import { SidebarsWidth } from "../../../../../types";
import { generateUniqueId } from "../../../../../utils";

import classes from "./Recomendations.module.scss";

interface RecomendationsProps {
  openChat: boolean;
}

const Recomendations: FC<RecomendationsProps> = ({ openChat = false }) => {
  const { isCollapsed } = useAppSelector(state => state.sidebar);
  const quarterlyRoadmap = useAppSelector(state => state.project.project?.project_data?.quarterly_roadmap) || [];
  const { ASIDE_OPENED_WIDTH, ASIDE_CLOSED_WIDTH, CHAT_OPENED_WIDTH, CHAT_CLOSED_WIDTH, DEFAULT_SCREEN_WIDTH, MAIN_PADDINGS } =
    SidebarsWidth;

  const isSlider = !isCollapsed || openChat;
  const chatWidth = openChat ? CHAT_OPENED_WIDTH : CHAT_CLOSED_WIDTH;
  const chatOffset = chatWidth - CHAT_CLOSED_WIDTH;
  const asideWidth = isCollapsed ? ASIDE_CLOSED_WIDTH : ASIDE_OPENED_WIDTH;
  const asideOffset = asideWidth - ASIDE_CLOSED_WIDTH;
  const scrollWidth = window.innerWidth - document.documentElement.clientWidth || 15;

  const [sliderOffset, setSliderOffset] = useState(0);

  const handleSlider = (isMoveToLeft = true) => {
    let offset = 0;

    if (sliderOffset === chatOffset) {
      offset = asideOffset;
    }
    if (sliderOffset === asideOffset) {
      offset = chatOffset;
    }
    if (sliderOffset === 0) {
      offset = asideOffset + chatOffset;
    }
    offset = isMoveToLeft ? offset : -sliderOffset;
    setSliderOffset(prev => prev + offset);
  };

  const quarters = quarterlyRoadmap
    .map((item, index) => {
      const {
        quarter_number = 1,
        quarter_year = 2023,
        revenue_impact,
        confidence_score,
        explanation = "",
      }: {
        quarter_number: number;
        quarter_year: number;
        revenue_impact?: number;
        confidence_score?: number;
        explanation?: string;
      } = item;
      const cuttedYear = quarter_year.toString().slice(-2);

      return {
        id: quarter_number,
        explanation: explanation,
        note: "",
        period: `Q${quarter_number}’${cuttedYear}`,
        quarter: quarter_number,
        year: quarter_year,
      };
    })
    .sort((a, b) => a.quarter - b.quarter)
    .sort((a, b) => a.year - b.year);

  const itemsBlock = () => {
    const result = [];
    let nextQuarter = 0;
    let nextYear: number | string = 23;

    for (let i = 0; i < 4; i++) {
      const quarter = quarters[i];

      if (quarter) {
        nextQuarter = quarter?.quarter === 4 ? 1 : quarter.quarter + 1;
        nextYear = quarter.quarter === 4 ? (quarter.year + 1).toString().slice(-2) : quarter.year.toString().slice(-2);
      }
      if (!quarter) {
        nextYear = nextQuarter === 5 ? Number(nextYear) + 1 : nextYear;
        nextQuarter = nextQuarter === 5 ? 1 : nextQuarter;
      }

      result.push(
        <div key={generateUniqueId()} className={classes.recomendationsSection__content__item}>
          {quarter ? (
            <div
              className={twMerge(classes.recomendationsSection__content__item__list, "text-sm font-medium text-[#464A53] leading-[160%]")}
            >
              {quarter?.explanation}
              {quarter?.note && <div className={classes.recomendationsSection__content__item__list__notification}>{quarter?.note}</div>}
            </div>
          ) : (
            <div className={`${classes.recomendationsSection__content__item__list} ${classes.empty}`}>
              <NotFoundIcon />
              <p>Data for this period will be available once you select a longer budget period.</p>
            </div>
          )}

          <div className={classes.recomendationsSection__content__item__preQuarter}></div>
          <div className={classes.recomendationsSection__content__item__quarter}>{quarter?.period || `Q${nextQuarter}'${nextYear}`}</div>
        </div>,
      );
      !quarter && nextQuarter++;
    }
    return result;
  };

  useEffect(() => {
    if (isCollapsed && !openChat) {
      setSliderOffset(0);
    }
  }, [isCollapsed, openChat]);

  return (
    <section className={classes.recomendationsSection}>
      <h2 className={classes.recomendationsSection__title}>Recommendations</h2>
      <div
        className={classes.recomendationsSection__contentParent}
        style={{
          width:
            window.innerWidth <= DEFAULT_SCREEN_WIDTH ? window.innerWidth - scrollWidth - asideWidth - chatWidth - MAIN_PADDINGS : "100%",
        }}
      >
        <div
          className='h-full w-[180px] transform scale-x-[-1] bg-gradient-custom absolute top-0 z-10 left-0 flex items-center justify-end'
          style={{
            display: isSlider && sliderOffset > 0 && window.innerWidth <= DEFAULT_SCREEN_WIDTH ? "flex" : "none",
          }}
        >
          <button className={classes.recomendationsSection__contentParent__leftArrow} onClick={() => handleSlider(false)}>
            <LeftArrowIcon />
          </button>
        </div>
        <div
          className='h-full w-[180px] bg-gradient-custom absolute top-0 z-10 right-0 flex items-center justify-end'
          style={{
            display: isSlider && sliderOffset < asideOffset + chatOffset && window.innerWidth <= DEFAULT_SCREEN_WIDTH ? "flex" : "none",
          }}
        >
          <button className={classes.recomendationsSection__contentParent__rightArrow} onClick={() => handleSlider()}>
            <RightArrowIcon />
          </button>
        </div>

        <div
          className={`${classes.recomendationsSection__content} ${classes["items" + quarters.length]}`}
          style={{
            width:
              window.innerWidth <= DEFAULT_SCREEN_WIDTH
                ? window.innerWidth - scrollWidth - ASIDE_CLOSED_WIDTH - CHAT_CLOSED_WIDTH - MAIN_PADDINGS
                : "100%",
            left: `-${sliderOffset}px`,
          }}
        >
          {itemsBlock()}
        </div>
      </div>
    </section>
  );
};

export default Recomendations;
