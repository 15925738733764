import dayjs from "dayjs";
import { useMemo } from "react";

import { IBudgetData, IChannelsTransformedData } from "api/budget/types";

import { FULL_MONTH__FORMAT, FULL_YEAR_FORMAT } from "constants/date-formats.constants";

import { useAppSelector } from "../appHooks";

interface Props {
  budgetData?: IBudgetData[];
}

export const useChannelStrategy = ({ budgetData }: Props) => {
  const { channels } = useAppSelector(state => state.project?.project) || {};

  const transformedData: IChannelsTransformedData[] = useMemo(() => {
    if (!budgetData?.length || !channels?.length) return [];

    return budgetData?.map(({ date, channels: budgetChannels }) => ({
      date,
      month: dayjs(date).format(FULL_MONTH__FORMAT),
      year: dayjs(date).format(FULL_YEAR_FORMAT),
      values: budgetChannels?.map(({ value_new: value, summary, channel_id }) => {
        const currentChannel = channels?.find(channel => channel.channel_id === channel_id);

        return {
          channel_id,
          summary,
          value,
          color: currentChannel?.color || null,
          channel: currentChannel?.name || "",
          icon: currentChannel?.icon?.replace("-", ""),
        };
      }),
    }));
  }, [budgetData, channels]);

  return [transformedData];
};
