import cn from "classnames";
import { useMemo } from "react";

import { SortOrders } from "interfaces/meta.interfaces";

import { UseSortingParams } from "hooks/useSorting";

import { ReactComponent as DropdownIcon } from "assets/icons/dropdown.svg";

import { Dropdown } from "./dropdown/Dropdown";

const DEFAULT_SORT_OPTIONS = [
  { id: "oldest", label: "Oldest", order: SortOrders.ASC },
  { id: "newest", label: "Newest", order: SortOrders.DESC },
];

interface Props extends UseSortingParams {
  options?: { id: string; label: string; order: SortOrders; sortBy?: string }[];
}

export const SortDropdown = ({ sorting, updateSorting, options = DEFAULT_SORT_OPTIONS }: Props) => {
  const { id } = sorting;

  const preparedSortItems = useMemo(() => {
    return options?.map(({ order: sort_order, sortBy: sort_by, ...sortOption }) => ({
      ...sortOption,
      action: () =>
        updateSorting({
          sort_order,
          sort_by,
          id: sortOption.id,
        }),
    }));
  }, [options, updateSorting]);

  const currentSortingLabel = preparedSortItems.find(({ id }) => id === sorting?.id)?.label;

  return (
    <Dropdown items={preparedSortItems} activeId={id} className='w-36' placement='bottom end'>
      {({ open }) => (
        <div className='flex items-center space-x-1.5 text-[#A6A6A6] text-sm font-medium cursor-pointer'>
          <p>{currentSortingLabel}</p>
          <div className='w-4 h-4 flex justify-center items-center bg-[#292D32]/10 rounded'>
            <DropdownIcon className={cn("-rotate-180 transition-all duration-300", { "rotate-0": open })} />
          </div>
        </div>
      )}
    </Dropdown>
  );
};
