import Lottie from "lottie-react";

import lottieAnimation from "assets/lottie-animations/multicolor-loader.json";

export default function SpinnerLoader() {
  return (
    <div className='flex bg-[#fff] w-full h-full justify-center'>
      <Lottie animationData={lottieAnimation} loop={true} style={{ height: "100dvh", width: "100%" }} />
    </div>
  );
}
