import { useGoogleLogin, hasGrantedAllScopesGoogle, TokenResponse } from "@react-oauth/google";
import axios, { AxiosError } from "axios";
import { ChangeEvent, KeyboardEvent, useContext, useEffect, useState } from "react";

import { extractSpreadsheetIdFromUrl, getUserFromGoogle, getWorksheetDataFromGoogle, getWorksheetListFromGoogle } from "api/budget";

import { showGoogleServerError, showServerError } from "utils/modules.utils";
import { convertSpreadsheetToXlsxFile } from "utils/sheets.utils";

import { useAppSelector } from "hooks/appHooks";
import { useGoogleOIDC } from "hooks/useGoogleLogin";

import GoogleButton from "shared/components/auth-buttons/GoogleButton";

import { ConnectSourceContext } from "pages/budget/ConnectDataSource";

import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-down.svg";
import { ReactComponent as CloseSquareIcon } from "assets/icons/close-square.svg";

type UserDetailsType = {
  email: string;
  email_verified: boolean;
  picture: string;
  sub: string;
};
const UploadGoogle = ({}) => {
  const { handleUploadFile, selectedWorksheet, changeWorksheet, worksheetList, resetData,selectedGoogleFile, updateSeletedGoogleFile } = useContext(ConnectSourceContext);
  const [customUrl, setCustomUrl] = useState("");
  const { dataType, socialSignup } = useAppSelector(state => state.uploadedBudget);
  const [files, setFiles] = useState<any>([]);
  const [userDetails, setUserDetails] = useState<null | UserDetailsType>(null);
  const [downloadingSheet, setDownloadingSheet] = useState<boolean>(false);
  const [downloadingSheetList, setDownloadingSheetList] = useState<boolean>(false);
  const [pasteUrlError, setPasteUrlError] = useState<boolean>(false);
  const fetchUserDetails = async () => {
    try {
      const userInfoResponse = await getUserFromGoogle(socialSignup?.googleAccessToken);
      setUserDetails(userInfoResponse?.data);
    } catch (err) {
      console.log("fetchUserDetails error", err);
    }
  };

  const fetchFileList = async () => {
    try {
      setDownloadingSheetList(true)
      const filesList = await getWorksheetListFromGoogle(socialSignup?.googleAccessToken);
      setDownloadingSheetList(false)
      setFiles(filesList?.data?.files || []);
    } catch (error) {
      setDownloadingSheetList(false)
      console.error(error, "errror ");
    }
  };

  const fetchSpreadsheetData = async (spreadsheetId: any) => {
    try {
      resetData()
      setPasteUrlError(false)
      setDownloadingSheet(true);
      const response = await getWorksheetDataFromGoogle(spreadsheetId, socialSignup?.googleAccessToken);
      setDownloadingSheet(false);
      if(!response.data) throw new Error("No data found")
      const convertedToXlsx = convertSpreadsheetToXlsxFile(response?.data);
      handleUploadFile(convertedToXlsx);
    } catch (err:unknown) {
      setDownloadingSheet(false);
      showGoogleServerError(err)
      if(err instanceof AxiosError && err?.status === 403){
        setPasteUrlError(true)
      }
      console.error(`Error fetching data for spreadsheet ID ${spreadsheetId}:`, err);
      return null;
    }
  };
  const handleCustomUrlEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const url = extractSpreadsheetIdFromUrl(customUrl);
      if (url) {
        fetchSpreadsheetData(url);
        updateSeletedGoogleFile(""); // Remove the selected file from dropdown
      }
      e.preventDefault();
    }
  };
  const resetSource = () => {
    setFiles([]);
    updateSeletedGoogleFile("");
    setUserDetails(null);
    resetData();
  };

  useEffect(() => {
    // ---------- Step 1.1 (Automatically Fetch the Data after Login)
    if (dataType === "Google Sheets" && socialSignup?.googleAccessToken) {
      fetchFileList();
      fetchUserDetails();
    }
  }, [dataType, socialSignup]);

  useEffect(() => {
    // ----------- Step 2 (On Select a File: Download Spreadsheet Data)
    // -- Auto Select The first worksheet
    if (selectedGoogleFile) {
      fetchSpreadsheetData(selectedGoogleFile);
      setCustomUrl("");
    }
  }, [selectedGoogleFile]);
  
  // -------- Step 1 (Login Using Google Account)
  const handleGoogleLogin = useGoogleOIDC(dataType);
  return (
    <ol className='flex list-decimal w-full  ml-6 flex-col gap-4 text-base font-bold text-[#2E323A] leading-default'>
      <li>Connect a source</li>
      <div className='flex w-fit flex-col gap-4'>
        <div>
          <p className='text-sm font-medium text-[#717684]'>Connect your Google account to let Brainvoy download your data</p>
          {userDetails ? (
            <div className='w-100 px-4 py-2 rounded-xl border mt-4 flex flex-row justify-between'>
              <p className='text-sm font-semibold'>{userDetails.email}</p>
              <button className='' onClick={resetSource}>
                <CloseSquareIcon className='w-5 h-5' />
              </button>
            </div>
          ) : (
            <div className='mt-4'>
              <GoogleButton onClick={() => handleGoogleLogin()}>Sign in with Google</GoogleButton>
            </div>
          )}
        </div>
      </div>
      <li className='mt-4'>Select the file</li>
      <div className='relative mr-6'>
        <select
          className={`custom-select w-full  text-sm font-medium leading-default ${selectedGoogleFile ? "text-[#0c0c0c]" : "text-[#9EA2AD]"} py-[7px] px-[14px] outline-none mr-6 border-[1px] border-[#E9EAEC] rounded-lg`}
          aria-placeholder='Select a  worksheets'
          name=''
          value={selectedGoogleFile}
          id=''
          onChange={e => {
            updateSeletedGoogleFile(e.target.value);
          }}
        >
          <option value='' disabled selected>
            Select a file from your Google Sheets account
          </option>
          {files?.map((file: any) => {
            return (
              <option key={file?.id} value={file?.id}>
                {file?.name}
              </option>
            );
          })}
        </select>
        {downloadingSheetList ? (
          <span className=' h-3 w-3 absolute right-[14px] top-[10px]'>
            <span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75'></span>
            <span className='relative inline-flex rounded-full h-3 w-3 bg-sky-500 mb-2'></span>
          </span>
        ) : (
          <ArrowDownIcon className='absolute right-[14px] top-[10px]' />
        )}
      </div>
      <h2 className='mt-4 text-[#5E636E]'>or Paste URL</h2>
      <div className="flex flex-row mr-6">
        <div className="flex flex-1 flex-col">
      <input
        name='googleSheetUrl'
        placeholder='Paste Google Sheets URL'
        className='flex flex-1 rounded-xl border-[1px] text-sm font-medium leading-default placeholder:texgt-[#9EA2AD] border-[#E9EAEC] px-[14px] py-[7px] h-9 outline-none mr-6'
        value={customUrl}
        style={{borderColor: pasteUrlError? "tomato": "#E9EAEC"}}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setCustomUrl(e.target.value);
        }}
        onKeyDown={handleCustomUrlEnter}
      />
      {pasteUrlError && <p className="text-xs text-red-500 font-normal">*You don’t have permission to access this file. Check file permissions or choose another file.</p>}
      </div>
      <button
        className='flex items-center gap-[10px] text-sm font-semibold text-[#1F2228] h-9 rounded-lg px-[42px] py-2 bg-[#FCFCFC] border-[1px] border-[#D5D1F7]'
        onClick={() => {
          const url = extractSpreadsheetIdFromUrl(customUrl);
          if (url) {  
            fetchSpreadsheetData(url);
            updateSeletedGoogleFile(""); // Remove the selected file from dropdown
          }
        }}
      >
        <p>Enter</p>
      </button>
      </div>
      <li className='mt-4'>Select the worksheet </li>
      <div className='mr-6 relative'>
        <select
          className={`custom-select w-full  text-sm font-medium leading-default ${selectedWorksheet ? "text-[#0c0c0c]" : "text-[#9EA2AD]"} py-[7px] px-[14px] outline-none mr-6 border-[1px] border-[#E9EAEC] rounded-lg`}
          aria-placeholder='Select a  worksheets'
          name=''
          id=''
          value={selectedWorksheet ? selectedWorksheet : ""}
          onChange={e => {
            changeWorksheet(e.target.value);
          }}
        >
          <option value='' disabled selected>
            Select a worksheet
          </option>
          {worksheetList?.map((item: string) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </select>
        {downloadingSheet ? (
          <span className=' h-3 w-3 absolute right-[14px] top-[10px]'>
            <span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75'></span>
            <span className='relative inline-flex rounded-full h-3 w-3 bg-sky-500 mb-2'></span>
          </span>
        ) : (
          <ArrowDownIcon className='absolute right-[14px] top-[10px]' />
        )}
      </div>
    </ol>
  );
};

export default UploadGoogle;
