import api from "../index";

// TODO - move api logic to classes

const BASE_API = process.env.REACT_APP_API_URL;

export const apiUserPreferences = async (): Promise<any> => {
  return api(`${BASE_API}/user-preferences`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};

export const apiAddUserPreferences = async (preferenceData:any): Promise<any> => {
  return api(`${BASE_API}/user-preferences`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(preferenceData),
  });
};
