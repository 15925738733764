import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "constants/routes.constants";
import { ButtonVariants } from "constants/shared/button.constants";
import { TypographyVariants } from "constants/shared/typography.constants";
import { UserPlans } from "constants/user.constants";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import { useSubscriptionPlanInfo } from "hooks/subscriptions/useSubscriptionPlanInfo";

import { getProjectChannelsData } from "storage/actions/channels";
import { selectIsAvailableToCreateBudget } from "storage/selectors/user";
import { closePopup, openPopup } from "storage/slices/popup-slice";
import { changeMethod, handleProjectForm } from "storage/slices/project-slice";

import { CreateBudgetCard } from "page-components/budgets/create-budget/CreateBudgetCard";
import CreateBudgetCardNew from "page-components/budgets/create-budget/CreateBudgetCardNew";

import { ReactComponent as CloseIcon } from "assets/icons/closeButton.svg";
import { ReactComponent as WalletIcon } from "assets/icons/createProjectWallet.svg";
import { ReactComponent as UploadIcon } from "assets/icons/uploadIcon.svg";
import cardUpgradeImage from "assets/png/budget-card-upgrade.png";
import createBudgetImage from "assets/png/create-budget-card.png";
import optimizeBudgeImage from "assets/png/optimize-budget-card.png";

import { Button } from "../../buttons/Button";
import { Typography } from "../../Typography";

export enum CreateBudgetVariants {
  MANUAL = "manual",
  UPLOAD = "upload",
}

export default function CreateBudgetPopup() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [selected, setSelected] = React.useState<CreateBudgetVariants | null>(null);

  const projectList = useAppSelector(({ project }) => project.projectList);
  const isAvailableToCreateBudget = useAppSelector(selectIsAvailableToCreateBudget);

  const { userPlan } = useSubscriptionPlanInfo();

  const isDisabledUploadCard = Boolean(userPlan === UserPlans.FREE && projectList?.length);

  const selectMethodHandler = (method: CreateBudgetVariants) => {
    setSelected(method);
    dispatch(changeMethod(method));
  };

  const continueHandler = () => {
    dispatch(getProjectChannelsData());
    dispatch(handleProjectForm({}));

    if (selected === CreateBudgetVariants.MANUAL) {
      dispatch(closePopup());
      dispatch(changeMethod(CreateBudgetVariants.MANUAL));
      navigate(ROUTES.budgetForm);
    } else if (selected === CreateBudgetVariants.UPLOAD) {
      dispatch(closePopup());
      dispatch(openPopup("UploadBudget"));
    }
  };

  useEffect(() => {
    if (isDisabledUploadCard) setSelected(null);
  }, [isDisabledUploadCard]);

  return (
    <div className='flex flex-col items-center bg-[#fff] shadow-sm rounded-3xl pointer-events-auto relative py-16 px-20 gap-y-10'>
      <button
        type='button'
        className='w-[36px] h-[36px] absolute top-6 right-6 flex justify-center items-center rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none'
        data-hs-overlay='#hs-static-backdrop-modal'
        onClick={() => dispatch(closePopup())}
      >
        <CloseIcon />
      </button>
      <div className='flex flex-col justify-between items-center'>
        <Typography className='mb-6' variant={TypographyVariants.H3}>
          Do you have an existing marketing plan?
        </Typography>
        <Typography className='text-[#5E636E] text-center' variant={TypographyVariants.BODY_MEDIUM}>
          Our AI intelligence platform can create a new marketing channel budget
          <br /> or help you optimize your existing budget for the year.
        </Typography>
      </div>

      <div className='flex justify-between items-center gap-x-8'>
        {/* <CreateBudgetCard
          selected={selected === CreateBudgetVariants.MANUAL}
          disabled={userPlan === UserPlans.FREE || !isAvailableToCreateBudget}
          handleSelectMethod={() => selectMethodHandler(CreateBudgetVariants.MANUAL)}
          icon={WalletIcon}
        >
          Create a new <br /> budget
        </CreateBudgetCard>

        <CreateBudgetCard
          selected={selected === CreateBudgetVariants.UPLOAD}
          disabled={isDisabledUploadCard || !isAvailableToCreateBudget}
          handleSelectMethod={() => selectMethodHandler(CreateBudgetVariants.UPLOAD)}
          icon={UploadIcon}
        >
          Optimize an existing <br /> budget
        </CreateBudgetCard> */}
        <CreateBudgetCardNew
          title='Create a new budget'
          caption='Build a budget plan tailored to your goals and needs.'
          selected={selected === CreateBudgetVariants.MANUAL}

          bgImage={isAvailableToCreateBudget ? createBudgetImage: cardUpgradeImage}
          width="298px"
          handleSelected={() => {
            if(!isAvailableToCreateBudget){
              dispatch(closePopup())
              dispatch(openPopup("MembershipsModal"))
              return;
            }
            selectMethodHandler(CreateBudgetVariants.MANUAL)
          }}
        />
        <CreateBudgetCardNew
          title='Optimize an exisiting budget'
          caption='Enhance and refine your current budget for better results.'
          selected={selected === CreateBudgetVariants.UPLOAD}
          bgImage={optimizeBudgeImage}
          width="305px"
          handleSelected={() => selectMethodHandler(CreateBudgetVariants.UPLOAD)}
        />
      </div>
      {selected && (
        <Button className='w-fit px-[42px]' variant={ButtonVariants.MEDIUM} onClick={continueHandler}>
          Continue
        </Button>
      )}
    </div>
  );
}
