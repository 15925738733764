import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";

import { PersonalizationData, PersonalizationOptionsList } from "interfaces/personalization.interfaces";

import { getMyPersonalizationData, getPersonalizationOptionsList } from "../actions/personalization";
import { PersonalizationState } from "../slices/personalization-slice";

export function getMyPersonalizationDataReducer(builder: ActionReducerMapBuilder<PersonalizationState>) {
  builder.addCase(getMyPersonalizationData.fulfilled, (state, action: PayloadAction<PersonalizationData>) => {
    state.data = action.payload;
  });
}

export function getPersonalizationOptionsListReducer(builder: ActionReducerMapBuilder<PersonalizationState>) {
  builder.addCase(
    getPersonalizationOptionsList.fulfilled,
    (state, action: PayloadAction<PersonalizationOptionsList>) => {
      state.options = action.payload;
    },
  );
}
