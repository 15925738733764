import { createAsyncThunk } from "@reduxjs/toolkit";

import { apiGetMembershipsPlans } from "api/memberships";

export const MEMBERSHIPS_SLICE_NAME = "memberships";

export const getMembershipsPlans = createAsyncThunk(
  `${MEMBERSHIPS_SLICE_NAME}/getMembershipsPlans`,
  async () => await apiGetMembershipsPlans(),
);
