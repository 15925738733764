import { useState } from "react";

import { NumberOrNull } from "interfaces/shared-types.interfaces";
import { WorkflowStrategy } from "interfaces/workflows.interfaces";

import { getWorkflowsStrategyById } from "storage/actions/workflows";

import { useAppDispatch } from "../appHooks";

const DEFAULT_STRATEGIES_STATE = { currentChannelId: null, workflowStrategies: [] };

export const useLoadWorkflowsStrategy = () => {
  const dispatch = useAppDispatch();

  const [strategiesData, setStrategiesData] = useState<{ currentChannelId: NumberOrNull; workflowStrategies: WorkflowStrategy[] }>(
    DEFAULT_STRATEGIES_STATE,
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleLoadWorkflowStrategies = (id: number) => {
    setStrategiesData(DEFAULT_STRATEGIES_STATE);
    if (!id) return;

    setIsLoading(true);
    dispatch(getWorkflowsStrategyById({ id }))
      .unwrap()
      .then(workflowStrategies => {
        setStrategiesData({ currentChannelId: id, workflowStrategies });
      })
      .finally(() => setIsLoading(false));
  };

  return { isLoading, workflowStrategiesData: strategiesData, handleLoadWorkflowStrategies };
};
