import cn from "classnames";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

import { BreadCrumb } from "interfaces/shared/breadcrumbs.interfaces";

interface Props extends BreadCrumb {
  children?: ReactNode;
}

export const BreadcrumbsItem = ({ path, label, active, children }: Props) => {
  return (
    <li className={cn("flex items-center text-[#717684] text-sm", { "text-[#766CE4]": active })}>
      <Link to={path} className={cn({ "pointer-events-none": active })}>
        {label}
      </Link>
      {children}
    </li>
  );
};
