import { createSelector } from "@reduxjs/toolkit";

import { UserPlans } from "constants/user.constants";

import { RootState } from "../store";

const selectState = (x: RootState) => x.auth;

export const selectUser = createSelector(selectState, ({ user }) => user);

export const selectIsFreemiumPlan = createSelector(selectState, ({ user }) => user?.plan?.name_tech === UserPlans.FREE);

export const selectIsAvailableToCreateBudget = createSelector(selectState, ({ user }) => user?.plan?.budget_creating_ability?.ability);

export const selectIsMembershipPlanExists = createSelector(selectState, ({ user }) => user?.plan?.plan_id !== null);

export const selectMembershipPlan = createSelector(selectState, ({ user }) => user?.plan);

export const selectIsAvailableToCreateWorkflow = createSelector(selectState, ({ user }) => user?.plan?.workflow_creating_ability?.ability);
