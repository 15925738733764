import { FC } from "react";

type Props = { className?: string; width?: string; height?: string; color?: string };

const EmailIcon: FC<Props> = ({ className, width = "22", height = "22", color = "#02A613" }) => {
  return (
    <svg
      width={width}
      height={height}
      color={color}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M15.584 19.4792H6.41732C3.07148 19.4792 1.14648 17.5542 1.14648 14.2084V7.79171C1.14648 4.44587 3.07148 2.52087 6.41732 2.52087H15.584C18.9298 2.52087 20.8548 4.44587 20.8548 7.79171V14.2084C20.8548 17.5542 18.9298 19.4792 15.584 19.4792ZM6.41732 3.89587C3.79565 3.89587 2.52148 5.17004 2.52148 7.79171V14.2084C2.52148 16.83 3.79565 18.1042 6.41732 18.1042H15.584C18.2057 18.1042 19.4798 16.83 19.4798 14.2084V7.79171C19.4798 5.17004 18.2057 3.89587 15.584 3.89587H6.41732Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='0.4'
      />
      <path
        d='M10.9999 11.7975C10.2299 11.7975 9.45071 11.5591 8.85488 11.0733L5.98571 8.78164C5.69238 8.54331 5.63738 8.11247 5.87571 7.81914C6.11405 7.5258 6.54488 7.47081 6.83822 7.70914L9.70737 10.0008C10.404 10.56 11.5865 10.56 12.2832 10.0008L15.1524 7.70914C15.4457 7.47081 15.8857 7.51664 16.1149 7.81914C16.3532 8.11247 16.3074 8.55247 16.0049 8.78164L13.1357 11.0733C12.549 11.5591 11.7699 11.7975 10.9999 11.7975Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='0.4'
      />
    </svg>
  );
};

export default EmailIcon;
