import { ChangeEvent, forwardRef, InputHTMLAttributes, ReactNode, useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";

import { INPUT_SHARED_CLASSNAME } from "constants/shared/fom-elements.constants";

import { SharedFormElementsProps } from "interfaces/shared/form-elements.interfaces";

import { ErrorMessage } from "./ErrorMessage";
import { FormLabel } from "./FormLabel";

const TEXT_AREA_HEIGHT_OFFSET = 2;

interface Props extends InputHTMLAttributes<HTMLTextAreaElement>, SharedFormElementsProps {
  inputClassName?: string;
  rows?: number;
  children?: ReactNode;
}

export const TextArea = forwardRef<HTMLTextAreaElement, Props>(({ inputClassName, children, onChange, error, label, ...props }, ref) => {
  const localRef = useRef<HTMLTextAreaElement | null>(null);

  // We need to keep local ref to be able to set the height of the textarea
  const setRef = (node: HTMLTextAreaElement) => {
    if (node) {
      if (typeof ref === "function") {
        ref(node);
      } else if (ref) {
        ref.current = node;
      }

      localRef.current = node;
    }
  };

  const handleChange = (ev: ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(ev);
    }

    if (localRef?.current?.style) {
      localRef.current.style.height = "auto"; // When we set auto to height we handle case when we delete a row
      localRef.current.style.height = `${ev.target.scrollHeight + TEXT_AREA_HEIGHT_OFFSET}px`;
    }
  };

  useEffect(() => {
    const scrollHeight = localRef.current?.scrollHeight;

    if (scrollHeight && localRef.current && localRef.current.style) {
      localRef.current.style.height = `${scrollHeight + TEXT_AREA_HEIGHT_OFFSET}px`;
    }
  }, [localRef.current?.scrollHeight]);

  useEffect(() => {
    if (localRef.current && localRef.current.style) {
      localRef.current.style.height = "auto";
      const scrollHeight = localRef.current?.scrollHeight;
      localRef.current.style.height = `${scrollHeight + 2}px`;
    }
  }, [props.disabled]);

  return (
    <div className='w-full flex flex-col'>
      <FormLabel>{label}</FormLabel>
      <textarea
        rows={1}
        ref={setRef}
        onChange={handleChange}
        className={twMerge("w-full block py-[7px] px-3.5 min-h-[36px] resize-none", INPUT_SHARED_CLASSNAME, inputClassName)}
        {...props}
      />
      <ErrorMessage error={error?.message} />
      {children}
    </div>
  );
});

TextArea.displayName = "TextArea";
