import React from "react";

import { Box } from "shared/components/Box";

import { ReactComponent as MagicPenIcon } from "assets/icons/magicpen.svg";
import { ReactComponent as PresentationChartIcon } from "assets/icons/presention-chart.svg";

import DashboardFeatureCard from "./DashboardFeatureCard";

export const DashboardUpcomingFeatures = () => {
  return (
    // TODO - create transparent variant for Box component
    <Box className='mt-8 p-0 bg-transparent' title='Upcoming features' childrenClassName='mt-4'>
      <div className='mt-4 flex space-x-4 overflow-x-auto'>
        <DashboardFeatureCard
          title='AI Voyagers'
          description='Streamline budgeting, vendor management, contract intelligence, market research, and operational workflow automation use-cases end-to-end'
          icon={MagicPenIcon}
        />
        <DashboardFeatureCard
          title='Analytics'
          description='Ready-to-use template-based reports powered by ML and heuristic models for financial performance management and customer insights'
          icon={PresentationChartIcon}
        />
      </div>
    </Box>
  );
};
