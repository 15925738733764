import { type FC } from "react";

import { useChannelStrategy } from "hooks/channels/useChannelStrategy";

import { useAppSelector } from "../../../../../hooks/appHooks";
import MonthlyStrategy from "../../MarketingpView/MonthlyStrategy/MonthlyStrategy";

import classes from "./BudgetAllocation.module.scss";
import Budgets from "./Budgets/Budgets";

interface BudgetAllocationProps {
  openChat: boolean;
}

const BudgetAllocation: FC<BudgetAllocationProps> = ({ openChat = false }) => {
  const project = useAppSelector(state => state?.project?.project);
  const [transformedData] = useChannelStrategy({ budgetData: project?.project_data?.budget_data });
  return (
    <section className={classes.allocationSection}>
      <Budgets />
      <MonthlyStrategy transformedData={transformedData}/>
    </section>
  );
};

export default BudgetAllocation;
