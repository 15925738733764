import React from "react";

export default function DownIcon({ color, className }: any) {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M14.9999 7.5C15.7099 7.5 16.0807 8.32167 15.6524 8.8525L15.589 8.9225L10.589 13.9225C10.4456 14.066 10.2546 14.1522 10.0521 14.1649C9.84959 14.1776 9.64938 14.116 9.48905 13.9917L9.41071 13.9225L4.41071 8.9225L4.34155 8.84417L4.29655 8.78L4.25155 8.7L4.23738 8.67L4.21488 8.61417L4.18821 8.52417L4.17988 8.48L4.17155 8.43L4.16821 8.3825V8.28417L4.17238 8.23583L4.17988 8.18583L4.18821 8.1425L4.21488 8.0525L4.23738 7.99667L4.29571 7.88667L4.34988 7.81167L4.41071 7.74417L4.48905 7.675L4.55321 7.63L4.63321 7.585L4.66321 7.57083L4.71905 7.54833L4.80905 7.52167L4.85321 7.51333L4.90321 7.505L4.95071 7.50167L14.9999 7.5Z'
        fill={color}
      />
    </svg>
  );
}
