import cn from "classnames";
import React, { useEffect, useRef, useState } from "react";

import { ICreateProjectData } from "api/projects/types";

import { ALLOWED_CHARACTERS_WITH_ONLY_ENGLISH_REGEX } from "constants/regex.constants";
import { ONLY_ENGLISH_AND_ALLOWED_CHARACTERS_MESSAGE } from "constants/validation.constants";

import { useAppDispatch } from "hooks/appHooks";

import { handleProjectForm } from "storage/slices/project-slice";

import { generateUniqueId } from "utils";

import { ErrorMessage } from "../form-elements/ErrorMessage";
import { FormLabel } from "../form-elements/FormLabel";

import TagComponent from "./TagComponent";

const staticConfig = ["Youth", "Eco-Conscious", "GenZ", "Influencers", "Young Professionals", "Millennial "];

// TODO - create onUpdate function and move dispatch outside

interface Props {
  projectTags?: string[];
  project: Partial<ICreateProjectData>;
  setError: (error: string) => void;
  error: string;
}

export default function TagInputComponent({ project, projectTags, error, setError }: Props) {
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [tags, setTags] = useState<string[]>(projectTags || []);
  const [inputValue, setInputValue] = useState<string>("");
  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
  };
  const addTag = (newValue?:string)=>{
    setError("")
    if(tags.length >=3){
      setError("You can add maximum 3 tags");
      return;
    }
    const updatedInputValues = newValue ? [newValue]:inputValue
      .split(",")
      .map(tag => tag.trim())
      .filter(tag => tag);

    const validatedValues = updatedInputValues.map(value => ALLOWED_CHARACTERS_WITH_ONLY_ENGLISH_REGEX.test(value));

    const isInvalidValues = validatedValues.some(booleanValue => !booleanValue);

    if (isInvalidValues) {
      setError(ONLY_ENGLISH_AND_ALLOWED_CHARACTERS_MESSAGE);
      return;
    }

    const newTags = [...tags, ...updatedInputValues.slice(0, 3)];
    setTags(newTags);
    dispatch(handleProjectForm({ ...project, audience: newTags }));
    setInputValue("");
  }
  const handleKeyDown = (e: React.KeyboardEvent) => {
    setError("")
    if (e.key !== "Enter") return;
    e?.preventDefault?.();
    setError("");

    if (!inputValue?.trim()) {
      setError("The name of the target audience can't be empty");
      return;
    }
    addTag()
    
  };

  const removeTag = (indexToRemove: number) => {
    setError("")
    const filteredTags = tags?.filter((_, index) => index !== indexToRemove);
    setTags(filteredTags);
    dispatch(handleProjectForm({ ...project, audience: filteredTags }));
  };
  useEffect(()=>{
    if(projectTags) setTags(projectTags)
  },[projectTags])

  console.log("Input Ref Current",inputRef.current?.value)
  return (
    <div className='flex flex-col items-start w-full grow-0'>
      <FormLabel>Target audience</FormLabel>
      <div
        className={`relative w-full flex items-center px-[7px] h-10 text-sm bg-[#fff] text-[#2c1a49] border border-[#E9EAEC] transition-all duration-300 ${
          isFocused ? "outline-none border-[#807692] shadow-ring" : "focus:border-[#807692] focus:shadow-ring"
        } rounded-xl overflow-wrap-break-word overflow-wrap flex-wrap hover:border-[#C3BBD1]`}
        onClick={() => inputRef.current && inputRef.current.focus()}
      >
        {tags?.length > 0 && (
          <div className='text-[#9EA2AD] text-sm font-medium bg-[#FFF] flex items-center'>
            {tags.map((tag, index) => (
              <TagComponent tag={tag} key={index} isExample={false} onDelete={removeTag} index={index} />
            ))}
          </div>
        )}
       <div className={cn(" flex items-center px-2 py-1 bg-[#FFF] rounded-md",{"!bg-[#F1F0FC] ":inputValue.length>0})}>
        <input
          disabled={tags.length >= 3}
          ref={inputRef}
          name='audience'
          value={inputValue}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          style={{
            width: inputValue.length > 0 ? `${inputRef.current?.scrollWidth}px`:"10px",
            boxSizing:"content-box",
            minWidth:"10px" 
          }}
          className={cn("block  text-sm bg-[#FFF] text-[#2c1a49] border-none  outline-none w-3",{"!bg-[#F1F0FC] ":inputValue.length>0})}
        />
        {<button className={cn("ml-4 z-[1040] delay-300 text-purple font-semibold text-xs",{"hidden":inputValue.length<1})} onClick={()=>{handleKeyDown({key:"Enter"} as any)}}>Add</button>}
        </div>
        {!inputValue && !tags?.length && (
          <span
            onClick={() => inputRef.current && inputRef.current.focus()}
            className='bg-transparent text-[#9EA2AD] absolute left-[22px] top-1/2 transform -translate-y-1/2 text-sm font-medium'
          >
            Type your target audience
          </span>
        )}
      </div>
      <ErrorMessage error={error} />
      <div className='flex w-full justify-end items-center pt-1'>
        <p className='font-normal text-xs text-[#9A91A8] content-end'>{tags.length} / 3</p>
      </div>
      <div className='flex w-full justify-start items-center pt-2'>
        <p className='font-medium text-sm text-[#717684]'>For example:</p>
      </div>
      <div className='flex w-full justify-start flex-wrap gap-y-2 items-center pt-2'>
        {staticConfig.map((tag, index) => (
          <TagComponent isExample={true} tag={tag} key={generateUniqueId()} index={0} onDelete={removeTag} addTag={addTag}/>
        ))}
      </div>
    </div>
  );
}