import { useEffect } from "react";

import { BrowserStorageKeys } from "constants/browser-storage.constants";

import { identifyPendo } from "utils/pendo.utils";

import { selectUser } from "storage/selectors/user";

import { getMembershipsPlans } from "../storage";

import { useAppDispatch, useAppSelector } from "./appHooks";

export const useInitializePendo = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);

  const isPendoInitialized = localStorage.getItem(BrowserStorageKeys.IS_PENDO_INITIALIZED);

  const { plan_id: planId } = user?.plan || {};

  useEffect(() => {
    if (!planId || !user || isPendoInitialized === "true") return;

    dispatch(getMembershipsPlans())
      .unwrap()
      .then(memberships => {
        const membership = memberships?.find(({ plan_id }) => plan_id === user?.plan?.plan_id);

        if (!membership) return;

        identifyPendo({ ...user, membership });
      });
  }, [dispatch, isPendoInitialized, planId, user]);

  return null;
};
