import { twMerge } from "tailwind-merge";

export enum ColoredLabelVariants {
  PRIMARY = "primary",
  BORDERED = "bordered",
  SMALL_BORDERED = "small_bordered",
}

export const SHARED_COLORED_LABEL_BORDERED_CLASSNAME = "bg-white rounded-[26px] border border-[#1D9052]";

export const ColoredLabelStyles: Record<ColoredLabelVariants, string> = {
  [ColoredLabelVariants.PRIMARY]: "rounded-full text-xs font-semibold py-[3px] px-3",
  [ColoredLabelVariants.BORDERED]: twMerge(
    SHARED_COLORED_LABEL_BORDERED_CLASSNAME,
    "text-sm font-semibold leading-[18px] px-[9px] py-[5px]",
  ),
  [ColoredLabelVariants.SMALL_BORDERED]: twMerge(
    SHARED_COLORED_LABEL_BORDERED_CLASSNAME,
    "text-xs font-semibold leading-[19px] py-0.5 px-2",
  ),
};
