import React, { useEffect, useRef, useState } from "react";

import Alert from "../../toasts";

export default function ItemInput({ onSubmit, currentMetrics }: any) {
  const [value, setValue] = useState("");

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const regex = /^[A-Za-z ]+$/;
    if (regex.test(value) || value === "") {
      if (value.length > 50) {
        Alert("error", "Item name should not exceed 50 characters", "Error");
      }
      const newValue = value.slice(0, 50);

      setValue(newValue);
    }
  };
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (currentMetrics.includes(value)) {
      Alert("error", "This metric already exists", "Error");
      return;
    }
    if (!value) {
      Alert("error", "Metric name should not be empty", "Error");
      return;
    }
    onSubmit(value);
  };
  return (
    <form onSubmit={handleSubmit}>
      <input
        ref={inputRef}
        value={value}
        onChange={handleChange}
        placeholder='Type here'
        className='placeholder:text-[#9EA2AD] placeholder:font-medium placeholder:text-sm
        text-[#1F2228] outline-none w-[136px] h-[47px] px-[18px] py-[14px] text-[14px] font-medium border-[1.5px] border-[#E9EAEC] rounded-[56px] cursor-pointer focus:border-[#C8CAD0] hover:border-[#D5D1F7]
        '
      />
    </form>
  );
}
