import { FC, ReactNode } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { SearchInput } from "interfaces/shared/search-form.interfaces";

import { ReactComponent as SearchIcon } from "assets/icons/searchGlass.svg";

import { InputVariants } from "../../constants/shared/input.constants";

import { Input } from "./form-elements/Input";

export const DEFAULT_SEARCH_INPUT_PLACEHOLDER = "Search";

type Props = {
  handleFilter: (search: string) => void;
  label?: string;
  className?: string;
  placeholder?: string;
  children?: ReactNode;
};

export const SearchForm: FC<Props> = ({ handleFilter, label, className, placeholder, children }) => {
  const { register, handleSubmit } = useForm<SearchInput>();

  const onSubmit: SubmitHandler<SearchInput> = ({ search }) => {
    handleFilter(search);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={twMerge("w-full flex items-center", className)}>
      <Input
        className='max-w-xs'
        icon={SearchIcon}
        variant={InputVariants.MEDIUM}
        onIconClick={handleSubmit(onSubmit)}
        {...register("search")}
        label={label}
        placeholder={placeholder || DEFAULT_SEARCH_INPUT_PLACEHOLDER}
      />
      {children}
    </form>
  );
};
