import { forwardRef, MouseEvent } from "react";

export const DropdownMenuButton = forwardRef((props: any, ref) => {
  const handleClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    props?.onClick(e);
  };

  return <button ref={ref} {...props} onClick={handleClick} />;
});

DropdownMenuButton.displayName = "DropdownMenuButton";
