import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IGoalData } from "../../api/budget/types";

interface GoalState {
  goals: IGoalData[];
}

const initialState: GoalState = {
  goals: [],
};

export const goalsSlice = createSlice({
  name: "goals",
  initialState,
  reducers: {
    setGoals: (state, action: PayloadAction<IGoalData[]>) => {
      state.goals = action.payload;
    },
  },
});

export const { setGoals } = goalsSlice.actions;
export const goalsReducer = goalsSlice.reducer;
