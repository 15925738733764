import React, { forwardRef, InputHTMLAttributes } from "react";

import { SharedFormElementsProps } from "interfaces/shared/form-elements.interfaces";

interface Props extends InputHTMLAttributes<HTMLInputElement>, SharedFormElementsProps {
  type?: "radio" | "checkbox";
  label: string;
}

export const SwitchableInput = forwardRef<HTMLInputElement, Props>(({ type = "radio", label, ...props }, ref) => {
  return (
    <label>
      <input className='peer hidden' ref={ref} type={type} {...props} />

      <p className='w-fit px-3 py-1 flex items-center bg-white justify-between text-nowrap rounded-2xl border-[1px] hover:border-[#C3BBD1]  border-[#E9EAEC] text-[#2E323A] text-sm group font-medium peer-checked:!border-[#6B62CF] select-none cursor-pointer'>
        {label}
      </p>
    </label>
  );
});

SwitchableInput.displayName = "SwitchableInput";
