import React from "react";

import { TypographyVariants } from "constants/shared/typography.constants";

import { Box } from "shared/components/Box";
import { Button } from "shared/components/buttons/Button";
import { Typography } from "shared/components/Typography";

import { ReactComponent as PlusIcon } from "assets/icons/plusIcon.svg";
import transformInsightsPng from "assets/png/transform-insights.png";

export const DashboardTransformInsightsContainer = () => {
  return (
    <Box className='mt-8 px-8 py-5 border-2 border-dashed border-[#C0BBF3] rounded-3xl'>
      <div className='flex justify-between w-full'>
        <div className='max-w-[460px]'>
          <div className='flex items-start'>
            <Typography className='text-[#807692]' variant={TypographyVariants.H6}>
              Transform your data into insights
            </Typography>
            <span className='w-fit min-w-36 ml-3.5 px-3 py-1.5 font-bold text-nowrap text-[#14191A] bg-yellow-to-orange rounded-2xl -translate-y-1.5'>
              Coming Soon...
            </span>
          </div>
          <Typography className='text-[#9A91A8]' variant={TypographyVariants.BODY_MEDIUM}>
            Getting your data into Brainvoy is the important step to get insights you need. Easily connect your marketing platforms now in
            just a few clicks.
          </Typography>
          <Button className='mt-5 w-fit' disabled>
            Connect Data Source <PlusIcon className='ml-1.5 [&_path]:fill-[#C8CAD0]' />
          </Button>
        </div>
        <img className='hidden md:block max-w-[180px] object-contain ml-8' src={transformInsightsPng} alt='transform-insights-png' />
      </div>
    </Box>
  );
};
