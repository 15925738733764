import { ButtonVariants } from "constants/shared/button.constants";

export const getDisabledButtonStylesByVariant = (variant: ButtonVariants) => {
  switch (variant) {
    case ButtonVariants.LARGE_LINK:
    case ButtonVariants.MEDIUM_LINK:
      return ButtonVariants.LINK_DISABLED;

    default:
      return ButtonVariants.DISABLED;
  }
};
