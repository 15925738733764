import React from "react";

import { ButtonVariants } from "constants/shared/button.constants";

import { useAppDispatch } from "hooks/appHooks";

import { openPopup } from "storage/slices/popup-slice";

import { Box } from "shared/components/Box";
import { Button } from "shared/components/buttons/Button";

import { ReactComponent as SetupCompanyProfileIcon } from "assets/icons/setup-company-profile.svg";
import { ReactComponent as StartFirstProject } from "assets/icons/start-first-project.svg";

import { DashboardInsightCard } from "./DashboardInsightCard";
import StartProjectCard from "./StartProjectCard";

// TODO - Create Shared Button Component

export const DashboardInsightsContainer = () => {
  const dispatch = useAppDispatch();

  const handleStartFirstBudget = () => dispatch(openPopup("CreateBudget"));

  return (
    <Box title='Find your first insight'>
      {/* <div className='flex space-x-6 overflow-auto max-w-[1600px] flex-nowrap min-w-full w-60'> */}
        {/* <DashboardInsightCard
          className='bg-yellow-to-purple'
          icon={SetupCompanyProfileIcon}
          title='Setup Company Profile'
          description='Setting up your company profile helps us provide  personalised insights and optimise marketing strategies.'
        >
          <Button variant={ButtonVariants.MEDIUM} className='w-fit mt-3'>
            Setup
          </Button>
        </DashboardInsightCard> */}
        {/* <DashboardInsightCard
          className='bg-purple-to-white'
          icon={StartFirstProject}
          title='Start First Project'
          description='Upload your budget to receive actionable suggestions and key metrics to enhance your marketing effectiveness.'
        >
          <Button variant={ButtonVariants.MEDIUM} className='w-fit mt-3' onClick={handleStartFirstBudget}>
            Start
          </Button>
        </DashboardInsightCard> */}
        <StartProjectCard onButtonClick={handleStartFirstBudget} />
      {/* </div> */}
    </Box>
  );
};
