import React, { useMemo } from "react";
import { Tooltip } from "react-tooltip";

import { IChannelsTransformedData } from "api/budget/types";

import { UserPlans } from "constants/user.constants";

import { StringOrNull } from "interfaces/shared-types.interfaces";

import { checkIsViewAvailableBySubscription } from "utils/subscriptions.utils";

import { useAppSelector } from "hooks/appHooks";

import { ReactComponent as ArrowLeft } from "assets/icons/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "assets/icons/arrowRight.svg";

import { ChannelStrategyMonthSlide } from "./ChannelStrategyMonthSlide";

interface Props {
  data: IChannelsTransformedData[];
  activeDateIndex: number;
  handleActiveMonth: (value: boolean) => void;
  budgetDateFrom?: StringOrNull;
}

export const ChannelStrategyMonths = ({ data, activeDateIndex, handleActiveMonth, budgetDateFrom }: Props) => {
  const { name_tech: userPlan } = useAppSelector(state => state?.auth?.user?.plan) || {};

  const nextData = data?.[activeDateIndex + 1];

  const nextDateDetails = `${nextData?.year} ${nextData?.month}`;

  const listOfAvailableDates = useMemo(() => {
    if (!data?.length || userPlan === UserPlans.FREE) return [];

    return data?.reduce((previousDates, curr) => {
      const date = `${curr?.year} ${curr?.month}`;

      const isAvailableDate = checkIsViewAvailableBySubscription({
        currentBudgetDate: date,
        budgetDateFrom: budgetDateFrom || null,
      });

      if (isAvailableDate && userPlan) return [...previousDates, date];

      return previousDates;
    }, [] as string[]);
  }, [budgetDateFrom, data, userPlan]);

  const handleMoveSlide = (nextSlide: boolean, index: number) => {
    const slideData = data?.[index];

    const isAvailableToMove = listOfAvailableDates?.includes(`${slideData?.year} ${slideData?.month}`);

    if (!isAvailableToMove && userPlan === UserPlans.PRO) return;

    handleActiveMonth(nextSlide);
  };

  const nextMonthButtonTooltipId =
    userPlan === UserPlans.PRO && !listOfAvailableDates.includes(nextDateDetails) ? "next-button-blocked-tooltip" : undefined;

  return (
    <div className='flex py-[9px] px-4 border-b border-b-[#E8E9F0] justify-between items-center mb-[12px]'>
      <button
        className='rounded-lg border border-[#C8CAD0] flex items-center justify-center h-7 w-7 min-w-7'
        style={{
          opacity: data[activeDateIndex - 1]?.month ? "1" : "0",
          cursor: data[activeDateIndex - 1]?.month ? "pointer" : "unset",
        }}
        onClick={() => handleMoveSlide(false, activeDateIndex - 1)}
      >
        <ArrowLeft width={14} height={14} color={"#717684"} />
      </button>
      <div className='flex items-center justify-around w-full mx-2.5'>
        <ChannelStrategyMonthSlide>{data[activeDateIndex - 1]?.month?.slice(0, 3) ? data[activeDateIndex - 1]?.month?.slice(0, 3) + "." : ""}</ChannelStrategyMonthSlide>
        <ChannelStrategyMonthSlide isActive>
          {data[activeDateIndex]?.month}, {data[activeDateIndex]?.year}
        </ChannelStrategyMonthSlide>
        <ChannelStrategyMonthSlide className='justify-start'>{data[activeDateIndex + 1]?.month?.slice(0, 3)}.</ChannelStrategyMonthSlide>
      </div>
      <button
        data-tooltip-id={nextMonthButtonTooltipId}
        className='rounded-lg border border-[#C8CAD0] flex items-center justify-center h-7 w-7 min-w-7 cursor-pointer'
        style={{
          opacity: data[activeDateIndex + 1]?.month ? "1" : "0",
          cursor: data[activeDateIndex + 1]?.month ? "pointer" : "unset",
        }}
        onClick={() => handleMoveSlide(true, activeDateIndex + 1)}
      >
        <ArrowRight width={14} height={14} color={"#717684"} />
      </button>

      <Tooltip
        id='next-button-blocked-tooltip'
        positionStrategy='fixed'
        place='top-end'
        opacity={1}
        clickable
        style={{
          top: "-50%",
          width: "168px",
          borderRadius: "13px",
          background: "#2E323A",
          padding: "12px",
          zIndex: 1000,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            width: "100%",
            gap: "8px",
          }}
        >
          <div className='flex justify-between items-start'>
            <span className='w-full text-xs text-color-[#FFF] font-normal'>
              Workflows will open up in <b>{nextDateDetails}</b> Come back later.{" "}
            </span>
          </div>
          <button className='flex relative w-full items-center justify-center m-[0px] px-[32px] py-[4px] bg-[#766CE4] border-0 rounded-[8px] text-[12px] font-medium'>
            Set reminder
          </button>
        </div>
      </Tooltip>
    </div>
  );
};
