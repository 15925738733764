import { Column, textEditor } from "react-data-grid";
import { read, utils, WorkBook, WorkSheet, write } from "xlsx";

export type Row = any[];
export type AOAColumn = Column<Row>;
export type ColumnData = any[];
type RowCol = { rows: Row[]; columns: AOAColumn[] };

export function ws_to_rdg(ws: WorkSheet): RowCol {
  /* create an array of arrays */
  const rows: Array<Row> = utils.sheet_to_json(ws, { header: 1 });

  /* create column array */
  const range = utils.decode_range(ws["!ref"] || "A1");
  const columns = Array.from({ length: range.e.c + 1 }, (_, i) => ({
    key: String(i), // RDG will access row["0"], row["1"], etc
    name: utils.encode_col(i), // the column labels will be A, B, etc
    editor: textEditor, // enable cell editing
  }));
  return { rows, columns }; // these can be fed to setRows / setColumns
}
// If not needed delete it
export const blobToCsv = async (blob: Blob) => {
  // 1. Read the Blob as an ArrayBuffer
  const arrayBuffer = await blob.arrayBuffer();

  // 2. Parse the ArrayBuffer using xlsx library
  const workbook: WorkBook = read(arrayBuffer, { type: "array" });

  // 3. Get the first worksheet (or specify a particular sheet name)
  const firstSheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[firstSheetName];

  // 4. Convert the worksheet to CSV
  const csvData = utils.sheet_to_csv(worksheet);

  return csvData;
};

export const csvToJson = (csv: string): Array<Record<string, string | null>> => {
  const lines = csv.split("\n"); // Split CSV into lines
  const headers = lines[0].split(",").map(header => header.trim()); // Get the headers from the first line

  const jsonData = lines.slice(1).map((line) => {
    const values = line.split(",");
    const obj: Record<string, string | null> = {}; // Define the object type

    headers.forEach((header, index) => {
      obj[header] = values[index]?.trim() || null; // Map each header to its corresponding value
    });

    return obj;
  });

  return jsonData;
};


interface Spreadsheet {
  sheets: Sheet[];
  properties: SpreadsheetProperties;
}

interface SpreadsheetProperties {
  title: string;
}

interface Sheet {
  properties: SheetProperties;
  data: SheetData[];
}

interface SheetProperties {
  title: string;
}

interface SheetData {
  rowData: RowData[];
}

interface RowData {
  values: Cell[];
}

interface Cell {
  effectiveValue?: EffectiveValue;
}

interface EffectiveValue {
  stringValue?: string;
  numberValue?: number;
  formulaValue?: string;
  boolValue?: boolean;
}

// Function to convert spreadsheet data to XLSX File
export const convertSpreadsheetToXlsxFile = (spreadsheet: Spreadsheet): File => {
  const { sheets, properties } = spreadsheet;

  // Create a new workbook
  const workbook = utils.book_new();

  sheets?.forEach((sheet) => {
    
    const sheetTitle = sheet.properties.title;
    
    const rows = sheet.data[0]?.rowData || [];
    

    // Extract values from each row
    console.log(typeof rows)
    
    const data = rows?.map((row) =>
    {
      return row?.values?.map((cell) => (cell.effectiveValue ? getCellValue(cell.effectiveValue) : ""))
    }
    );
    

    // Add the data to a new worksheet
    const worksheet = utils.aoa_to_sheet(data);

    // Append the worksheet to the workbook
    utils.book_append_sheet(workbook, worksheet, sheetTitle);
  });

  // Convert the workbook to a Blob
  const xlsxBlob = write(workbook, { bookType: "xlsx",type: "buffer" });

  // Convert the Blob to a File object
  const fileName = `${properties.title}.xlsx`.replace(/[\\/:*?"<>|]/g, "_"); // Replace invalid filename characters
  const xlsxFile = new File([xlsxBlob], fileName, {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  return xlsxFile;
};

// Helper function to extract cell value based on type
const getCellValue = (effectiveValue: EffectiveValue): string | number | boolean => {
  if (effectiveValue.stringValue) return effectiveValue.stringValue;
  if (effectiveValue.numberValue !== undefined) return effectiveValue.numberValue;
  if (effectiveValue.formulaValue) return effectiveValue.formulaValue;
  if (effectiveValue.boolValue !== undefined) return effectiveValue.boolValue;
  return "";
};


export const getColumnData = (rows:any,columnKey: string, startRow:number) => {
  return rows.map((row:any,index:number) => { 
    if(index <startRow ) return null
    return row[columnKey as keyof typeof row]}
  ).filter((value: any) => (typeof value ==="string"));
};

export const getRowsAccordingToCategories= (rows:any, originalDataNameOnly:string[],yAxis:number)=>{
  return rows.filter((item:any)=>{
     return (originalDataNameOnly.includes(item[yAxis]))
  })
}
export const getRowsAccordingToCategoriesAlt= (rows:any, originalDataNameOnly:string[],originalCustomData:any,yAxis:number)=>{
  const finalData:any = []
  rows.forEach((item:any)=>{
    if(originalDataNameOnly.includes(item[yAxis])){
      finalData.push({
        ...originalCustomData[item[yAxis]],
        row:item,
      })
    }
  })
 return finalData
}