import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  children?: ReactNode;
  required?: boolean;
  className?: string;
  tag?: "label" | "legend";
}

export const FormLabel = ({ children, required, className, tag = "label" }: Props) => {
  const Tag = tag;

  if (!children) return null;

  return (
    <Tag className={twMerge("text-sm text-[#1F2228] font-semibold w-fit pb-1", className)}>
      {children} {required && " *"}
    </Tag>
  );
};
