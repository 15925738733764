import React from "react";

import { ReactComponent as RightArrowIcon } from "assets/icons/MarketingArrowRightIcon.svg";

interface Props {
  handleClick?: VoidFunction;
}

export const UpgradeToProButton = ({ handleClick }: Props) => (
  <button
    className='w-full flex justify-center items-center mt-6 bg-gradient-purple rounded-xl py-2.5 px-6 text-white text-sm font-bold leading-[18px]'
    onClick={handleClick}
  >
    Upgrade to
    <p className='uppercase ml-1 py-1 px-[3px] bg-white text-[#8766FB] font-semibold leading-[14px] rounded-[3px]'>
      pro
    </p>
    <RightArrowIcon className='-rotate-45 ml-2.5 [&_rect]:fill-white/20 w-5 h-5' />
  </button>
);
