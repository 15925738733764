import React from "react";

import { IChannelData } from "../../../../api/budget/types";
import { ReactComponent as EmptyCheck } from "../../../../assets/icons/emptyCheck.svg";
import { ReactComponent as GoalCheck } from "../../../../assets/icons/goalCheck.svg";

interface IGoalItemProps {
  item: string | IChannelData;
  title: string;
  checked: boolean;
  onSelect: (item: string | IChannelData) => void;
}

export default function GoalItem({ title, checked, onSelect, item }: IGoalItemProps) {
  return (
    <span
      onClick={() => onSelect(item)}
      className={`py-[10px] pl-4 pr-6 gap-x-2.5 flex flex-nowrap items-center border-[1.5px] cursor-pointer hover:border-[#D5D1F7]  ${
        checked ? "border-[#766CE4]" : "border-[#E8E9F0]"
      } text-xs text-[#1F2228] rounded-[56px]`}
    >
      {checked ? <GoalCheck className='w-5 h-5' /> : <EmptyCheck className='w-5 h-5' />}
      <span className='ms-1.5 whitespace-nowrap text-sm font-medium'>{title}</span>
    </span>
  );
}
