import cn from "classnames";
import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  isActive?: boolean;
  children: ReactNode;
  className?: string;
}

export const ChannelStrategyMonthSlide = ({ isActive, children, className }: Props) => {
  return (
    <span
      className={twMerge(
        cn(
          "flex w-[15%] justify-end text-sm font-bold text-[#1F2228] opacity-40 rounded-lg",
          {
            "w-[auto] justify-center text-normal py-2.5 opacity-100 text-[16px] px-2.5 border border-[#D5D1F7] bg-[#F9F9FF] mx-4": isActive,
          },
          className,
        ),
      )}
    >
      {children}
    </span>
  );
};
