import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";

import { setAuthData } from "../actions/user";
import { AuthState } from "../slices/auth-slice";

export function setAuthDataReducer(builder: ActionReducerMapBuilder<AuthState>) {
  builder.addCase(setAuthData, (state, action: PayloadAction<AuthState>) => ({
    ...state,
    ...action.payload,
  }));
}
