import React, { FC, SVGProps } from "react";

import { Box } from "shared/components/Box";

interface Props {
  title: string;
  description: string;
  icon: FC<SVGProps<SVGSVGElement>>;
}

const DashboardFeatureCard = ({ title, description, icon: Icon }: Props) => {
  return (
    <Box className='p-4'>
      <div className='flex'>
        <Icon className='min-w-6 h-6' />
        <div className='ml-3'>
          <h4 className='text-[15px] font-bold text-[#14191A]'>{title}</h4>
          <p className='mt-1.5 text-sm font-medium text-[#5E636E]'>{description}</p>
        </div>
      </div>
    </Box>
  );
};

export default DashboardFeatureCard;
