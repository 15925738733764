import React, { useState } from "react";

// Custom number editor component
const NumberEditor: React.FC<any> = props => {
  const { row, column, onRowChange, onClose } = props;
  const [value, setValue] = useState(row[column.key])
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === "" || /^\d*$/.test(value)) {
       
    setValue(value)

      onRowChange({ ...row, [column.key]: value ===""?0:value });
    }else{
        console.log("here")
    }
  };
  return (
    <div className="p-2 text-[#5E636E] text-sm font-medium dollar-only-input">
      $
    <input
      className={"custom-text-editor-table text-[#5E636E] text-sm font-medium border-0"}
      value={value}
      autoFocus
      onFocus={(e: any) => e.target.select()}
      onChange={handleChange}
      onBlur={() => onClose(true, false)}
    />
    </div>
  );
};

export default NumberEditor;
