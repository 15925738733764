import cn from "classnames";
import React from "react";
import { twMerge } from "tailwind-merge";

import { Button } from "shared/components/buttons/Button";

import { ReactComponent as LeftArrowIcon } from "assets/icons/arrow-left-purple.svg";
import { ReactComponent as CheckIcon } from "assets/icons/purpleTick.svg";

const steps = ["Connect a source", "Locate data", "Confirm categories", "Review the budget"];
type ProgressIndicatorType = {
    formStep:any,
    setFormStep:any,
    handleBack:any,
    enableNext:boolean
}
const ProgressIndicator = ({formStep,setFormStep,handleBack, enableNext}:ProgressIndicatorType)=>{
    return <div className='flex w-full items-center justify-between bg-white px-6 py-2 border-2 border-[#F7F9FB] sticky top-0'>
    <button
      className='flex py-[2px] items-center gap-[10px] text-base font-bold leading-[24px] text-[#766CE4]'
      onClick={() => {
        handleBack()
      }}
      style={{ visibility: formStep > 1 ? "visible" : "hidden" }}
    >
      <LeftArrowIcon />
      Back
    </button>

    <div className='flex gap-[49px] items-center relative'>
      {steps.map((step, ind) => (
        <div key={ind} className='flex gap-3 items-center px-3 bg-white z-[1] sticky'>
          <div
            className={twMerge(
              cn(
                "h-6 w-6 flex justify-center items-center rounded-full border-[1px] border-[#C8CAD0] text-sm font-semibold leading-default text-[#5E636E]",
                { "bg-[#766CE4] shadow-ring2 text-white": formStep === ind + 1, "border-[#766CE4]": formStep > ind + 1 },
              ),
            )}
          >
            {formStep > ind + 1 ? <CheckIcon /> : ind + 1}
          </div>
          <p
            className={twMerge(
              cn("text-base font-medium leading-default text-[#717684]", {
                "font-semibold text-[#766CE4]": formStep === ind + 1,
                "text-[#1F2228]": formStep > ind + 1,
              }),
            )}
          >
            {step}
          </p>
        </div>
      ))}
      <div className='w-full h-[2px] absolute top-1/2 bg-[#C8CAD0]' />
    </div>
    <Button disabled={!enableNext} className={`w-fit h-9 px-[42px] rounded-lg ${enableNext? "opacity-100":"opacity-70"}`} onClick={() => setFormStep(formStep + 1)}>
      Next
    </Button>
  </div>
}

export default ProgressIndicator
