import { WorkflowStrategyAdditionalFields } from "interfaces/workflows.interfaces";

export const getPerformanceTextAreaData = ({
  type,
}: {
  type: WorkflowStrategyAdditionalFields;
}): { label?: string; placeholder?: string } => {
  switch (type) {
    case "company":
      return { label: "What is the company name?", placeholder: "Company name" };
    case "industry":
      return { label: "What is the industry?", placeholder: "Industry" };
    case "audience":
      return { label: "Who is your audience?", placeholder: "Audience" };
    case "company_website":
      return { label: "Company website address", placeholder: "https://example.com" };

    default:
      return {};
  }
};
