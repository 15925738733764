import React from "react";
import { Link } from "react-router-dom";

import { ROUTES } from "constants/routes.constants";

import { ReactComponent as LogoIcon } from "assets/icons/full-logo.svg";

export const Logo = () => {
  return (
    <Link to={ROUTES.dashboard}>
      <LogoIcon />
    </Link>
  );
};
