import { FC } from "react";

type Props = { className?: string; width?: string; height?: string; color?: string };

const SEMIcon: FC<Props> = ({ className, width = "22", height = "22", color = "#4B42B8" }) => {
  return (
    <svg
      width={width}
      height={height}
      color={color}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path d='M12.832 12.8333L15.1237 15.125' stroke='#4B42B8' strokeWidth='1.8' strokeLinejoin='round' />
      <path
        d='M15.0632 16.9814C14.5336 16.4519 14.5336 15.5933 15.0632 15.0638C15.5927 14.5343 16.4512 14.5343 16.9808 15.0638L19.7689 17.8519C20.2984 18.3814 20.2984 19.24 19.7689 19.7695C19.2393 20.299 18.3808 20.299 17.8513 19.7695L15.0632 16.9814Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M14.6654 8.25C14.6654 4.70617 11.7925 1.83333 8.2487 1.83333C4.70487 1.83333 1.83203 4.70617 1.83203 8.25C1.83203 11.7938 4.70487 14.6667 8.2487 14.6667C11.7925 14.6667 14.6654 11.7938 14.6654 8.25Z'
        stroke='currentColor'
        strokeWidth='1.8'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default SEMIcon;
