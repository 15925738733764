import { AxiosError } from "axios";

import Alert from "../shared/components/toasts";

export const showServerError = (error: unknown) => {
  // TODO - remove temporary type below

  const err = error as {
    response: {
      data: {
        errors?: string[];
      };
    };
  };
  const message = err?.response?.data?.errors?.[0] || "Something went wrong. Please try again.";
  // TODO - create alert helpers functions
  Alert("error", message, "Error");
};

export const errorWithLink = (error:unknown,callback?:any)=>{
  if (error instanceof Error) {
    if (!navigator.onLine) {
      Alert("error", "Please check your internet connection.", "Offline!");
        
    } else if (error.message === "Network Error") {
      Alert("error", "Connection error. Unable to connect to the server at present", "Error Occured");
    } else {
      const message = error.message || "Something went wrong. Please try again."
      Alert("error",message,"Error",()=>{ callback?.()})
    }  

    
  }
}

export const showGoogleServerError = (error: unknown)=>{
  if (error instanceof AxiosError) {
    if (!navigator.onLine) {
      Alert("error", "Please check your internet connection.", "Offline!");
        
    } else if (error.message === "Network Error") {
      Alert("error", "Connection error. Unable to connect to the server at present", "Error Occured");
    } else {
      switch(error.status) {  
        case 401: {
          Alert("error", "Google Sheets access not granted", "Error");
          break;
        }
        case 403: {
          Alert("error", "You don't have permission to access this file", "Error");
          break;
        }
        default: {
          const message = error.message || "Something went wrong. Please try again."
          Alert("error", message, "Error");
        }
      }
      
    }    
  }
}

export const showCommonServerError = (error: unknown)=>{
  if (error instanceof Error) {
    if (!navigator.onLine) {
    Alert("error", "Please check your internet connection.", "Offline!");
      
  } else if (error.message === "Network Error") {
    Alert("error", "Connection error. Unable to connect to the server at present", "Error Occured");
  } else {
    const message = error.message || "Something went wrong. Please try again."
    Alert("error", message, "Error");
  }    
  }
}
export const showInfoToast = (error: unknown)=>{
  if (error instanceof Error) {
    if (!navigator.onLine) {
    Alert("error", "Please check your internet connection.", "Offline!");
      
  } else if (error.message === "Network Error") {
    Alert("error", "Connection error. Unable to connect to the server at present", "Error Occured");
  } else {
    const message = error.message || "Something went wrong. Please try again."
    Alert("info", message, "Action Required");
  }    
  }
}