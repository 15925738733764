import {
  GetPaymentStatusPayload,
  GetPaymentStatusResponse,
  GetPaymentUrlPayload,
  GetPaymentUrlResponse,
} from "interfaces/payments.interfaces";

import api from "../index";

// TODO - move api logic to classes

const BASE_API = process.env.REACT_APP_API_URL;

export const apiGetPaymentUrl = async ({ priceId }: GetPaymentUrlPayload): Promise<GetPaymentUrlResponse> => {
  return api(`${BASE_API}/payments/payment-url`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ price_id: priceId }),
  });
};

export const apiGetPaymentStatus = async ({
  sessionId,
}: GetPaymentStatusPayload): Promise<GetPaymentStatusResponse> => {
  return api(`${BASE_API}/payments/status?session_id=${sessionId}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};
