import React, { ReactNode } from "react";

interface Props {
  charsCount?: number;
  maxChars: number;
  children?: ReactNode;
}

export const MessageWithCounter = ({ children, charsCount, maxChars }: Props) => (
  <div className='flex w-full justify-between items-center mt-1.5'>
    {children}
    <p className='font-normal ml-auto text-xs text-[#9A91A8]'>
      {charsCount} / {maxChars}
    </p>
  </div>
);
