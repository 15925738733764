import { CSSObjectWithLabel } from "react-select";

export const SELECT_SHARED_STYLES = {
  menuList: (base: CSSObjectWithLabel) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#F1F0FC !important",
      borderRadius: "4px",
      zIndex: 100,
    },
    "::-webkit-scrollbar-thumb": {
      background: "#9189E9",
      borderRadius: "4px",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
};
