import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useParams, useRoutes } from "react-router-dom";

import { PerformancePlanFormValues, PerformancePlanLocationState } from "interfaces/performance-plan.interfaces";
import { GenerateWorkflowPayload, WorkflowStrategyAdditionalFields } from "interfaces/workflows.interfaces";

import { createWorkflow, generateWorkflow, getWorkflow, setIsWorkflowsLoaded } from "storage/actions/workflows";
import { selectWorkflow } from "storage/selectors/workflows";

import Alert from "shared/components/toasts";

import { useAppDispatch, useAppSelector } from "../appHooks";


export const usePerformancePlan = () => {
  const dispatch = useAppDispatch();

  const { state } = useLocation();
  const { id: workflowParamsId } = useParams();
  
  const {
    workflow_id: workflowId,
    additional_fields: additionalFields,
    workflow_data: workflowData,
    project_id: workflowProjectId,
  } = useAppSelector(selectWorkflow) || {};

  const [isInitiallyReset, setIsInitiallyReset] = useState(false);
  const [isInitiallyValidated, setIsInitiallyValidated] = useState(false);

  const { budget: locationStateBudget } = (state || {}) as PerformancePlanLocationState;
  const formMethods = useForm<PerformancePlanFormValues>({ mode: "all" });

  const { trigger, reset, handleSubmit, formState } = formMethods;

  const additionalFieldsKeysList = useMemo(
    () => Object.keys(additionalFields || {}) as WorkflowStrategyAdditionalFields[],
    [additionalFields],
  );

  const isFormErrorsExists = !!Object.keys(formState?.errors)?.length;

  const isSubmitDisabled = Boolean(isFormErrorsExists || !isInitiallyValidated);
  //const isSubmitDisabled = Boolean(isFormErrorsExists || !isInitiallyValidated || (workflowId && workflowData));

  const onSubmit = handleSubmit((values: PerformancePlanFormValues) => {
    if (!workflowId) {
      const payload: any = {
        strategy_id: state.strategy_id, project_id: state.project_id,
        additional_fields: values,
        
      };
      dispatch(createWorkflow(payload))
        .unwrap()
        .then((data) => {
          dispatch(setIsWorkflowsLoaded(null));
        }).catch(error=>{
          Alert("error", error.message, "Error");
          console.log("Error",error)

        });
      
      return
    };

    const payload: GenerateWorkflowPayload = {
      workflow_id: workflowId,
      additional_fields: values,
    };

    dispatch(generateWorkflow(payload));
  });

  useEffect(() => {
    if (!workflowParamsId || Number(workflowParamsId) === workflowId) return;

    dispatch(getWorkflow(workflowParamsId));
  }, [dispatch, workflowId, workflowParamsId]);

  useEffect(() => {
    const dataToReset = { ...additionalFields };

    if (locationStateBudget && additionalFieldsKeysList?.includes("budget")) {
      dataToReset.budget = locationStateBudget;
    }

    reset(dataToReset);

    if (additionalFieldsKeysList?.length) {
      setIsInitiallyReset(true);
    }
  }, [additionalFieldsKeysList, additionalFields, locationStateBudget, reset]);

  useEffect(() => {
    if (isInitiallyReset) trigger().then(() => setIsInitiallyValidated(true));
  }, [isInitiallyReset, trigger]);

  return { formMethods, onSubmit, isSubmitDisabled, typesToRender: additionalFieldsKeysList, workflowData, workflowId, workflowProjectId };
};
