import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import dayjs from "dayjs";
import React from "react";

import { FULL_DATE_FORMAT_WITH_COMMA } from "constants/date-formats.constants";

import { ProjectListItem } from "interfaces/projects.interfaces";
import { NumberOrNull } from "interfaces/shared-types.interfaces";

import { ChannelsGroup } from "shared/components/channels-group/ChannelsGroup";
import { Table } from "shared/components/table/Table";

interface Props {
  data: ProjectListItem[];
  selectedId: NumberOrNull;
  handleSelectBudgetId: (value: number) => void;
}

export const BudgetsTable = ({ data, handleSelectBudgetId, selectedId }: Props) => {
  const columnHelper = createColumnHelper<ProjectListItem>();

  const columns = [
    // columnHelper.accessor("project_id", {
    //   header: "S. No.",
    //   cell: info => info.renderValue(),
    // }),
    columnHelper.accessor("name", {
      header: "Project Name",
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor("channels", {
      header: "Channels",
      cell: info => <ChannelsGroup channels={info.getValue()} />,
    }),
    columnHelper.accessor("date_created", {
      id: "last_modified",
      header: "Last modified",
      cell: info => dayjs(info.getValue()).format(FULL_DATE_FORMAT_WITH_COMMA),
    }),
    columnHelper.accessor("date_created", {
      header: "Created",
      cell: info => dayjs(info.getValue()).format(FULL_DATE_FORMAT_WITH_COMMA),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <Table
        className='max-h-[234px]'
        table={table}
        handleClickRow={({ project_id }) => handleSelectBudgetId(project_id)}
        idKey='project_id'
        selectedId={selectedId}
        hover={true}
      />
    </div>
  );
};
