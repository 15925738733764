import React, { useEffect } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { Tooltip } from "react-tooltip";

import { generateUniqueId } from "../../../utils";
import Button from "../auth-buttons/Button";
import Checkbox from "../checkbox/Checkbox";
import DownIcon from "../IconsComponents/DownIcon";
import FilterIcon from "../IconsComponents/FilterIcon";

const FilterDropdown = ({
  channels,
  activeChannels,
  setActiveChannels,
  baseState,
  isYearly,
  handleInputChange,
}: any) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedFilters, setSelectedFilters] = React.useState([]);

  useEffect(() => {
    const selected = activeChannels?.filter((channel: any) => !channel.visible);
    setSelectedFilters(selected);
  }, [activeChannels]);

  const setChannel = (channelName: string) => {
    setActiveChannels(
      activeChannels?.map((channel: any) =>
        channel?.name === channelName ? { ...channel, visible: !channel.visible } : channel,
      ),
    );
  };

  return (
    <div className='relative'>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`flex items-center gap-x-1.5 pr-8 text-xs font-medium cursor-pointer ${
          isOpen ? "text-[#766CE4]" : selectedFilters?.length ? "text-[#766CE4]" : "text-[#717684]"
        }`}
      >
        <FilterIcon
          color={isOpen ? "#766CE4" : selectedFilters?.length ? "text-[#766CE4]" : "#717684"}
          className='w-5 h-5'
        />
        Filter
        {selectedFilters?.length ? (
          <p className='flex items-center justify-center px-2 w-[18px] bg-[#766CE4] text-white text-xs font-medium rounded-full'>
            {selectedFilters?.length}
          </p>
        ) : null}
        <DownIcon
          color={isOpen ? "#766CE4" : selectedFilters?.length ? "#766CE4" : "#717684"}
          className={isOpen ? "rotate-180" : ""}
        />
      </div>
      {isOpen && (
        <div className='absolute flex-col text-black right-8 bot-3 flex h-fit p-2 rounded-[14px] bg-white shadow-2xl shadow-[0_4px_29px_0px_rgba(0,0,0,0.12) z-20 '>
          <div className='flex items-center pb-2'>
            <label className='relative cursor-pointer'>
              <div className=' bg-white rounded-full border border-[rgba(0,0,0,0.05)]'>
                <input type='checkbox' className='sr-only peer' checked={isYearly} onChange={handleInputChange} />
                <div
                  className={
                    "w-[200px] h-8 flex items-center bg-white rounded-full text-[14px] font-bold after:flex after:items-center after:justify-center peer after:content-['Quarterly'] peer-checked:after:content-['Yearly'] peer-checked:after:translate-x-full after:absolute  after:bg-[#F7F9FB] after:border after:border-border-[rgba(0,0,0,0.05) after:rounded-full after:h-8 after:w-[100px] after:transition-all peer-checked:after:border peer-checked:after:border-[rgba(0,0,0,0.05)]"
                  }
                >
                  <span className='absolute text-[14px] font-bold left-8 text-gray-500 peer-checked:text-[#000]'>
                    Quarterly
                  </span>
                  <span className='absolute text-[14px] font-bold right-8 text-gray-500 peer-checked:text-[#000]'>
                    Yearly
                  </span>
                </div>
              </div>
            </label>
          </div>
          <div className='text-[#1F2228] text-base font-bold pb-2.5'>Channels</div>
          <div className='flex flex-col gap-y-3 pb-2'>
            {channels?.map((channel: any) => (
              <Checkbox
                key={generateUniqueId()}
                checked={Boolean(activeChannels.find((el: any) => el.name === channel.name && el.visible))}
                setChecked={setChannel}
                title={channel.name}
                style={channel.style}
              />
            ))}
          </div>
          <div className='w-full border border-[#E9EAEC] mb-2'></div>
          <div className='flex items-center justify-end gap-x-3'>
            <Button
              classes='py-1 px-[10px] max-w-[60px] max-h-[26px] rounded-[8px] !bg-transparent !hover:bg-transparent !text-black'
              onClick={() => {
                setActiveChannels(baseState);
                setIsOpen(!isOpen);
                handleInputChange({ target: { checked: !isYearly } });
              }}
            >
              Cancel
            </Button>
            <Button
              classes='py-1 px-[10px] max-w-[60px] max-h-[26px] rounded-[8px] '
              onClick={() => setIsOpen(!isOpen)}
            >
              Apply
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const BarChart = ({ project, filteredChannels, transformedData }: any) => {
  const [showChannels, setShowChannels] = React.useState(
    filteredChannels?.map((channel: any) => ({ name: channel.name, visible: true })),
  );
  const [isYearly, setIsYearly] = React.useState(true);

  function calculateYAxisLabels(max: number, steps: number) {
    if (max <= 50000) {
      const upperLimit = max;
      const stepSize = Math.ceil(upperLimit / 1000) / (steps - 1);
      const labels = Array.from({ length: steps }, (_, i) => i * stepSize);

      return labels;
    } else {
      const stepSize = (Math.ceil(max / 1000 / 100) * 100) / (steps - 1);
      const labels = Array.from({ length: steps }, (_, i) => i * stepSize);
      return labels;
    }
  }

  const visibleData = transformedData
    .map((item: any) => ({
      ...item,
      value: item.value.filter((val: any) =>
        showChannels.find((channel: any) => channel.name === val.channel && channel.visible),
      ),
    }))
    .filter((item: any) => item.value.length > 0);

  const monthlyTotals = visibleData.map((item: any) =>
    item.value.reduce((sum: number, obj: any) => sum + obj.value, 0),
  );

  const dataMax = Math.max(...monthlyTotals);
  const defaultYAxisLabels = [0, 100, 200, 300, 500];
  let yAxisLabels: number[];

  if (dataMax === -Infinity) {
    yAxisLabels = defaultYAxisLabels;
  } else {
    yAxisLabels = calculateYAxisLabels(dataMax, 5);
  }

  function getCurrentQuarter() {
    const date = new Date();
    const quarter = Math.floor((date.getMonth() + 3) / 3);
    const year = date.getFullYear();
    return { quarter, year };
  }

  function getDataForCurrentQuarter(data: { name: string }[]) {
    const { quarter } = getCurrentQuarter();
    const monthToQuarter: { [key: string]: number } = {
      January: 1,
      February: 1,
      March: 1,
      April: 2,
      May: 2,
      June: 2,
      July: 3,
      August: 3,
      September: 3,
      October: 4,
      November: 4,
      December: 4,
    };

    return data.filter((item) => {
      const itemQuarter = monthToQuarter[item.name as keyof typeof monthToQuarter];
      return itemQuarter === quarter;
    });
  }

  const currentQuarterData = getDataForCurrentQuarter(transformedData);

  const renderedData = isYearly ? transformedData : currentQuarterData;

  return (
    <div className='flex flex-col w-full h-full max-h-[290px] bg-white rounded-2xl'>
      <div className='flex w-full items-center justify-center  py-4'>
        <div className='flex w-full items-center justify-center gap-x-7'>
          {filteredChannels?.map((channel: any, i: number) => (
            <span
              className='flex items-center gap-x-3 text-xs font-semibold'
              key={generateUniqueId()}
              style={{
                opacity: showChannels.some(
                  (showChannel: any) => showChannel.name === channel.name && !showChannel.visible,
                )
                  ? 0.5
                  : 1,
              }}
            >
              <p
                className={"h-4 w-4 rounded-full"}
                style={{
                  ...channel.style,
                }}
              ></p>{" "}
              {channel.name}
            </span>
          ))}
        </div>
        <FilterDropdown
          isYearly={isYearly}
          handleInputChange={(e: any) => setIsYearly(e.target.checked)}
          channels={filteredChannels}
          activeChannels={showChannels}
          setActiveChannels={setShowChannels}
          baseState={filteredChannels?.map((channel: any) => ({
            name: channel?.name,
            visible: true,
          }))}
        />
      </div>
      <div className='flex flex-col bg-white rounded-2xl px-8 pb-4 w-full relative h-[234px]  '>
        <div className='h-full'>
          <div className='flex flex-col-reverse justify-between w-full h-full'>
            {yAxisLabels.map((label, index) => (
              <div
                key={generateUniqueId()}
                className='text-xs flex items-center text-right justify-end text-[#9EA2AD] gap-x-5'
              >
                <span className='w-[42px] text-right'>
                  ${label > 1000 ? parseFloat((label / 1000).toFixed(1)) + "M" : label + "K"}
                </span>{" "}
                <div className='h-[1px] w-full bg-[#ECE9F1] '></div>
              </div>
            ))}
          </div>
        </div>
        <div className='flex flex-col w-full ml-8 mt-[-32px] px-8'>
          <div className='flex w-full justify-around items-end h-6'>
            {renderedData.map((item: any, index: number) => {
              return (
                <div key={generateUniqueId()} className='flex flex-col items-center relative'>
                  {item.value?.length >= 1 &&
                    item.value
                      .sort((a: any, b: any) => b.value - a.value)
                      .map((el: any, i: any) => {
                        const totalMontsBudget = item.value.reduce((total: number, el: any) => {
                          const isVisible = showChannels.find(
                            (channel: any) => channel.name === el.channel && channel.visible,
                          );

                          if (isVisible) {
                            total += el.value / 1000;
                          }

                          return total;
                        }, 0);

                        return (
                          <div
                            className='flex flex-col items-center'
                            key={generateUniqueId()}
                            style={{
                              display: showChannels.find(
                                (channel: any) => channel.name === el.channel && channel.visible,
                              )
                                ? "flex"
                                : "none",
                            }}
                          >
                            <div
                              data-tooltip-id='my-tooltip'
                              data-tooltip-html={renderToStaticMarkup(
                                <div className='flex flex-col items-start gap-y-1 text-black'>
                                  <div className='flex items-start justify-start gap-x-1 text-[10px] font-semibold'>
                                    Channel: {el.channel}
                                  </div>{" "}
                                  <div className='flex items-center gap-x-1 text-[10px] font-semibold'>
                                    Budget: ${el.value?.toLocaleString("en-US")}
                                  </div>{" "}
                                  <div className='flex items-center gap-x-1 text-[10px] font-semibold'>
                                    Split:
                                    {parseFloat((el.value / 1000 / (totalMontsBudget / 100)).toFixed(1))}%
                                  </div>
                                </div>,
                              )}
                              data-tooltip-place='right'
                              className={`w-[30px] bar-animation ${i !== 0 ? "border-t" : ""} border-white`}
                              style={{
                                height: `${(el.value / (yAxisLabels[4] * 1000)) * 190}px`,
                                maxHeight: "200px",
                                background: `${el.color}`,
                                ["--bar-height" as any]: ` ${(el.value / (yAxisLabels[4] * 1000)) * 190}px`,
                              }}
                            >
                              {!showChannels.every((channel: any) => channel.visible === true) ? (
                                <span
                                  className='w-[30px] absolute top-[-15px] text-xs text-center'
                                  style={{
                                    color: showChannels.find(
                                      (channel: any) => channel.name === el.channel && channel.visible,
                                    )
                                      ? "#1F2228"
                                      : "transparent",
                                  }}
                                >
                                  {totalMontsBudget >= 1000
                                    ? (totalMontsBudget / 1000).toFixed(0) + "M"
                                    : totalMontsBudget >= 1
                                      ? totalMontsBudget.toFixed(0) + "K"
                                      : totalMontsBudget.toFixed(2) + "K"}
                                </span>
                              ) : (
                                i === 0 && (
                                  <span
                                    className='w-[30px] absolute top-[-15px] text-xs text-center'
                                    style={{
                                      color: showChannels.find(
                                        (channel: any) => channel.name === el.channel && channel.visible,
                                      )
                                        ? "#1F2228"
                                        : "transparent",
                                    }}
                                  >
                                    {totalMontsBudget >= 1000
                                      ? (totalMontsBudget / 1000).toFixed(0) + "M"
                                      : totalMontsBudget >= 1
                                        ? totalMontsBudget.toFixed(0) + "K"
                                        : totalMontsBudget.toFixed(2) + "K"}
                                  </span>
                                )
                              )}
                            </div>
                            <Tooltip
                              id='my-tooltip'
                              style={{
                                zIndex: 10000,
                                background: "#fff",
                                borderRadius: "8px",
                              }}
                              border={"1px solid #000"}
                            />
                          </div>
                        );
                      })}
                </div>
              );
            })}
          </div>
          <div className='flex justify-around items-start w-full mt-1 '>
            {renderedData.map((item: any) => (
              <span key={generateUniqueId()} className='text-xs w-full text-center text-[#9EA2AD]'>
                {item?.name?.substring(0, 3)}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarChart;
