import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { ROUTES } from "constants/routes.constants";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";

import { getMeData } from "storage/actions/user";

import { ProtectedRoute } from "shared/routes/ProtectedRoute";

import ConnectDataSource from "pages/budget/ConnectDataSource";
import { Memberships } from "pages/memberships/Memberships";
import { Personalize } from "pages/personalize/Personalize";
import { WorkflowDetails } from "pages/workflow/WorkflowDetails";

import ConfirmPassword from "../pages/auth/ConfirmPassword";
import NewPassword from "../pages/auth/NewPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import VerifyCode from "../pages/auth/VerifyCode";
import Budget from "../pages/budget/Budget";
import BudgetIntelligence from "../pages/budget/BudgetIntelligence/BudgetIntelligence";
import BudgetPlannerTable from "../pages/budget/BudgetPlannerTable";
import StepForm from "../pages/budget/StepForm";
import Dashboard from "../pages/dashboard/Dashboard";
import Homepage from "../pages/homepage/Homepage";
import { PerformancePlan } from "../pages/performance-plan/PerformancePlan";
import Workflow from "../pages/workflow/Workflow";
import PublicRoute from "../router/public-route/PublicRoute";
import BarChart from "../shared/components/charts/BarChart";
import { ProtectedConditionalRoute } from "../shared/routes/ProtectedConditionalRoute";
import { getMyPersonalizationData } from "../storage/actions/personalization";
import { selectMyPersonalizationData } from "../storage/selectors/personalization";
import { selectMembershipPlan } from "../storage/selectors/user";

// TODO - map Routes
export const PagesWrapper = () => {
  const dispatch = useAppDispatch();

  const isAuth = useAppSelector(state => state.auth.isAuthenticated);
  const personalizeData = useAppSelector(selectMyPersonalizationData);
  const membershipPlan = useAppSelector(selectMembershipPlan);

  useEffect(() => {
    if (!isAuth) return;

    dispatch(getMyPersonalizationData());
  }, [isAuth, dispatch]);

  useEffect(() => {
    if (isAuth !== null) return;

    dispatch(getMeData());
  }, [dispatch, isAuth]);

  if (isAuth === null) return null;
  // TODO - map Routes
  return (
    <Routes>
      <Route element={<ProtectedRoute to={ROUTES.dashboard} isAuth={!isAuth} />}>
        <Route element={<PublicRoute />}>
          <Route path={ROUTES.signInWithToken} element={<SignIn />} />
        </Route>
        <Route path={ROUTES.signUp} element={<SignUp />} />
        <Route path={ROUTES.resetPassword} element={<ResetPassword />} />
        <Route path={ROUTES.confirmPassword} element={<ConfirmPassword />} />
        <Route path={ROUTES.setupPassword} element={<NewPassword />} />
        <Route path={ROUTES.verifyCode} element={<VerifyCode />} />
      </Route>
      <Route element={<ProtectedConditionalRoute personalizeData={personalizeData} membershipPlan={membershipPlan} isAuth={isAuth} />}>
        <Route element={<ProtectedRoute to={ROUTES.signIn} isAuth={isAuth} />}>
          <Route path={ROUTES.home} element={<Homepage />} />
          <Route path={ROUTES.chart} element={<BarChart />} />
          <Route path={ROUTES.dashboard} element={<Dashboard />} />
          <Route path={ROUTES.budget} element={<Budget />} />
          <Route path={ROUTES.budgetForm} element={<StepForm />} />
          <Route path={ROUTES.editBudgetForm + "/:id"} element={<StepForm />} />
          <Route path={ROUTES.uploadBudgetForm} element={<ConnectDataSource />} />
          <Route path={ROUTES.budgetOverview} element={<BudgetPlannerTable />} />
          <Route path={ROUTES.budgetIntelligence} element={<BudgetIntelligence />} />
          <Route path={ROUTES.workflow} element={<Workflow />} />
          <Route path={ROUTES.workflowDetails} element={<WorkflowDetails />} />
          <Route path={ROUTES.memberships} element={<Memberships />} />
          <Route path={ROUTES.personalize} element={<Personalize />} />
          {/*<Route path={ROUTES.verifyEmail} element={<VerifyEmail />} />*/}
          <Route path={ROUTES.performancePlan} element={<PerformancePlan />} />
          <Route path={ROUTES.createPerformancePlan} element={<PerformancePlan />} />
        </Route>
      </Route>
    </Routes>
  );
};
