import React from "react";
import { twMerge } from "tailwind-merge";

// TODO - Refactoring
export default function Checkbox({ className, checked, setChecked, title, style, checkboxHeight = 4, checkboxWidth = 4 }: any) {
  return (
    <div className={twMerge("flex items-center justify-between", className)} onClick={() => setChecked(title)}>
      <div className='flex items-center text-sm font-medium gap-x-2 cursor-pointer'>
        <label className='flex items-center cursor-pointer'>
          <input type='checkbox' className='sr-only' checked={checked} onChange={() => ""} />
          <div
            className={`${
              checked ? "bg-[#766CE4]" : "bg-gray-200"
            } w-${checkboxWidth} h-${checkboxHeight} rounded-[6px] flex items-center justify-center`}
          >
            {checked && (
              <svg
                className='w-3 h-3 text-white'
                fill='none'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='4'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path d='M5 13l4 4L19 7'></path>
              </svg>
            )}
          </div>
        </label>
        {title}
      </div>
      <div className='flex items-center'>
        <p className={"h-3 w-3 rounded-full mr-2"} style={style}></p>
      </div>
    </div>
  );
}
