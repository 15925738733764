import React, { useState } from "react";
import { twMerge } from "tailwind-merge";

import { ButtonVariants } from "constants/shared/button.constants";
import { INPUT_SHARED_CLASSNAME } from "constants/shared/fom-elements.constants";
import { TypographyVariants } from "constants/shared/typography.constants";

import { formatNumberToUSLocale } from "utils/shared.utils";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";

import { handleProjectForm } from "storage/slices/project-slice";

import AddMoreItem from "shared/components/budget/goalItem/AddMoreItem";
import GoalItem from "shared/components/budget/goalItem/GoalItem";
import ItemInput from "shared/components/budget/goalItem/ItemInput";
import { Button } from "shared/components/buttons/Button";
import { FormLabel } from "shared/components/form-elements/FormLabel";
import { Typography } from "shared/components/Typography";

import { ReactComponent as DashIcon } from "assets/icons/dashIcon.svg";
import { ReactComponent as MinusIcon } from "assets/icons/minusIcon.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plusIcon.svg";

interface IGoalsProps {
  nextStepHandler: () => void;
}

const staticMetrics = ["Increase Sales", "Increase Traffic", "Lower CAC", "Increase CLTV", "Improve CSAT", "Increase Revenue"];

export default function GoalsProjectForm({ nextStepHandler }: IGoalsProps) {
  const dispatch = useAppDispatch();

  const formData = useAppSelector(state => state.project.newProjectForm);
  const [goalItem, setGoalItem] = useState<string[]>(formData.goals || []);

  const [isItemInputVisible, setIsItemInputVisible] = useState(false);

  const [growPercentage, setGrowPercentage] = useState<number>(formData.revenue_rate || 0);

  const selectBudgetHandler = (value: string | null, key: string) => {
    const numericValue = Number(value?.toString().replace(/,/g, ""));
    if (!isNaN(numericValue)) {
      dispatch(handleProjectForm({ ...formData, [key]: numericValue }));
    }
  };

  const growPercentageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value;
    if (isNaN(value) || value < 0 || value > 100) {
      return;
    } else {
      setGrowPercentage(value);
      dispatch(handleProjectForm({ ...formData, revenue_rate: value }));
    }
  };

  const selectGoalHandler = (goal: any) => {
    setGoalItem(prev => {
      if (prev.find(item => item === goal)) {
        const newGoals = prev.filter(item => item !== goal);
        dispatch(handleProjectForm({ ...formData, goals: [...newGoals] }));
        return newGoals;
      } else if (prev.length < 3) {
        dispatch(handleProjectForm({ ...formData, goals: [...prev, goal] }));
        return [...prev, goal];
      }
      return [...prev];
    });
  };

  const handleAddGoal = (goalName: string) => {
    setGoalItem([...goalItem, goalName]);
    if (Array.isArray(formData.goals)) {
      dispatch(handleProjectForm({ ...formData, goals: [...formData?.goals, goalName] }));
    }
    setIsItemInputVisible(false);
  };

  const combinedItems = [...staticMetrics, ...goalItem];
  const uniqueItems = combinedItems.filter((item, index, self) => index === self.findIndex(t => t === item));

  return (
    <div className='rounded-xl flex flex-col h-full w-full gap-y-6 grow-0'>
      <Typography variant={TypographyVariants.H2}>
        Pick your <span className='text-[#766CE4]'>goal</span>
      </Typography>
      <div className='flex justify-start w-full h-full items-start'>
        <div className='flex flex-col w-full h-full gap-y-6'>
          <div className='flex flex-col  max-w-full bg-[#fff] py-9 px-8 border-[1.5px] border-[#EFF0F2] rounded-[22px]'>
            <div className='text-[18px] font-bold flex items-center justify-start pb-2 text-[#1F2228]'>
              Select the metric(s) you want to improve *
            </div>
            <p className='text-[13px] font-medium text-[#717684] pb-8'>Select up to 3 metrics </p>
            <div className='flex items-center gap-4 flex-wrap max-w-full'>
              {uniqueItems?.map((itemGoal, index) => (
                <GoalItem
                  item={itemGoal}
                  key={index}
                  title={itemGoal}
                  checked={!!goalItem.find(item => item === itemGoal)}
                  onSelect={selectGoalHandler}
                />
              ))}
              {isItemInputVisible && <ItemInput currentMetrics={uniqueItems} onSubmit={handleAddGoal} />}
              <AddMoreItem
                disabled={Array.isArray(formData.goals) && formData.goals?.length === 3}
                clickHandler={() => setIsItemInputVisible(true)}
              />
            </div>
          </div>
          <div className='bg-[#fff] border-[1.5px] border-[#EFF0F2] rounded-[22px] p-9 flex flex-col'>
            <div className='flex items-start w-full font-bold text-base pb-9'>Estimated company revenue for the year *</div>
            <div className='flex items-center justify-start w-full h-fit gap-x-4 max-h-[180px]'>
              {!formData?.isRevenueUnknown ? (
                <>
                  <div>
                    <FormLabel>From</FormLabel>
                    <div className='relative max-w-[190px] flex items-center text-[#1F2228] font-medium text-sm'>
                      <span className='flex items-center pointer-events-none w-[18px] h-[18px] absolute top-1/2 transform -translate-y-1/2 left-3.5 text-[#9EA2AD]'>
                        $
                      </span>
                      <input
                        onChange={e => selectBudgetHandler(e.target.value, "revenue_from")}
                        value={formData.revenue_from ? formatNumberToUSLocale(formData.revenue_from) : ""}
                        placeholder='0'
                        type='text'
                        className={twMerge("w-full py-[9px] pr-[55px] pl-6 block", INPUT_SHARED_CLASSNAME)}
                      />
                      <span className='cursor-pointer w-6 h-[18px] absolute top-1/2 transform -translate-y-1/2 right-6 text-[#333740]'>
                        USD
                      </span>
                    </div>
                  </div>

                  <DashIcon className='hidden 2xl:block' />
                  <div>
                    <FormLabel>To</FormLabel>
                    <div className='relative max-w-[190px] flex items-center text-[#1F2228] font-medium text-sm'>
                      <span className='flex items-center pointer-events-none w-[18px] h-[18px] absolute top-1/2 transform -translate-y-1/2 left-3.5 text-[#9EA2AD]'>
                        $
                      </span>
                      <input
                        value={formData.revenue_to ? formatNumberToUSLocale(formData.revenue_to) : ""}
                        onChange={e => selectBudgetHandler(e.target.value, "revenue_to")}
                        placeholder='0'
                        type='text'
                        className={twMerge("w-full py-[9px] pr-[55px] pl-6 block", INPUT_SHARED_CLASSNAME)}
                      />
                      <span className='cursor-pointer w-6 h-[18px] absolute top-1/2 transform -translate-y-1/2 right-6 text-[#333740]'>
                        USD
                      </span>
                    </div>
                  </div>

                  <label className='flex flex-col items-start ml-0 2xl:ml-[56px] w-fit gap-y-1'>
                    <div className='text-sm text-[#1F2228] font-semibold w-fit pb-1'>Revenue growth rate</div>
                    <div className='relative flex items-center text-[#2c1a49] font-medium text-sm gap-x-4'>
                      <div
                        onClick={() => {
                          setGrowPercentage(prev => {
                            if (prev === 0) return prev;
                            return prev - 1;
                          });
                          dispatch(
                            handleProjectForm({
                              ...formData,
                              revenue_rate: growPercentage === 0 ? 0 : growPercentage - 1,
                            }),
                          );
                        }}
                        className='flex items-center justify-center rounded-full bg-[#C8C4F4] h-8 w-8 cursor-pointer'
                      >
                        <MinusIcon />
                      </div>
                      <input
                        onChange={growPercentageHandler}
                        value={formData.revenue_rate || growPercentage || ""}
                        placeholder='0'
                        type='tel'
                        maxLength={3}
                        className={twMerge(INPUT_SHARED_CLASSNAME, "py-[9px] px-8 max-w-[100px]")}
                      />
                      <span className='cursor-pointer flex items-center text-[#9EA2AD] w-6 h-6 absolute top-1/2 transform -translate-y-1/2 right-[68px]  pointer-events-none'>
                        %
                      </span>
                      <div
                        onClick={() => {
                          setGrowPercentage(prev => {
                            if (prev === 100) return prev;
                            return prev + 1;
                          });

                          dispatch(handleProjectForm({ ...formData, revenue_rate: growPercentage + 1 }));
                        }}
                        className='flex items-center justify-center rounded-full bg-[#C8C4F4] h-8 w-8 cursor-pointer'
                      >
                        <PlusIcon />
                      </div>
                    </div>
                  </label>
                </>
              ) : (
                <div className='bg-[#F7F1F2] rounded-[14px] p-4 flex flex-col items-start w-full'>
                  <div className='font-bold text-[15px] text-[#F88C6A] pb-3'>Basic strategy requested</div>
                  <div className='text-xs font-normal '>
                    Our intelligence platform might not be able to create a personalized marketing budget and strategy as revenue range is
                    missing.
                  </div>
                </div>
              )}
            </div>
            <label className='inline-flex items-center cursor-pointer w-fit justify-start pt-6 gap-x-4'>
              <span className='text-[15px] font-medium text-[#1F2228]'>I don&apos;t know</span>
              <input
                type='checkbox'
                value=''
                checked={formData?.isRevenueUnknown}
                className='sr-only peer outline-none focus:outline-none'
                onChange={() => {
                  setGrowPercentage(0);
                  dispatch(
                    handleProjectForm({
                      ...formData,
                      isRevenueUnknown: !formData?.isRevenueUnknown,
                      revenue_from: 0,
                      revenue_to: 0,
                      revenue_rate: 0,
                    }),
                  );
                }}
              />
              <div
                className="relative w-12 h-6 bg-[#E9EAEC]  rounded-full peer peer-checked:after:translate-x-[24px] rtl:peer-checked:after:-translate-x-full peer-checked:after:border-[#fff]
          after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#fff] after:border after:border-[#fff]
          after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#766CE4] focus:outline-none shadow-[inset_0_2px_4px_rgba(0,0,0,0.1)]"
              ></div>
            </label>
          </div>

          <div className='flex justify-end w-full mt-auto 1xl:m-auto 1xl:mb-[140px]'>
            <Button
              className='w-fit'
              variant={ButtonVariants.SMALL}
              disabled={!formData?.goals?.length || (!formData?.isRevenueUnknown && (!formData?.revenue_from || !formData?.revenue_to))}
              onClick={() => nextStepHandler()}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
