import { FC } from "react";

type Props = { className?: string; width?: string; height?: string; color?: string };

const SMSIcon: FC<Props> = ({ className, width = "22", height = "22", color = "#814D8E" }) => {
  return (
    <svg
      width={width}
      height={height}
      color={color}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M7.79232 17.4166H7.33398C3.66732 17.4166 1.83398 16.5 1.83398 11.9166V7.33331C1.83398 3.66665 3.66732 1.83331 7.33398 1.83331H14.6673C18.334 1.83331 20.1673 3.66665 20.1673 7.33331V11.9166C20.1673 15.5833 18.334 17.4166 14.6673 17.4166H14.209C13.9248 17.4166 13.6498 17.5541 13.4757 17.7833L12.1007 19.6166C11.4957 20.4233 10.5057 20.4233 9.90065 19.6166L8.52565 17.7833C8.37898 17.5816 8.03982 17.4166 7.79232 17.4166Z'
        stroke='currentColor'
        strokeWidth='1.8'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.41602 7.33331H15.5827'
        stroke='currentColor'
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.41602 11.9167H11.916'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default SMSIcon;
