import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import * as yup from "yup";

import { ROUTES } from "constants/routes.constants";
import { ButtonVariants } from "constants/shared/button.constants";
import { TypographyVariants } from "constants/shared/typography.constants";

import { useAppDispatch } from "hooks/appHooks";

import { sendConfirmationCode } from "storage/actions/auth";

import { Button } from "shared/components/buttons/Button";
import { Input } from "shared/components/form-elements/Input";
import { Typography } from "shared/components/Typography";
import { AuthLayout } from "shared/layouts/AuthLayout";

import { ReactComponent as BackButton } from "assets/icons/BackButton.svg";
import { ReactComponent as BigLockIcon } from "assets/icons/BigLock.svg";
import { ReactComponent as EmailIcon } from "assets/icons/sms.svg";

const schema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .matches(/^[a-zA-Z0-9._%+-]+@[a-z]+\.[a-z]{2,}$/, "Email is not valid")
    .email("Email is not valid"),
});

export default function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string }>({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const onSubmit = async (data: { email: string }) => {
    localStorage.setItem("email", data.email);
    dispatch(sendConfirmationCode(data)).then((res: any) => {
      if (res?.success) navigate(ROUTES.verifyCode);
    });
  };
  return (
    <AuthLayout>
      <div className='grow px-3 min-h-screen  flex flex-col justify-center'>
        <div className='h-fit sm:w-[464px] flex flex-col mx-auto gap-6'>
          <div className='mb-10'>
            <div className='flex justify-center'>
              <BigLockIcon className='h-16 w-16' />
            </div>

            <Typography className='my-6 text-center text-[#302C34]' variant={TypographyVariants.H1}>
              Reset <span className='text-[#9A91A8]'>your password</span>
            </Typography>
            <Typography variant={TypographyVariants.LG_MEDIUM} className='text-center'>
              We will send a password reset link to your email
            </Typography>
          </div>

          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <div className='space-y-6'>
              <Input placeholder='Email' {...register("email", { required: true })} icon={EmailIcon} error={errors?.email} />

              <Button type='submit'>Send Email</Button>
            </div>
          </form>

          <div className='flex items-center justify-center text-sm text-center text-black hover:cursor-pointer'>
            <Link
              className='flex items-center justify-center underline-offset-1 gap-x-1 text-sm font-medium text-[#1F2228] hover:underline'
              to={ROUTES.signIn}
            >
              <BackButton />
              Back to Log In
            </Link>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}
