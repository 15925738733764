import { createSlice } from "@reduxjs/toolkit";

import { IUserData } from "api/user/types";

import { setAuthDataReducer } from "../reducers/auth";

export interface AuthState {
  user: IUserData | null;
  isAuthenticated: boolean | null;
}

const initialState: AuthState = {
  user: null,
  isAuthenticated: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: builder => {
    setAuthDataReducer(builder);
  },
});

export const authReducer = authSlice.reducer;
