import React from "react";

import { MembershipPlanDetailsAdvantage } from "interfaces/memberships.interfaces";

import { MembershipAdvantagesListItem } from "./MembershipAdvantagesListItem";

interface Props {
  advantagesList: MembershipPlanDetailsAdvantage[];
}

export const MembershipAdvantagesList = ({ advantagesList }: Props) => {
  return (
    <ul className='space-y-2 xl:space-y-4 mt-4'>
      {advantagesList?.length
        ? advantagesList.map((advantage, index) => <MembershipAdvantagesListItem key={index} {...advantage} />)
        : null}
    </ul>
  );
};
