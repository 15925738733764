export enum DropdownVariants {
  DEFAULT = "default",
  SECONDARY = "secondary",
}

export const DropdownStyles: Record<
  DropdownVariants,
  {
    menuItems: string;
    menuSection: string;
    menuItem: string;
  }
> = {
  [DropdownVariants.DEFAULT]: {
    menuItems: "border border-[#E9EAEC]",
    menuSection: "",
    menuItem: "[&_svg]:mr-3 [&_svg]:mt-0 p-1.5 px-3 text-[#464A53] font-medium leading-tight items-center",
  },
  [DropdownVariants.SECONDARY]: {
    menuItems: "shadow-[0px_3px_6px_0px_#00000026)] p-2",
    menuSection: "bg-white p-2 rounded-lg shadow-[0px_4px_29.6px_0px_#0000001F]",
    menuItem:
      "[&>div>svg]:mr-2 [&>svg]:hover:scale-150 [&>svg]:hover:text-[#766CE4] p-2 rounded-lg text-sm font-medium text-[#1F2228] hover:bg-[#F1F0FC] hover:text-[#766CE4] hover:font-semibold",
  },
};
