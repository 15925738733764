import api from "..";

import { IAuthResponse, ILoginData, IUserRegister, IVerificationCode } from "./types";

const BASE_API = process.env.REACT_APP_API_URL;

export const signUp = async (registrationData: IUserRegister): Promise<IAuthResponse> => {
  return api(`${BASE_API}/auth/register`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(registrationData),
  });
};

export const login = async (loginData: ILoginData): Promise<IAuthResponse> => {
  return api(`${BASE_API}/auth/login`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(loginData),
  });
};

export const logOut = async () => {
  return api(`${BASE_API}/auth/logout`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });
};

export const emailConfirmation = async (code: IVerificationCode) => {
  return api(`${BASE_API}/auth/email-confirmation`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(code),
  });
};

export const refresh = async () => {
  return api(`${BASE_API}/auth/refresh`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};

export const sendCode = async (data: { email: string }) => {
  return api(`${BASE_API}/auth/reminder/send-code`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
};

export const verifyCode = async (data: { email: string; code: string }) => {
  return api(`${BASE_API}/auth/reminder/validate-code`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
};

export const sendEmailVerificationLink = async (data: { email: string }) => {
  return api(`${BASE_API}/auth/resend-email-confirmation`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
};

export const setNewPassword = async (data: { email: string; code: string; password: string }) => {
  return api(`${BASE_API}/auth/reminder/set-password`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
};

export const googleAuthRedirect = async (data: { token: string }): Promise<IAuthResponse> => {
  return api(`${BASE_API}/auth/google?token=${data.token}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};
