import ContentIcon from "assets/icons/react-components-icons/ContentIcon";
import EmailIcon from "assets/icons/react-components-icons/EmailIcon";
import InfluencerIcon from "assets/icons/react-components-icons/InfluencerIcon";
import PaidIcon from "assets/icons/react-components-icons/PaidIcon";
import SEMIcon from "assets/icons/react-components-icons/SEMIcon";
import SMSIcon from "assets/icons/react-components-icons/SMSIcon";
import SocialIcon from "assets/icons/react-components-icons/SocialIcon";

export const CHANNEL_FLOW_ICONS: any = {
  icon1: SocialIcon,
  icon2: PaidIcon,
  icon3: InfluencerIcon,
  icon4: ContentIcon,
  icon5: EmailIcon,
  icon6: SEMIcon,
  icon7: SMSIcon,
};
