import React from "react";

import { ButtonVariants } from "../../../constants/shared/button.constants";
import { Button } from "../buttons/Button";

interface Props {
  handleCancel?: VoidFunction;
  handleSubmit?: VoidFunction;
  submitButtonText?: string;
  cancelButtonText?: string;
  buttonClass?: string;
  primaryButtonDisabled?: boolean;
}

export const ModalControlButtons = ({
  handleSubmit,
  handleCancel,
  submitButtonText = "Confirm",
  buttonClass,
  primaryButtonDisabled,
}: Props) => {
  return (
    <div className='mt-10 px-6 gap-x-4 flex justify-center items-center'>
      {handleCancel && (
        <button
          className={
            "w-full max-w-[200px] py-[7px] px-8 border border-[#D5D1F7] rounded-xl text-sm text-[#1F2228] font-semibold cursor-pointer" +
            " " +
            buttonClass
          }
          onClick={handleCancel}
        >
          Cancel
        </button>
      )}
      {handleSubmit && (
        <Button
          className={buttonClass || "max-w-[200px]"}
          variant={ButtonVariants.MEDIUM}
          onClick={handleSubmit}
          disabled={primaryButtonDisabled}
        >
          {submitButtonText}
        </Button>
      )}
    </div>
  );
};
