import cn from "classnames";
import React, { ButtonHTMLAttributes } from "react";
import { Link, LinkProps } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { ButtonStyles, ButtonVariants, SHARED_BUTTON_CLASSNAME } from "constants/shared/button.constants";

import { ButtonSharedProps } from "interfaces/shared/button.interfaces";

import { getDisabledButtonStylesByVariant } from "../../../utils/shared/button.interfaces";

export interface Props extends LinkProps, ButtonSharedProps {
  disabled?: boolean;
}

export const LinkButton = ({
  className,
  children,
  isReversedIcon,
  iconClassName,
  disabled,
  variant = ButtonVariants.LARGE,
  icon: Icon,
  ...props
}: Props) => {
  return (
    <Link
      className={twMerge(
        cn(
          SHARED_BUTTON_CLASSNAME,
          ButtonStyles[variant],
          {
            "flex-row-reverse [&_svg]:mr-0 [&_svg]:ml-2.5": isReversedIcon,
            [ButtonStyles[getDisabledButtonStylesByVariant(variant)]]: disabled,
          },
          className,
        ),
      )}
      {...props}
    >
      {Icon && <Icon className={iconClassName} />}
      {children}
    </Link>
  );
};
