import { useEffect } from "react";
import ReactGA from "react-ga4";
import { Bounce, ToastContainer } from "react-toastify";

import { PagesWrapper } from "./page-components/PagesWrapper";

import "react-toastify/dist/ReactToastify.css";

const googleAnalyticsMeasurementId = process.env.REACT_APP_GA4_MEASUREMENT_ID;

function App() {
  useEffect(() => {
    if (!localStorage.getItem("watchedInfo")) {
      localStorage.setItem("watchedInfo", "false");
    }
    if (googleAnalyticsMeasurementId) {
      ReactGA.initialize(googleAnalyticsMeasurementId);
      ReactGA.send("pageview");
    }
  }, []);

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
        theme='light'
        transition={Bounce}
      />
      <PagesWrapper />
    </>
  );
}

export default App;
