// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import { ROUTES } from "constants/routes.constants";
import { ButtonVariants } from "constants/shared/button.constants";
import { TypographyVariants } from "constants/shared/typography.constants";

import { useAppDispatch } from "hooks/appHooks";

import { sendConfirmationCode, validateCode } from "storage/actions/auth";

import { Button } from "shared/components/buttons/Button";
import { Typography } from "shared/components/Typography";
import { AuthLayout } from "shared/layouts/AuthLayout";

import { ReactComponent as BackButton } from "assets/icons/BackButton.svg";
import { ReactComponent as BigEmailIcon } from "assets/icons/BigEmail.svg";

import { formatEmail } from "utils";

export default function VerifyCode() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const email = localStorage.getItem("email");

  const onSubmit = async (data: { code: string }) => {
    localStorage.setItem("code", data.code);
    dispatch(validateCode({ ...data, email })).then((res: any) => {
      if (res?.success) navigate(ROUTES.setupPassword);
    });
  };

  const resendCode = async () => {
    const email = localStorage.getItem("email");
    dispatch(sendConfirmationCode({ email }));
  };

  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

  const resetCode = () => {
    inputRefs.forEach(ref => {
      ref.current.value = "";
    });
    inputRefs[0].current.focus();
    setCode("");
  };

  useEffect(() => {
    if (code.length === 7) {
      resetCode();
    }
  }, [code]);

  useEffect(() => {
    resetCode();
  }, []);

  function handleInput(e, index) {
    const input = e.target;
    const previousInput = inputRefs[index - 1];
    const nextInput = inputRefs[index + 1];

    const newCode = [...code];
    if (/^[a-z]+$/.test(input.value)) {
      const uc = input.value.toUpperCase();
      newCode[index] = uc;
      inputRefs[index].current.value = uc;
    } else {
      newCode[index] = input.value;
    }
    setCode(newCode.join(""));

    input.select();

    if (input.value === "") {
      if (previousInput) {
        previousInput.current.focus();
      }
    } else if (nextInput) {
      nextInput.current.select();
    }
  }

  function handleFocus(e) {
    e.target.select();
  }

  function handleKeyDown(e, index) {
    const input = e.target;
    const previousInput = inputRefs[index - 1];

    if ((e.keyCode === 8 || e.keyCode === 46) && input.value === "") {
      e.preventDefault();
      setCode(prevCode => prevCode.slice(0, index) + prevCode.slice(index + 1));
      if (previousInput) {
        previousInput.current.focus();
      }
    }
  }

  const handlePaste = e => {
    const pastedCode = e.clipboardData.getData("text");
    if (pastedCode.length === 6) {
      setCode(pastedCode);
      inputRefs.forEach((inputRef, index) => {
        inputRef.current.value = pastedCode.charAt(index);
      });
    }
  };
  return (
    <AuthLayout>
      <div className='grow px-5 min-h-screen  flex flex-col justify-center py-6'>
        <div className='h-fit sm:w-[464px] flex flex-col  mx-auto gap-y-8'>
          <div className='pb-4'>
            <div className='flex justify-center'>
              <BigEmailIcon className='w-16 h-16' />
            </div>
            <Typography className='my-6 text-center' variant={TypographyVariants.H1}>
              Verify Email
            </Typography>
            <div className='flex flex-col justify-center mt-1 text-center'>
              <Typography variant={TypographyVariants.LG_MEDIUM}>Please type the verification code sent to</Typography>
              <Typography variant={TypographyVariants.LG_SEMIBOLD} className='font-bold'>
                {email && formatEmail(email)}
              </Typography>
            </div>
          </div>

          <div className='flex gap-x-4 justify-center' data-hs-pin-input>
            {[0, 1, 2, 3, 4, 5].map(index => (
              <input
                name='code'
                className={`outline-none py-2 px-3 block w-16 h-16 border-2 text-center rounded-2xl text-[32px] font-bold placeholder:text-gray-400 focus:border-[#766CE4] focus:ring-[#766CE4] ${
                  code[index] ? "border-[#766CE4]" : "border-gray-200"
                }`}
                key={index}
                type='tel'
                maxLength={1}
                onChange={e => handleInput(e, index)}
                ref={inputRefs[index]}
                autoFocus={index === 0}
                onFocus={handleFocus}
                onKeyDown={e => handleKeyDown(e, index)}
                onPaste={handlePaste}
                data-hs-pin-input-item
              />
            ))}
          </div>

          <div className='space-y-6'>
            <Button onClick={() => onSubmit({ code })} type='button'>
              Confirm
            </Button>

            <Typography variant={TypographyVariants.MD_MEDIUM} className='w-full inline-flex justify-center items-center'>
              Didn’t receive the code?
              <Button onClick={resendCode} variant={ButtonVariants.LINK_TEXT_MEDIUM} className='ml-2'>
                Resend
              </Button>
            </Typography>

            <div className='flex items-center justify-center text-sm text-center text-black hover:cursor-pointer'>
              <Link
                className='flex items-center justify-center underline-offset-1 gap-x-1 text-sm font-medium text-[#1F2228] hover:underline'
                to={ROUTES.signIn}
              >
                <BackButton />
                Back to Log In
              </Link>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}
