import React from "react";

import {ReactComponent as UserAvatarIcon} from "assets/icons/user-avatar.svg"

interface Props {
  imageUrl?: string;
}

export const Avatar = ({ imageUrl }: Props) => {
  if (!imageUrl) return <div className='relative flex justify-center items-center w-8 h-8 overflow-hidden rounded-full cursor-pointer bg-[#F7F9FB]'><UserAvatarIcon /></div>;

  // TODO - add avatar logic
  return <div>Avatar</div>;
};
