import cn from "classnames";
import React, { useState } from "react";
// eslint-disable-next-line import/no-unresolved
import { EffectCoverflow } from "swiper/modules";
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from "swiper/react";
// eslint-disable-next-line import/no-unresolved
import { Swiper as SwiperClass } from "swiper/types";

import { TypographyVariants } from "constants/shared/typography.constants";

import { Typography } from "shared/components/Typography";

import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-down.svg";
interface Data {
  competitive_outlook: string[];
  budget_recommendation: string[];
  competitor_insights: string[];
}
type Suggestions = Data;
interface Props {
  suggestions: Suggestions;
}

const getSuggestionCardName = (key: string) => {
  const titleArray = key.split("_");

  return titleArray[0][0].toUpperCase() + titleArray[0].slice(1) + " " + titleArray[1][0].toUpperCase() + titleArray[1].slice(1);
};

export const BudgetSuggestionsSlider = ({ suggestions }: Props) => {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const handleRightClick = React.useCallback(() => {
    if (swiper === null) return;
    if (swiper.activeIndex === 2) swiper.slideTo(0)
      else swiper.slideTo(swiper.activeIndex + 1)
  }, [swiper]);

  return (
    <div className='relative'>
      <Swiper
        allowTouchMove={false}
        autoHeight
        grabCursor
        effect='coverflow'
        slidesPerView="auto"
        centeredSlides
        loop
        initialSlide={2}
        noSwiping={false}
        coverflowEffect={{
          rotate: 0,
          stretch: "80%",
          depth: 400,
          modifier: 1,
          slideShadows: false,
        }}
        pagination={true}
        modules={[EffectCoverflow]}
        className='mySwiper'
        onSwiper={setSwiper} 
      >
        {Object.keys(suggestions).map((suggestionKey, index) => {
          const suggestion = suggestions[suggestionKey as keyof Data];

          return (
            <SwiperSlide key={index}>
              <div className={cn("coverflow-slide-content w-full h-[213px] break-all overflow-y-auto rounded-[22px] p-0.5")}>
                <div className='bg-[#F1F0FC] py-5 px-6 h-full rounded-[20px]'>
                  <Typography className='text-[#413B7D]' tag='h4' variant={TypographyVariants.H7}>
                    {getSuggestionCardName(suggestionKey)}:
                  </Typography>
                  <ul className='mt-4 space-y-[10px] text-[#413B7D] text-sm font-medium'>
                    {suggestion.map((item: string) => (
                      <li className='list-disc list-inside leading-default pl-2' key={item}>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
        <button
          onClick={handleRightClick}
          className='absolute top-[calc(50%-20px)] right-[5%] z-20 flex justify-center items-center rounded-full border w-10 h-10 bg-white border-[#E9EAEC]'
        >
          <ArrowDownIcon className='-rotate-90' />
        </button>
      </Swiper>
    </div>
  );
};
