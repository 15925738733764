import { twMerge } from "tailwind-merge";

interface Props {
  progress: number;
  className?: string;
}

export const ProgressLine = ({ progress, className }: Props) => (
  <div className={twMerge("w-full bg-[#E9EAEC] rounded-full h-1.5 overflow-hidden", className)}>
    <div className='bg-light-purple-to-purple h-1.5 rounded-full' style={{ width: `${progress > 100 ? 100 : progress}%` }} />
  </div>
);
