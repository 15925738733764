import { FC } from "react";

type Props = { className?: string; width?: string; height?: string; color?: string };

const PaidIcon: FC<Props> = ({ className, width = "22", height = "22", color = "#D17102" }) => {
  return (
    <svg
      width={width}
      height={height}
      color={color}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M7.94922 13.1357C7.94922 14.3182 8.85672 15.2715 9.98422 15.2715H12.2851C13.2659 15.2715 14.0634 14.4374 14.0634 13.4107C14.0634 12.2924 13.5776 11.8982 12.8534 11.6415L9.15922 10.3582C8.43505 10.1015 7.94922 9.70738 7.94922 8.58904C7.94922 7.56238 8.74672 6.72821 9.72755 6.72821H12.0284C13.1559 6.72821 14.0634 7.68154 14.0634 8.86404'
        stroke='currentColor'
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M11 5.5V16.5' stroke='currentColor' strokeWidth='1.8' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M13.7507 20.1666H8.25065C3.66732 20.1666 1.83398 18.3333 1.83398 13.75V8.24998C1.83398 3.66665 3.66732 1.83331 8.25065 1.83331H13.7507C18.334 1.83331 20.1673 3.66665 20.1673 8.24998V13.75C20.1673 18.3333 18.334 20.1666 13.7507 20.1666Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default PaidIcon;
