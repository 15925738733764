import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UploadBudgetState {
  dataType: string;
  socialSignup: {
    [key: string]: string;
  }
}

interface SocialSignupPayload {
  social: string
  token: string;
}

const initialState: UploadBudgetState = {
  dataType: "Google Sheets",
  socialSignup: {
    googleAccessToken: "",
  },
};

export const uploadBudgetSlice = createSlice({
  name: "uploadedBudget",
  initialState,
  reducers: {
    setDataType: (state, action: PayloadAction<string>) => {
      state.dataType = action.payload;
    },
    setSocialSignup: (state: UploadBudgetState , action: PayloadAction<SocialSignupPayload>) => {
      state.socialSignup[action?.payload?.social] = action.payload?.token;
    },
  },
});

export const { setDataType, setSocialSignup } = uploadBudgetSlice.actions;
export const uploadBudgetReducer = uploadBudgetSlice.reducer;
