import React from "react";

import { ReactComponent as GoogleIcon } from "assets/icons/googleIcon.svg";

import { ButtonVariants } from "../../../constants/shared/button.constants";
import { Button } from "../buttons/Button";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
}
export default function GoogleButton({ children, ...props }: ButtonProps) {
  return (
    <Button variant={ButtonVariants.BORDERED} {...props} type='button' className='w-full bg-white !font-semibold'>
      <GoogleIcon className='w-5 h-5' />
      {children}
    </Button>
  );
}
