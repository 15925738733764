import React, { useContext, useEffect, useRef, useState } from "react";
import DataGrid, { textEditor, Column, renderHeaderCell } from "react-data-grid";

import { AOAColumn, ColumnData, Row } from "utils/sheets.utils";

import { ConnectSourceContext } from "pages/budget/ConnectDataSource";




// Data Sheet table Locate Data
const DataSheetTableLD = () => {
  const { row, column, locateData, setDataCoordinates, setCategories,coordinates } = useContext(ConnectSourceContext);
  const [yAxis, setYAxis] = useState<any>("");
  const [xAxis, setXAxis] = useState<any>("");
  const [dataStarts, setDataStarts] = useState<any>("");

  const gridcontainerRef = useRef(null);
  function rowHeight() {
    return 37;
  }
  const getRowClassName = (row: any) => {
    const firstClass = row.index - 1 === xAxis ? "selected-row" : "";
    const secondClass = dataStarts && row.index - 1 >= dataStarts ? "selected-row-data" : "";
    return firstClass + " " + secondClass;
  };

  const getClassName = (column: any) => {
    return column.key == yAxis ? "selected-column" : "";
  };
  const columnsWithSelect = [{ key: "index", name: "", width: 120, resizable: true },...column]

  useEffect(()=>{
    if(locateData){
      if(!yAxis) setYAxis(locateData?.channel_label_range?.start?.column+"")
      if(dataStarts || xAxis) return
      if(!xAxis) setXAxis(locateData?.calendar_range?.start?.row ?? "")
      if(!dataStarts) setDataStarts(locateData?.data_range?.start?.row ?? "")
    }
    
  },[locateData])
  useEffect(()=>{
    if((xAxis || xAxis ===0) && (yAxis || yAxis ===0) && (dataStarts || dataStarts ===0)){
      setDataCoordinates({xAxis, yAxis:parseInt(yAxis),dataStarts})
    }
  },[xAxis,yAxis,dataStarts])
  // useEffect(()=>{
  //   if (gridcontainerRef?.current && dataStarts) {
  //     // Select the first div inside the .main container
  //     const selectedRowData = gridcontainerRef?.current?.querySelector('.selected-row-data');
  //     const childDivs = selectedRowData?.children
  //     if(!childDivs) return
  //     Array.from(childDivs).forEach((child:any) => {
  //       child.classList.add('data-border-top-grn');  // Replace 'new-class' with the desired class name
  //     });
  //     console.log(selectedRowData?.children); // Manipulate or log the first div
  //   }
  // },[dataStarts])
  const resetNextPagesData = ()=>{
    setCategories(null)
  }
  return (
    <ol className='text-base font-bold text-[#2E323A] w-full min-h-96'>
      
      <div className='pt-4 min-h-96' ref={gridcontainerRef}>
        <DataGrid
          style={{ flex: 1 }}
          onCellClick={(params,event)=>{
            event.preventGridDefault()
          }}
          className='min-h-600 '
          columns={[
            
            ...columnsWithSelect.map((col:any) => ({
            ...col,
            headerCellClass: getClassName(col),
            cellClass: getClassName(col),
            
            renderHeaderCell: (props: any) => {
              if (props.column.key === "index") {
                return <div></div>;
              }
              return (
                <span onClick={(e) => e.stopPropagation()}>
                <select
                  name='cars'
                  id='cars'
                  value={yAxis === col.key ? yAxis : ""}
                  onChange={val => {
                    setYAxis(col.key);
                    resetNextPagesData()
                  }}
                  style={{ backgroundColor: "hsl(0deg 0% 97.5%)", flex: 1, width: "100%" }}
                >
                  <option value='' disabled>
                    Select
                  </option>
                  <option value={col.key}>Y-Axis</option>
                </select>
                </span>
              );
            },
            renderCell: (props:any) => {

              if (props.column.key === "index") {
                return (
                  <span onClick={(e) => e.stopPropagation()}>
                  <select
                    name='mars'
                    id='mars'
                    value={xAxis === props.rowIdx ? "X-Axis" : dataStarts === props.rowIdx ? "Data-Starts" : ""}
                    onChange={val => {
                      if (val.target.value === "X-Axis") {
                        if (dataStarts && dataStarts <= props.rowIdx) {
                          alert("X-Axis cannot be below Data Starts range");
                          return;
                        }
                        setXAxis(props.rowIdx);
                        resetNextPagesData()
                      } else {
                        if (xAxis && xAxis >= props.rowIdx) {
                          alert("Data Starts range cannot be above X-Axis row");
                          return;
                        }
                        setDataStarts(props.rowIdx);
                        resetNextPagesData()
                      }
                    }}
                    style={{ backgroundColor: "transparent", flex: 1, width: "100%" }}
                  >
                    <option value='' disabled>
                      Select
                    </option>
                    <option value='X-Axis'>X-Axis</option>
                    <option value='Data-Starts'>Data Starts</option>
                  </select>
                  </span>
                );
              }
              return <div>{props.row[props.column.key]}</div>;
            },
            resizable: true, minWidth: 100,
          }))]}
          rows={row.map((row: Row, index: number) => ({
            ...row,
            index: index + 1,
            // cellClass: getRowClassName(row),
          }))}
          rowHeight={rowHeight}
          renderers={{}}
          //   onRowClick={onRowClick}
          rowClass={row => getRowClassName(row)}
          onSelectedCellChange={re => {
            // setSelectedRowId(re.rowIdx);
          }}
        />
      </div>
    </ol>
  );
};

export default DataSheetTableLD;
