import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { ROUTES } from "constants/routes.constants";

import { ReactComponent as ArrowLeft } from "assets/icons/arrowLeft.svg";

import { Logo } from "../components/Logo";

interface Props {
  children: ReactNode;
  childrenClassName?: string;
  goBackPath?: string;
  hideBackButton?: boolean;
}

export const WithLogoLayout = ({ children, childrenClassName, goBackPath = ROUTES.dashboard, hideBackButton }: Props) => (
  <div className='p-4 px-7 w-full min-h-screen bg-[#F7F9FB]'>
    <Logo />
    <div className={twMerge("mt-6 relative", childrenClassName)}>
      {!hideBackButton && (
        <Link to={goBackPath} className='flex items-center text-[#766CE4] font-bold mt-2 absolute top-0 left-0'>
          <ArrowLeft className='mr-2.5 text-[#766CE4]' /> Back
        </Link>
      )}
      {children}
    </div>
  </div>
);
