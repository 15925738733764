import cn from "classnames";
import React from "react";
import { NavLink } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { twMerge } from "tailwind-merge";

import { TypographyVariants } from "constants/shared/typography.constants";

import BetaLabel from "../beta-label/BetaLabel";
import { Typography } from "../Typography";

interface NavButtonProps {
  to: string;
  title: string;
  classes?: string;
  isCollapsed: boolean;
  beta: boolean;
  Icon: React.ComponentType;
  ActiveIcon: React.ComponentType;
}

export default function NavButton({ to, title, Icon, beta, ActiveIcon, classes, isCollapsed }: NavButtonProps) {
  return (
    <>
      <Tooltip
        id={isCollapsed ? `${title}-tooltip` : ""}
        content={title}
        place='right'
        positionStrategy='fixed'
        opacity={1}
        style={{
          borderRadius: "13px",
          background: "#2E323A",
          fontSize: "12px",
          boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.20)",
          zIndex: 1000,
        }}
      />
      <NavLink
        data-tooltip-id={`${title}-tooltip`}
        to={to}
        className={({ isActive }) =>
          twMerge(
            cn(
              "flex items-center w-full text-[#1F2228] py-1.5 px-3 gap-x-4 outline outline-1 outline-transparent hover:bg-[#F7F7F8] rounded-lg focus:bg-[#E9EAEC] focus:text-[#1F2228] focus:outline-[#E9EAEC]",
              {
                "justify-center rounded-full w-fit p-[7px] mx-auto": isCollapsed,
                "bg-[#F9F9FF] outline-[#F1F0FC]": isActive,
              },
            ),
            classes,
          )
        }
        children={({ isActive }) => (
          <div className="flex items-center w-full justify-between">
            <div className="flex items-center gap-4">
              <div className='flex items-center justify-center cursor-pointer h-4 w-4 max-w-4 flex-shrink-0'>
                {isActive ? <ActiveIcon /> : <Icon />}
              </div>{" "}
              {!isCollapsed && (
                <Typography variant={TypographyVariants.MD_MEDIUM} className={cn({ "text-[#766CE4]": isActive })}>
                  {title}
                </Typography>
              )}
            </div>
            {beta && !isCollapsed && <BetaLabel />}
          </div>
        )}
      ></NavLink>
    </>
  );
}
