import type { FC, FormEvent } from "react";

import cn from "classnames";
import { useReactFlow } from "reactflow";
import { twMerge } from "tailwind-merge";

import { ReactComponent as CursorIcon } from "assets/icons/cursor.svg";
import { ReactComponent as DashedIcon } from "assets/icons/dashed.svg";
import { ReactComponent as HandIcon } from "assets/icons/hand.svg";
import { ReactComponent as RedoIcon } from "assets/icons/redo.svg";
import { ReactComponent as ResizeIcon } from "assets/icons/resize.svg";

import classes from "./CustomControls.module.scss";

// TODO - move to separate constants file
const ZOOM_OPTIONS = [100, 75, 50, 25, 15];

interface Props {
  isDraggable: boolean;
  handleSetIsDraggable: (value: boolean) => void;
}

const CustomControls: FC<Props> = ({ isDraggable, handleSetIsDraggable }) => {
  const { setViewport } = useReactFlow();

  const handleSetViewport = (event: FormEvent<HTMLSelectElement>) => {
    setViewport({ x: 200, y: 200, zoom: Number(event.currentTarget.value) / 100 });
  };

  return (
    <div className={twMerge(classes.controllers, "[&_svg]:text-[#666F8D] !mb-12")}>
      <button className={classes.controllers__btn} onClick={() => handleSetIsDraggable(true)}>
        <CursorIcon className={cn({ "!text-black": isDraggable })} />
      </button>
      <button className={classes.controllers__btn} onClick={() => handleSetIsDraggable(false)}>
        <HandIcon className={cn({ "!text-black": !isDraggable })} />
      </button>
      <button className={classes.controllers__btn}>
        <DashedIcon />
      </button>
      <div className={classes.controllers__line}></div>
      <button className={classes.controllers__btn}>
        <RedoIcon />
      </button>
      <button className={classes.controllers__btn}>
        <RedoIcon className=' scale-x-[-1] ' />
      </button>
      <div className={classes.controllers__line}></div>
      <select onChange={handleSetViewport}>
        {ZOOM_OPTIONS.map(value => (
          <option key={value} value={value}>
            {value}%
          </option>
        ))}
      </select>
      <div className={classes.controllers__line}></div>
      <button className={classes.controllers__btn}>
        <ResizeIcon />
      </button>
    </div>
  );
};

export default CustomControls;
