import cn from "classnames";
import { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

import { Tab } from "interfaces/shared/tabs.interfaces";

import { TypographyVariants } from "../../../constants/shared/typography.constants";
import { Typography } from "../Typography";

interface Props<T = void> {
  tabs: Tab[];
  componentProps?: T;
  className?: string;
  contentClassName?: string;
}

export const Tabs = <T,>({ tabs, componentProps, className, contentClassName }: Props<T>) => {
  const tabsRef = useRef<(HTMLElement | null)[]>([]);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);

  const TabContentComponent = tabs[activeTabIndex]?.component || null;

  useEffect(() => {
    if (activeTabIndex === null) {
      return;
    }

    const setTabPosition = () => {
      const currentTab = tabsRef.current[activeTabIndex] as HTMLElement;
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    };

    setTabPosition();
  }, [activeTabIndex]);

  if (!tabs?.length) return null;

  return (
    <div>
      <div
        className={twMerge(
          "w-fit flex flew-row relative mx-auto mb-8 px-1 h-10 rounded-[20px] border border-[#D5D1F7] bg-[#F3F2FD] backdrop-blur-sm font-interTight",
          className,
        )}
      >
        <span
          className='flex absolute bottom-0 top-0 -z-10 py-1 transition-all duration-300'
          style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
        >
          <span className='h-full w-full rounded-2xl bg-white shadow-tab' />
        </span>

        {tabs?.map(({ title, description }, index) => {
          const isActive = activeTabIndex === index;

          return (
            <button
              key={index}
              ref={el => (tabsRef.current[index] = el)}
              className='my-[3.5px] mr-4 last:mr-0 py-1.5 px-6 cursor-pointer select-none rounded-2xl text-center'
              onClick={() => setActiveTabIndex(index)}
            >
              <Typography
                className={cn("text-[#717684] font-medium", { "text-[#766CE4] font-bold": isActive })}
                variant={TypographyVariants.H7}
                tag='p'
              >
                {title} {description && <span className='text-[#544DA2] font-normal'>{description}</span>}
              </Typography>
            </button>
          );
        })}
      </div>
      <div className={twMerge("px-4", contentClassName)}>{TabContentComponent && <TabContentComponent {...componentProps} />}</div>
    </div>
  );
};
