import React from "react";
import { Link } from "react-router-dom";

import { ROUTES } from "constants/routes.constants";
import { TypographyVariants } from "constants/shared/typography.constants";

import { Button } from "shared/components/buttons/Button";
import { Typography } from "shared/components/Typography";
import { AuthLayout } from "shared/layouts/AuthLayout";

import { ReactComponent as BackButton } from "assets/icons/BackButton.svg";
import { ReactComponent as BigLockIcon } from "assets/icons/BigLock.svg";

export default function ConfirmPassword() {
  return (
    <AuthLayout>
      <div className='grow px-5 min-h-screen  flex flex-col justify-center py-6'>
        <div className='h-fit sm:w-[464px] flex flex-col  mx-auto gap-y-6'>
          <div className='mb-4'>
            <div className='flex justify-center'>
              <BigLockIcon className='w-16 h-16' />
            </div>
            <Typography className='my-6 text-center text-[#2C1A49]' variant={TypographyVariants.H1}>
              Password reset <br />
              <span className='text-[#2c1a497a]'>successful</span>
            </Typography>

            <Typography className='text-center text-[#2C1A49]'>
              Your password has been successfully rest. <br /> Click below to continue.
            </Typography>
          </div>

          <div className='space-y-6'>
            <Button>Continue</Button>
          </div>

          <div className='flex items-center justify-center text-sm text-center text-black hover:cursor-pointer'>
            <Link
              className='flex items-center justify-center underline-offset-1 gap-x-1 text-sm font-medium text-[#1F2228] hover:underline'
              to={ROUTES.signIn}
            >
              <BackButton />
              Back to Log In
            </Link>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}
