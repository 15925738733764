import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apiAddUserPreferences, apiUserPreferences } from "api/preferences/index";

import { store } from "storage/store";

interface PreferencesState {
  operationalViewAccessRequested: boolean;
  loading: boolean;
  error: string | null;
}

// Define initial state for the slice
const initialState: PreferencesState = {
  operationalViewAccessRequested: false,
  loading: false,
  error: null,
};

export const getUserPreferences = createAsyncThunk<any>("preferences/getUserPreferences", async () => {
  try {
    const response = await apiUserPreferences();
    return response.preferences?.operationalViewAccessRequested as boolean; // Type assertion for the response data
  } catch (error: any) {
    console.error(error);
    return;
  }
});

export const updateUserPreferences = createAsyncThunk<any, any>("preferences/updateUserPreferences", async preferenceData => {
  try {
    const response = await apiAddUserPreferences(preferenceData);
    return response.preferences?.operationalViewAccessRequested as boolean; // Type assertion for the response data
  } catch (error: any) {
    console.error(error);
    return;
  }
});

export const preferencesSlice = createSlice({
  name: "preferences",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getUserPreferences.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserPreferences.fulfilled, (state, action: any) => {
        state.loading = false;
        state.operationalViewAccessRequested = action.payload;
      })
      .addCase(getUserPreferences.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.payload || "Unknown error";
      })

      .addCase(updateUserPreferences.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUserPreferences.fulfilled, (state, action: any) => {
        state.loading = false;
        state.operationalViewAccessRequested = action.payload;
      })
      .addCase(updateUserPreferences.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.payload || "Unknown error";
      });
  },
});

export const preferencesReducer = preferencesSlice.reducer;
