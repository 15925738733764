import cn from "classnames";
import { useEffect, useState, type FC } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { ROUTES } from "constants/routes.constants";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";

import { getWorkflowsStrategiesList } from "storage/actions/workflows";
import { getUserPreferences } from "storage/slices/preferences-slice";
import { setIsCollapsed } from "storage/slices/sidebar-slice";

import { months } from "shared/components/budget/datePicker/DatePicker";
import OperationalFakeDoor from "shared/components/popups/operationalFakeDoor/OperationalFakeDoor";
import { DashboardLayout } from "shared/layouts/DashboardLayout";

import { ReactComponent as ArrowRight } from "assets/icons/arrow-right-gray.svg";
import { ReactComponent as BriefcaseIcon } from "assets/icons/briefcase.svg";
import { ReactComponent as ChartIcon } from "assets/icons/chart.svg";
import { ReactComponent as OperationalIcon } from "assets/icons/operational-view.svg";
import { ReactComponent as PencilIcon } from "assets/icons/pencil.svg";

import { SidebarsWidth } from "types";

import classes from "./BudgetIntelligence.module.scss";
import ExecutiveView from "./ExecutiveView/ExecutiveView";
import MarketingView from "./MarketingpView/MarketingView";
import OperationalView from "./OperationalView/OperationalView";

const BudgetIntelligence: FC<any> = () => {
  const { CHAT_OPENED_WIDTH, CHAT_CLOSED_WIDTH } = SidebarsWidth;
  const { budget_date_from, budget_date_to } = useAppSelector((state: any) => state.project.project || {});

  const { state } = useLocation();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(setIsCollapsed(true));
    dispatch(getUserPreferences());
  }, []);

  const [isMarketingView, setIsMarketingView] = useState<boolean>(false);
  const [view, setView] = useState<string>(state?.view || "executive");
  const [openChat] = useState(false);

  const handleSwitcher = (isMarketing = true) => {
    setIsMarketingView(isMarketing);
  };
  const onEditBtnClick = () => {
    navigate(ROUTES.editBudgetForm + "/" + id);
  };
  return (
    <DashboardLayout title='Budget Intelligence'>
      <div className='flex min-h-full'>
        <div className={`flex min-h-full overflow-auto w-full justify-between bg-[#F7F9FB] ${classes.budgetIntelligence}`}>
          <div
            className={`relative max-h-full py-4 px-6 flex-grow flex flex-col gap-y-4 overflow-y-auto custom-scrollbar ${
              openChat ? "chartContainerOpenChat" : "chartContainer"
            } `}
            style={{ width: `calc(100% -${openChat ? CHAT_OPENED_WIDTH : CHAT_CLOSED_WIDTH} )` }}
          >
            <div className='flex justify-between items-center static z-[20]'>
              <div className={`${classes.budgetIntelligence__switcher}`}>
                {/* <button className={`${isMarketingView ? classes.passive : undefined}`} onClick={() => handleSwitcher(false)}>
                <BriefcaseIcon /> Executive View
              </button>

              <button className={`${isMarketingView ? undefined : classes.passive}`} onClick={() => handleSwitcher(true)}>
                <ChartIcon /> Marketing View
              </button> */}
                <button className={`${view === "executive" ? undefined : classes.passive}`} onClick={() => setView("executive")}>
                  <BriefcaseIcon /> Executive View
                </button>
                <button className={`${view === "operational" ? undefined : classes.passive}`} onClick={() => setView("operational")}>
                  <OperationalIcon /> Operational View
                </button>
                {/* <button className={`${view === "marketing" ? undefined : classes.passive}`} onClick={() => setView("marketing")}>
                  <ChartIcon /> Marketing View
                </button> */}
              </div>
              <div className='flex gap-2 items-center text-sm leading-default font-medium text-[#464A53]'>
                Budget period:{" "}
                <div className='flex items-center gap-1'>
                  <div className='py-2 px-[11px] bg-white rounded-lg border-[1px] h-[34px] border-[#E9EAEC] text-[#1F2228] text-sm leading-default font-medium'>
                    {months[Number(budget_date_from?.split("-")[1]) - 1]} {budget_date_from?.split("-")[0]}
                  </div>
                  <ArrowRight />
                  <div className='py-2 px-[11px] bg-white rounded-lg border-[1px] h-[34px] border-[#E9EAEC] text-[#1F2228] text-sm leading-default font-medium'>
                    {months[Number(budget_date_to?.split("-")[1]) - 1]} {budget_date_to?.split("-")[0]}
                  </div>
                  <button
                    className='py-2 px-[11px] h-[34px] text-[#766CE4] underline underline-offset-3 text-sm leading-default font-semibold hover:font-bold'
                    onClick={onEditBtnClick}
                  >
                    Edit budget
                  </button>
                </div>
              </div>
              {/*<button className='py-[10px] flex items-center gap-[10px] px-6 rounded-xl border-[1px] border-[#D5D1F7] bg-[#FCFCFC] text-[#1F2228] leading-default font-semibold text-sm h-9'>
                <PencilIcon />
                Edit budget
              </button>*/}
            </div>
            {/* {isMarketingView ? <MarketingView openChat={openChat} /> : <ExecutiveView openChat={openChat} />} */}
            {view === "executive" ? (
              <ExecutiveView openChat={openChat} />
            ) : (
              <OperationalView />
            )}
          </div>
          {/* <AiChatAssistant openChat={openChat} setOpenChat={setOpenChat} /> */}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default BudgetIntelligence;
