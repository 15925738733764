import { useFormContext } from "react-hook-form";

import { PERFORMANCE_PLAN_SELECT_DATA } from "constants/performance-plan.constants";
import { REQUIRED_VALIDATION_RULE } from "constants/validation.constants";

import { WorkflowStrategyAdditionalFields } from "interfaces/workflows.interfaces";

import { getPerformanceTextAreaData } from "utils/performance-plan.utils";

import { NumberInput } from "shared/components/form-elements/NumberInput";
import { Select } from "shared/components/form-elements/select/Select";
import { TextArea } from "shared/components/form-elements/TextArea";

import { MessageWithCounter } from "../../../shared/components/form-elements/MessageWithCounter";

interface Props {
  type: WorkflowStrategyAdditionalFields;
  disabled?: boolean;
}

export const PerformancePlanFormField = ({ type, disabled }: Props) => {
  const {
    control,
    register,
    formState: { errors },
    watch,
  } = useFormContext();

  if (type === "budget")
    return (
      <NumberInput
        className='[&>div]:max-w-full'
        label='What is your budget?'
        name={type}
        control={control}
        validation={REQUIRED_VALIDATION_RULE}
        disabled={disabled}
      />
    );

  const selectProps = PERFORMANCE_PLAN_SELECT_DATA[type] || { label: "", options: [] };
  if (
    type === "brand" ||
    type === "goal" ||
    type === "goals" ||
    type === "ad_platform" ||
    type === "ad_platforms" ||
    type === "social_platform" ||
    type === "social_platforms"
  ) {
    return <Select name={type} {...selectProps} validation={REQUIRED_VALIDATION_RULE} control={control} disabled={disabled} />;
  }

  return (
    <TextArea
      {...getPerformanceTextAreaData({ type })}
      {...register(type, REQUIRED_VALIDATION_RULE)}
      error={errors?.[type]}
      disabled={disabled}
    >
      <MessageWithCounter charsCount={watch(type)?.length} maxChars={200} />
    </TextArea>
  );
};
