import { createAsyncThunk } from "@reduxjs/toolkit";

import { apiGetPaymentStatus, apiGetPaymentUrl } from "api/payments";

import { GetPaymentStatusPayload, GetPaymentUrlPayload } from "interfaces/payments.interfaces";

const PAYMENTS_SLICE_NAME = "payments";

export const getPaymentUrl = createAsyncThunk(`${PAYMENTS_SLICE_NAME}/getPaymentUrl`, ({ priceId }: GetPaymentUrlPayload) =>
  apiGetPaymentUrl({ priceId }),
);

export const getPaymentStatus = createAsyncThunk(`${PAYMENTS_SLICE_NAME}/getPaymentStatus`, ({ sessionId }: GetPaymentStatusPayload) =>
  apiGetPaymentStatus({ sessionId }),
);
