import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import { ConstraintStyles, ConstraintVariants } from "constants/shared/constraint.constants";

interface Props {
  className?: string;
  variant?: ConstraintVariants;
  children: ReactNode;
}

export const Constraint = ({ variant = ConstraintVariants.NORMAL, className, children }: Props) => {
  return <div className={twMerge("mx-auto", ConstraintStyles[variant], className)}>{children}</div>;
};
