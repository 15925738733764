import React from "react";
import { twMerge } from "tailwind-merge";

import { ReactComponent as CircularCheckMarkIcon } from "assets/icons/circular-check-mark.svg";
import { ReactComponent as EmptyCircularCheckMarkIcon } from "assets/icons/empty-circular-check-mark.svg";

interface Props {
  filled?: boolean;
  className?: string;
}

export const CheckMarkToggle = ({ filled, className }: Props) => {
  return <div className={twMerge("mr-2", className)}> {filled ? <CircularCheckMarkIcon /> : <EmptyCircularCheckMarkIcon />}</div>;
};
