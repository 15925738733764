import React, { InputHTMLAttributes } from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { INPUT_SHARED_CLASSNAME } from "constants/shared/fom-elements.constants";

import { SharedFormElementsProps } from "interfaces/shared/form-elements.interfaces";

import { formatNumberToUSLocale, removeComaFromLocaleString } from "utils/shared.utils";

import { ErrorMessage } from "./ErrorMessage";
import { FormLabel } from "./FormLabel";

interface Props extends InputHTMLAttributes<HTMLInputElement>, SharedFormElementsProps {
  control: Control;
  validation?: RegisterOptions;
  name: string;
  className?: string;
}

export const NumberInput = ({ label, name, control, validation, disabled, className }: Props) => {
  return (
    <div>
      <Controller
        name={name}
        control={control}
        rules={validation}
        render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
          <>
            <div className={className}>
              <FormLabel>{label}</FormLabel>
              <div className='relative max-w-[190px] flex items-center text-[#1F2228] font-medium text-sm'>
                <span className='flex items-center pointer-events-none w-[18px] h-[18px] absolute top-1/2 transform -translate-y-1/2 left-3.5 text-[#9EA2AD]'>
                  $
                </span>
                <input
                  onChange={e => onChange(removeComaFromLocaleString(e.target.value))}
                  value={Number(value) && value !== 0 ? formatNumberToUSLocale(Number(value)) : ""}
                  placeholder='0'
                  type='text'
                  disabled={disabled}
                  className={twMerge("w-full py-[9px] pr-[55px] pl-6 block", INPUT_SHARED_CLASSNAME)}
                  {...field}
                />
                <span className='cursor-pointer w-6 h-[18px] absolute top-1/2 transform -translate-y-1/2 right-6 text-[#333740]'>USD</span>
              </div>
              <ErrorMessage error={error?.message} />
            </div>
          </>
        )}
      />
    </div>
  );
};
