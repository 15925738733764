import cn from "classnames";
import React from "react";

import { ModalVariants } from "constants/shared/modal.constants";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import { useSubscriptionPlanInfo } from "hooks/subscriptions/useSubscriptionPlanInfo";
import { useBoolean } from "hooks/useBoolean";

import { logOutUser } from "storage/actions/auth";

import {ReactComponent as DotsVerticalIcon } from "assets/icons/dotsVertical.svg";
import { ReactComponent as LogoutIcon } from "assets/icons/logout.svg";

import { Avatar } from "../Avatar";
import { Dropdown } from "../dropdown/Dropdown";
import { Modal } from "../modal/Modal";
import { ModalControlButtons } from "../modal/ModalControlButtons";

interface Props {
  isSimplified?: boolean;
  hidden?: boolean;
}

export const UserAccountControls = ({ isSimplified, hidden }: Props) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.auth?.user);
  const [isModalOpen, _, handleOpenModal, handleCloseModal] = useBoolean();

  const dropdownOptions = [{ id: "logout", label: "Log Out", action: handleOpenModal, icon: LogoutIcon }];
  const handleLogout = () => {
    dispatch(logOutUser());
  };

  

  return (
    <div
      className={cn("py-4  relative transition-all duration-300 w-full flex items-center justify-between", {
        "invisible opacity-0 ": hidden,
      })}
    >
        <div className="flex items-center">
            <div className="border border-[#E9EAEC] rounded-full">
          <Avatar />
            </div>
      <p className="ml-2 font-medium text-sm text-[#464A53] text-wrap">{user?.name}</p>
      </div>
        <Dropdown className='min-w-[200px] !left-32' placement='bottom end' items={dropdownOptions}>
            <DotsVerticalIcon />
        </Dropdown>
        <Modal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          title='Are you sure?'
          titleClass="!text-[28px] !font-semibold"
          popupClass="!p-16"
          description='You will be logged out from your account.'
          variant={ModalVariants.SMALL}
        >
          <ModalControlButtons buttonClass="px-[42px] w-fit text-nowrap" submitButtonText='Log out' handleSubmit={handleLogout} handleCancel={handleCloseModal} />
        </Modal>
    </div>
  );
};
