import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";

import { ProjectListItem } from "interfaces/projects.interfaces";

import { getProjectsList } from "../actions/project";
import { ProjectState } from "../slices/project-slice";

export function getProjectsListReducer(builder: ActionReducerMapBuilder<ProjectState>) {
  builder.addCase(getProjectsList.fulfilled, (state, action: PayloadAction<ProjectListItem[]>) => {
    state.projectList = action.payload;
  });
}
