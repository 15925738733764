export enum SortOrders {
  "ASC" = "ASC",
  "DESC" = "DESC",
}

export interface Meta extends Sorting {
  page?: string;
  limit?: string;
  search?: string;
}

export interface Sorting {
  sort_order?: SortOrders;
  sort_by?: string;
}

export interface SortingWithId extends Sorting {
  id?: string;
}
