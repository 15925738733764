import React from "react";
import ReactDOM from "react-dom";

import { ReactComponent as CloseWhiteBtn } from "assets/icons/cross-rounded-white.svg";
import  ReviewOverlayIcon1  from "assets/icons/review-overlay-icon1.png";
import { ReactComponent as ReviewOverlayIcon2 } from "assets/icons/review-overlay-icon2.svg";
interface OverlayProps {
  onClick: () => void;
}

const OverlayReviewBudget: React.FC<OverlayProps> = ({ onClick }) => {
  return ReactDOM.createPortal(
    <div id="overlay">
       <CloseWhiteBtn height={36} width={36}  className='absolute top-6 right-6' onClick={onClick}/>
      <div className='flex flex-col items-center justify-center'>
        <div className='text-center w-486 text-white'>
            <p className='text-3xl font-bold'>Review your Budget</p>
            <p className='text-base font-medium text-white mt-4 leading-7'>Please take a time to verify your uploaded budget is correct to procede with strategy generation.</p>
        </div>
        <div className='relative mt-20 transform translate-x-1/2'>
        <div className='flex'>
            <div>
                <img src={ReviewOverlayIcon1} className='h-16 w-32' />
            </div>
            <div className='ml-3 mt-3'>
                <ReviewOverlayIcon2 height={50} width={50} />
                <div className='w-60 mt-4'>
                    <p className='text-xl font-bold text-white'>Click to edit</p>
                    <p className='text-base font-medium text-white mt-2'>You can modify each field by clicking and typing the value.</p>
                    <button className='px-10 py-2 rounded-xl bg-purple mt-4' onClick={onClick}>
                        <p className="text-sm font-semibold text-white text-left ">Got it</p>
                    </button>
                </div>
            </div>
        </div>
        </div>
      </div>
    </div>,
    document.body // Render in the document body to cover the entire viewport
  );
};

export default OverlayReviewBudget;
