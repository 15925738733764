export const getMinLengthValidationRules = (value: number) => ({
  minLength: {
    value: value,
    message: `At least ${value} characters.`,
  },
});

export const getMaxLengthValidationRules = (value: number) => ({
  maxLength: {
    value: value,
    message: `Maximum ${value} characters.`,
  },
});
