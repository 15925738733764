import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  emailConfirmation,
  googleAuthRedirect,
  login,
  logOut,
  sendCode,
  sendEmailVerificationLink,
  setNewPassword,
  verifyCode,
} from "api/auth";
import { ILoginData } from "api/auth/types";
import { IUserData } from "api/user/types";

import { clearPendoBrowserData } from "utils/pendo.utils";

import Alert from "shared/components/toasts";

import { AppDispatch } from "../store";

import { getMeData, setAuthData } from "./user";

export const AUTH_SLICE_NAME = "auth";

export const verifyEmail = (code: string) => async () => {
  try {
    return await emailConfirmation({ code });
  } catch (e: any) {
    const errorMessage = (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
    Alert("error", "Something went wrong", "Error");
  }
};

export const loginUser = createAsyncThunk(`${AUTH_SLICE_NAME}/loginUser`, async (payload: ILoginData, { rejectWithValue }) => {
  try {
    const res = await login(payload);
    localStorage.setItem("accessToken", res.accessToken);

    return res;
  } catch (e: any) {
    Alert("error", "Something went wrong", "Error");
    return rejectWithValue(e);
  }
});

export const logOutUser = createAsyncThunk(`${AUTH_SLICE_NAME}/logOutUser`, async (_, { dispatch, rejectWithValue }) => {
  try {
    const res = await logOut();
    localStorage.removeItem("accessToken");
    dispatch(setAuthData({ user: null, isAuthenticated: false }));
    clearPendoBrowserData();
    return res;
  } catch (e: any) {
    Alert("error", "Something went wrong", "Error");
    return rejectWithValue(e);
  }
});

export const sendConfirmationCode = (data: { email: string }) => async (dispatch: AppDispatch) => {
  try {
    return await sendCode(data);
  } catch (e: any) {
    const errorMessage = (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
    Alert("error", "Something went wrong", "Error");
  }
};

export const sendVerificationLink = (data: { email: string }) => async (dispatch: AppDispatch) => {
  try {
    return await sendEmailVerificationLink(data);
  } catch (e: any) {
    const errorMessage = (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
    Alert("error", "Something went wrong", "Error");
  }
};

export const validateCode = (data: { email: string; code: string }) => async (dispatch: AppDispatch) => {
  try {
    return await verifyCode(data);
  } catch (e: any) {
    const errorMessage = (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
    Alert("error", "Something went wrong", "Error");
  }
};

export const createNewPassword = (data: { email: string; code: string; password: string }) => async (dispatch: AppDispatch) => {
  try {
    return await setNewPassword(data);
  } catch (e: any) {
    const errorMessage = (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
    Alert("error", "Something went wrong", "Error");
  }
};

export const googleAuth = createAsyncThunk(
  `${AUTH_SLICE_NAME}/googleAuth`,
  async (data: { token: string }, { dispatch, rejectWithValue }) => {
    try {
      const res = await googleAuthRedirect(data);

      let user = null;
      if (res.accessToken) {
        localStorage.setItem("accessToken", res.accessToken);
        user = await dispatch(getMeData());
      }
      return { accessToken: res.accessToken, user: (user || {}) as IUserData };
    } catch (e: any) {
      Alert("error", "Something went wrong", "Error");
      return rejectWithValue(e);
    }
  },
);
