import cn from "classnames";
import React, { ReactNode } from "react";
import { FieldError } from "react-hook-form";

import { Option } from "interfaces/shared/form-elements.interfaces";

import { ErrorMessage } from "../ErrorMessage";
import { FormLabel } from "../FormLabel";

interface Props {
  options: Option[];
  renderContent: (data: Option) => ReactNode;
  label?: string;
  description?: string;
  error?: Pick<FieldError, "message">;
}

export const SwitchableInputsGroup = ({ label, description, error, options, renderContent }: Props) => {
  return (
    <fieldset>
      <FormLabel className={cn("mb-3", { "mb-0": description })} tag='legend'>
        {label}
      </FormLabel>
      {description && <p className='mb-4 text-sm text-[#717684]'>{description}</p>}
      <div className='flex flex-wrap gap-2'>{options?.map((option) => renderContent(option))}</div>
      <ErrorMessage error={error?.message} />
    </fieldset>
  );
};
