import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";

import { MembershipPlan } from "interfaces/memberships.interfaces";

import { getMembershipsPlans } from "../actions/memberships";
import { MembershipsState } from "../slices/memberships-slice";

export const s = "";

export function getMembershipsPlansReducer(builder: ActionReducerMapBuilder<MembershipsState>) {
  builder.addCase(getMembershipsPlans.fulfilled, (state, action: PayloadAction<MembershipPlan[]>) => {
    state.plans = action.payload;
  });
}
