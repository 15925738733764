import { Dispatch } from "redux";

import { getChannelsData } from "../../api/budget";
import Alert from "../../shared/components/toasts";
import { setChannels } from "../slices/channels-slice";

export const getProjectChannelsData = () => async (dispatch: Dispatch) => {
  try {
    const channels = await getChannelsData();
    dispatch(setChannels([...channels]));

    return channels;
  } catch (e: any) {
    const errorMessage = (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
    Alert("error", "Something went wrong", "Error");
  }
};
