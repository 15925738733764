import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useAppDispatch } from "hooks/appHooks";

import { verifyEmail } from "storage/actions/auth";

import Alert from "shared/components/toasts";

export default function PublicRoute() {
  const accessToken = localStorage.getItem("accessToken");
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");

    if (token) {
      dispatch(verifyEmail(token)).then((res: any) => {
        if (res.success) {
          Alert("success", "Email verified successfully", "Success");
        }
      });
    }
  }, []);

  return accessToken ? <Navigate to='/dashboard' /> : <Outlet />;
}
