import React, { ReactNode } from "react";

interface Props {
  className?: string;
  children: ReactNode;
  title: string;
}

export const ChannelStrategyCard = ({ className, children, title }: Props) => {
  return (
    <div className={className}>
      <h4 className='text-xs text-grey1 text-left mb-1'>{title}</h4>
      <div className='text-sm font-bold text-black1 flex items-center text-left'>{children}</div>
    </div>
  );
};
