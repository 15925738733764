import React from "react";

import { getMembershipsTabs } from "constants/memberships.constants";

import { Modal } from "shared/components/modal/Modal";
import { Tabs } from "shared/components/tabs/Tabs";

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
}

export const MembershipsModalWindow = ({ isOpen, onClose }: Props) => {
  return (
    <Modal
      className='max-w-[1090px]'
      descriptionClassName='text-[#2C1A49] mt-3'
      childrenClassName='mt-6'
      title="It's upgrade time"
      description='Upgrade your plan to maximize your business potential.'
      isOpen={isOpen}
      onClose={onClose}
    >
      <Tabs className='my-6' contentClassName='px-0 h-full' tabs={getMembershipsTabs({ isSimplified: true })} />
    </Modal>
  );
};
