import React from "react";

import PlusIcon from "../IconsComponents/PlusIcon";

interface TagComponentProps {
  tag: string;
  index: number;
  isExample?: boolean;
  onDelete: (index: number) => void;
  addTag?: (tag:string)=>void;
}

export default function TagComponent({ tag, isExample, onDelete, index, addTag }: TagComponentProps) {
  return (
    <button
    onClick={()=>{addTag?.(tag)}}
      className={`flex items-center flex-nowrap px-2 py-1.5 mr-2 rounded-md text-[#1F2228] text-xs font-medium gap-x-1 
        ${isExample ? "bg-[#E9EAEC] hover:bg-[#d3d4d6]" : "bg-[#F1F0FC]"} ${addTag ? "cursor-pointer" : ""}
    `}
    >
      {tag}
      {!isExample && (
        <span onClick={() => onDelete(index)}>
          <PlusIcon className='rotate-45 cursor-pointer' />
        </span>
      )}
    </button>
  );
}
