export const stepFormConfig = [
  {
    tabIndex: 1,
    title: "Define your project",
    description: "Targets recommendations for your <br/> project's specific needs.",
  },
  {
    tabIndex: 2,
    title: "Set your objective",
    description: "Optimizes spending recommendations <br/> for impactful campaigns.",
  },

  {
    tabIndex: 3,
    title: "Annual marketing strategy",
    description: "Defines the resource envelope <br/> within which to make recommendations.",
  },
  // {
  //   tabIndex: 4,
  //   title: 'Company revenue and growth',
  //   description: 'Necessary to provide custom <br/> scalable strategies.',
  // },
];
