import React from "react";

import {ReactComponent as ArrowIcom} from "assets/icons/arrow-up-right.svg"
import {ReactComponent as StatIcon} from "assets/icons/stat-up.svg"
import startProjectInsights from "assets/png/start-project-insights-faded.png";

interface StartProjectCardProps {
  onButtonClick: () => void;
}

const StartProjectCard: React.FC<StartProjectCardProps> = ({ onButtonClick }) => {
  return (
    <div
      className='p-6 w-[400px] relative flex flex-col transition-all duration-300 ease-in-out justify-end bg-white h-64 border-[1px] group hover:border-[#9189E9] hover:shadow-ring1 border-[#C8CAD0] bg-cover bg-center bg-no-repeat rounded-[20px]'
      style={{ backgroundImage: `url(${startProjectInsights})` }}
    >
      <div className="flex gap-4 justify-between">
        <div className="flex w-[80%] flex-col gap-2">
          <h1 className="text-base leading-default font-bold text-[#14191A]">Start Project</h1>
          <p className="text-[#717684] leading-[22.4px] text-sm font-medium">Get actionable insights and key metrics to enhance your marketing effectiveness.</p>
        </div>
        <button className="h-9 flex items-center transition-all duration-300 ease-in-out justify-center w-9 bg-white rounded-[100%] border-[1px] border-[#E9EAEC] stroke-[#1F2228] group-hover:stroke-white group-hover:bg-[#766CE4]" onClick={onButtonClick}>
          <ArrowIcom />
        </button>
      </div>
      <div className="flex flex-col left-[46px] top-[53px] absolute gap-2 rounded-xl bg-white box border-[1px] border-[#E9EAEC] p-2" style={{boxShadow: "0px 8px 25px 0px rgba(0, 0, 0, 0.04)"}}>
        <h3 className="text-[#9EA2AD] text-xs font-semibold leading-default">YTD Sales</h3>
        <div className="flex gap-[6px] items-end">
          <h3 className="text-[15px] font-bold leading-default text-[#464A53]">$19.1M</h3>
          <div className="py-[3px] rounded-[21px] px-[6px] flex items-center gap-1 fill-[#1D9052] text-[#1D9052] bg-[#D1FAE4]">
            <StatIcon />
            <p className="text-[10px] font-semibold leading-default">+2,3%</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartProjectCard;
