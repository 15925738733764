import { useMemo } from "react";

import { StringOrNull } from "interfaces/shared-types.interfaces";

import { checkIsViewAvailableBySubscription } from "utils/subscriptions.utils";

interface Props {
  currentBudgetDate: string;
  budgetDateFrom: StringOrNull;
}

export const useLimitViewBySubscription = ({ budgetDateFrom, currentBudgetDate }: Props) => {
  const isAvailable = useMemo(
    () => checkIsViewAvailableBySubscription({ budgetDateFrom, currentBudgetDate }),
    [budgetDateFrom, currentBudgetDate],
  );

  return { isAvailable };
};
