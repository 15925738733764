import { usePaymentStatus } from "hooks/payments/usePaymentStatus";
import { useInitializePendo } from "hooks/useInitializePendo";

import { DashboardLayout } from "shared/layouts/DashboardLayout";

import { DashboardUpcomingFeatures } from "page-components/dashboard/dashboard-features/DashboardUpcomingFeatures";
import { DashboardInsightsContainer } from "page-components/dashboard/dashboard-insights/DashboardInsightsContainer";
import { DashboardTransformInsightsContainer } from "page-components/dashboard/dashboard-insights/DashboardTransformInsightsContainer";
import { DashboardGreeting } from "page-components/dashboard/DashboardGreeting";

export default function Dashboard() {
  usePaymentStatus();
  useInitializePendo();

  return (
    <DashboardLayout title='Get Started'>
      <div className='w-full p-6'>
        <DashboardGreeting />
        <DashboardInsightsContainer />
        <DashboardTransformInsightsContainer />
        <DashboardUpcomingFeatures />
      </div>
    </DashboardLayout>
  );
}
