import * as echarts from "echarts";
import React, { useEffect, useRef, FC } from "react";

import { IChannelsTransformedData } from "api/budget/types";

interface Props {
  activeMonth: IChannelsTransformedData;
  animation?: boolean;
}

const DonutChart: FC<Props> = ({ activeMonth, animation }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  const data: { value: any; name: any; itemStyle: { color: any } }[] | undefined = activeMonth?.values?.map((item: any) => ({
    value: item?.value,
    name: item?.channel,
    itemStyle: {
      color: item?.color?.primary, // Customize color for each data section
    },
  }));

  const total = data?.reduce((acc, cur) => acc + cur.value, 0);

  useEffect(() => {
    if (chartRef.current) {
      const chartInstance = echarts.init(chartRef.current);

      const option: echarts.EChartsOption = {
        tooltip: {
          trigger: "item",
          backgroundColor: "#2E323A", // Background color
          borderColor: "#2E323A", // Border color
          borderRadius: 13, // Border radius
          textStyle: {
            color: "#fff", // Text color
            fontSize: 12,
            align: "left",
          },

          formatter: (params: any) => {
            const item = data.find(item => item.name === params.name);
            if (item) {
              return `
                <div>
                  <p>${params.name} Budget</p>
                  <p><b>$${item.value.toLocaleString("en-US")}</></p>
                </div>
              `;
            }
            return "";
          },
        },
        legend: {
          top: "middle", // Position the legend vertically in the middle
          left: "55%", // Adjust the distance from the right edge
          orient: "vertical",
          align: "left",
          icon: "circle",
          itemHeight: 10, // Set the item height to control the legend height
          itemWidth: 10,
          type: "scroll",
          textStyle: {
            color: "rgba(70, 74, 83, 1)",
            fontFamily: "Plus Jakarta Sans",
            fontSize: 15,
            fontStyle: "normal",
            fontWeight: 500,
          },
          itemGap: 13,
          formatter: (name: string) => {
            const item = data.find(item => item.name === name);
            if (item) {
              let percentage = (item?.value / total) * 100;

              if (data.findIndex(i => i === item) === activeMonth.values?.length - 1) {
                percentage =
                  100 -
                  activeMonth?.values.slice(0, -1).reduce((acc: number, item: any) => acc + Math.round((item.value / total) * 100), 0);
              } else {
                percentage = Math.round(percentage);
              }

              if (isNaN(percentage)) {
                percentage = 0;
              } else if (percentage < 1) {
                percentage = parseFloat(percentage.toFixed(1));
              } else {
                percentage = Math.round(percentage);
              }

              return `${percentage}% - ${item.name}`;
            }
            return name;
          },
        },
        series: [
          {
            type: "pie",
            radius: ["44%", "85%"],
            center: ["25%", "50%"], // Center the pie chart on the left
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 3,
              borderColor: "#fff",
              borderWidth: 3,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 15,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: data,
          },
        ],
        animation,
      };
      chartInstance.setOption(option);

      // Clean up on unmount
      return () => {
        chartInstance.dispose();
      };
    }
  }, [activeMonth?.values, data, total, animation]);

  return <div id='main' ref={chartRef} style={{ width: "100%", height: "100%" }} />;
};

export default DonutChart;
