import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../store";

const selectState = (x: RootState) => x.workflows;

export const selectIsGeneratingWorkflowContent = createSelector(selectState, state => state.isGeneratingContent);

export const selectWorkflow = createSelector(selectState, state => state.workflow);

export const selectWorkflowData = createSelector(selectState, state => state.workflow?.workflow_data);

export const selectWorkflows = createSelector(selectState, state => state?.workflows);

export const selectWorkflowsWithIsLoaded = createSelector(selectState, ({ workflows, isWorkflowsLoaded }) => ({
  workflows,
  isWorkflowsLoaded,
}));

export const selectIsWorkflowLoading = createSelector(selectState, state => state.isWorkflowLoading);
