import cn from "classnames";
import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  children?: ReactNode;
  isSelected?: boolean;
  onClick?: VoidFunction;
  hover?: boolean;
}

export const TableRow = ({ children, isSelected, onClick, hover }: Props) => {
  return (
    <tr
      onClick={onClick}
      className={cn(
        twMerge(
          "[&_td]:border-b border-[#EAECF0] first:[&_td]:border-l last:[&_td]:border-r first:[&_td]:last:rounded-bl-2xl last:[&_td]:last:rounded-br-2xl",
        ),
        { "[&_td]:bg-[#F1F0FC]": isSelected, "[&_td]:hover:bg-[#F1F0FC]":hover },
      )}
    >
      {children}
    </tr>
  );
};
