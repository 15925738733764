import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LoaderState {
  unsaved: boolean;
}

const initialState: LoaderState = {
  unsaved: false,
};

export const editorSlice = createSlice({
  name: "editor",
  initialState,
  reducers: {
    setUnsaved: (state, action: PayloadAction<boolean>) => {
      state.unsaved = action.payload;
    },
  },
});

export const { setUnsaved } = editorSlice.actions;
export const editorReducer = editorSlice.reducer;
