import { useMemo } from "react";
import { Tooltip } from "react-tooltip";

import { TypographyVariants } from "constants/shared/typography.constants";

import { useAppSelector } from "hooks/appHooks";

import { Typography } from "shared/components/Typography";

import { BudgetSuggestionsSlider } from "page-components/budgets/budget-suggestions-slider/BudgetSuggestionsSlider";

import { ReactComponent as BigInfoIcon } from "assets/icons/bigInfo.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import { ReactComponent as SparcleIcon } from "assets/icons/sparcles.svg";

import { RevenueFormattedValue } from "../RevenueFormattedValue";

import classes from "./BrainvoySuggest.module.scss";

interface QuarterlyRoadmapItem {
  quarter_number: number;
  quarter_year: number;
  revenue_impact: number;
  confidence_score: number;
  explanation: string;
}

const BrainvoySuggest = () => {
  const suggestions = useAppSelector(state => state.project.project?.project_data.suggestions) || [];
  const quarterlyRoadmap =
    useAppSelector(state => state.project.project?.project_data?.quarterly_roadmap) || ([] as QuarterlyRoadmapItem[]);
  const project = useAppSelector(state => state.project.project);

  const { revenue_from, revenue_to } = project || {};

  const totalRevenue = quarterlyRoadmap.reduce((acc: number, item: any) => {
    acc += item?.revenue_impact;
    return acc;
  }, 0);

  function formatRevenue(revenue: any) {
    if (revenue < 1000) {
      return revenue?.toString();
    } else if (revenue < 1000000) {
      return (revenue / 1000).toFixed(0) + "K";
    } else {
      return (revenue / 1000000).toFixed(0) + "M";
    }
  }

  const formattedSuggestions = useMemo(() => {
    const chunkSize = 3;

    if (Array.isArray(suggestions)) {
      return suggestions.reduce((acc, _, index) => {
        if (index % chunkSize === 0) {
          acc.push(suggestions.slice(index, index + chunkSize));
        }
        return acc;
      }, []);
    }
    else {
      return suggestions
    }
  }, [suggestions]);

  return (
    <section className={classes.topSection}>
      <div className={classes.topSection__suggest}>
        <div className={classes.topSection__suggest__header}>
          <SparcleIcon className='outline-none focus:outline-none' />
          <Typography className='text-black' variant={TypographyVariants.H5}>
            Brainvoy Suggest
          </Typography>
        </div>
        <div className={classes.topSection__suggest__list}>
          <BudgetSuggestionsSlider suggestions={formattedSuggestions} />
        </div>
      </div>

      <div className={classes.topSection__opportunity}>
        <Tooltip
          id='title-tooltip'
          positionStrategy='fixed'
          place='top'
          opacity={1}
          style={{
            borderRadius: "13px",
            background: "#2E323A",
            boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.20)",
            zIndex: 1000,
            fontSize: "12px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>This data was generated</span>
            <span>based on AI analysis</span>
            <span>of the provided inputs.</span>
          </div>
        </Tooltip>
        <div className={classes.topSection__opportunity__title}>
          Revenue Opportunity
          <BigInfoIcon data-tooltip-id='title-tooltip' className='outline-none focus:outline-none' />
        </div>
        <div className={classes.topSection__opportunity__card}>
          <h4 className={classes.topSection__opportunity__card__title}>Projected Revenue Impact</h4>
          <RevenueFormattedValue from={revenue_from} to={revenue_to} />
          <div className={classes.topSection__opportunity__card__data}>
            <Typography className='text-[#5E636E] !font-medium flex items-center' tag='span' variant={TypographyVariants.MD_SEMIBOLD}>
            Estimated revenue from marketing for Q{quarterlyRoadmap[quarterlyRoadmap.length - 1]?.quarter_number}{" "}
              <InfoIcon data-tooltip-id='revenue-tooltip' className='outline-none focus:outline-none ml-1.5' />
              <Tooltip
                id='revenue-tooltip'
                content='Expertly crafted with AI'
                place='top'
                positionStrategy='fixed'
                style={{
                  borderRadius: "13px",
                  background: "#2E323A",
                  fontSize: "12px",
                  boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.20)",
                  zIndex: 1000,
                }}
              />
            </Typography>
            <div className={classes.topSection__opportunity__card__data__total}>
              <span className={classes.topSection__opportunity__card__data__total__leftData}>
                ${formatRevenue(quarterlyRoadmap[quarterlyRoadmap.length - 1]?.revenue_impact)}
              </span>
              <span className={classes.topSection__opportunity__card__data__total__rightData}>${formatRevenue(totalRevenue)}</span>
              <div
                className={classes.topSection__opportunity__card__data__total__progress}
                style={{
                  width: `${Math.round((quarterlyRoadmap[quarterlyRoadmap.length - 1]?.revenue_impact / totalRevenue) * 100)}%`,
                }}
              ></div>
            </div>
          </div>
          <div className={`${classes.topSection__opportunity__card__data} !mt-[14px]`}>
            <Typography className='text-[#5E636E] flex items-center !font-medium' tag='span' variant={TypographyVariants.MD_SEMIBOLD}>
              Confidence score{" "}
              <InfoIcon data-tooltip-id='confidence-tooltip' className='outline-none focus:outline-none ml-1.5' />
              <Tooltip
                id='confidence-tooltip'
                content='Expertly crafted  Success confidence with AI'
                place='top'
                style={{
                  borderRadius: "13px",
                  background: "#2E323A",
                  fontSize: "12px",
                  boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.20)",
                  zIndex: 1000,
                }}
              />
            </Typography>

            <div className={classes.topSection__opportunity__card__data__total}>
              <span className={classes.topSection__opportunity__card__data__total__rightData}>
                {quarterlyRoadmap[quarterlyRoadmap.length - 1]?.confidence_score}%
              </span>
              <div
                className={classes.topSection__opportunity__card__data__total__progress}
                style={{
                  width: `${quarterlyRoadmap[quarterlyRoadmap.length - 1]?.confidence_score}%`,
                  background: "#D5D1F7",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BrainvoySuggest;
