import React from "react";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";

import { updateUserPreferences } from "storage/slices/preferences-slice";

import BetaLabel from "shared/components/beta-label/BetaLabel";

const OperationalFakeDoor = () => {
  const dispatch = useAppDispatch();
  const preferences = useAppSelector(state => state.preferences);

  const handleGetAccess = () => {
    dispatch(updateUserPreferences({ preferences: { operationalViewAccessRequested: true }, schema_version: "1.0.0" }));
  };
  return (
    <div
      className='p-16 flex flex-col max-w-[482px] text-center justify-center items-center rounded-[25px] bg-white'
      style={{ boxShadow: "0px 4px 68.5px 0px rgba(0, 0, 0, 0.18)" }}
    >
      <BetaLabel classes='!px-3 !text-sm !font-semibold !tracking-[0.28px]' />
      <h3 className='mt-4 text-[#1F2228] leading-default text-[28px] font-semibold'>Explore early access to operational view</h3>
      <p className='font-medium mt-6 text-center text-[#5E636E] text-sm'>
        Unlock custom reports and AI-driven optimizations. Preview and refine your data with ease. Sign up for exclusive access!
      </p>
      {preferences?.operationalViewAccessRequested ? (
        <h3 className="text-sm font-semibold leading-default text-[#6B62CF] mt-[51px]">Thank you, we have added you to the waitlist.</h3>
      ) : (
        <button
          className='mt-10 bg-[#766CE4] rounded-xl py-[10px] px-[42px] text-white font-semibold text-sm leading-default'
          onClick={handleGetAccess}
        >
          Get Access
        </button>
      )}
    </div>
  );
};

export default OperationalFakeDoor;
