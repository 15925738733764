import { useCallback, useState } from "react";

import { SortingWithId, SortOrders } from "interfaces/meta.interfaces";

export type UseSortingParams = {
  sorting: SortingWithId;
  updateSorting: (values: SortingWithId) => void;
};

export const useSorting = (): UseSortingParams => {
  const [sorting, setSorting] = useState<SortingWithId>({ sort_order: SortOrders.ASC, id: "oldest" });

  const updateSorting = useCallback((sortValues: SortingWithId) => {
    setSorting(sortValues);
  }, []);

  return { sorting, updateSorting };
};
