import { createSlice } from "@reduxjs/toolkit";

import { MembershipPlan } from "interfaces/memberships.interfaces";

import { getMembershipsPlansReducer } from "storage/reducers/memberships";

export interface MembershipsState {
  plans: MembershipPlan[] | null;
}

const initialState: MembershipsState = {
  plans: null,
};

export const membershipsSlice = createSlice({
  name: "memberships",
  initialState,
  reducers: {},
  extraReducers: builder => {
    getMembershipsPlansReducer(builder);
  },
});

export const membershipsReducer = membershipsSlice.reducer;
