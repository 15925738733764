import { twMerge } from "tailwind-merge";

export enum ButtonVariants {
  LARGE = "large",
  LARGE_ICON = "large_icon",
  LARGE_LINK = "large_link",
  MEDIUM = "medium",
  MEDIUM_ICON = "medium_icon",
  MEDIUM_LINK = "medium_link",
  SMALL = "small",
  SMALL_ICON = "small_icon",
  BORDERED = "bordered",
  MEDIUM_PREMIUM = "medium_premium",
  MEDIUM_PREMIUM_LINK = "medium_premium_link",
  EXTRA_SMALL = "extra_small",
  DISABLED = "disabled",
  LINK_DISABLED = "link_disabled",
  LINK_TEXT_LARGE = "link_text_large",
  LINK_TEXT_MEDIUM = "link_text_medium",
  LARGE_STROKED = "large_stroked",
  MEDIUM_STROKED = "medium_stroked",
}
export const SHARED_BUTTON_CLASSNAME =
  "w-full flex justify-center items-center gap-x-2 [&_svg]:w-5 [&_svg]:h-5  [&_svg]:text-current transition-all duration-300";

const PRIMARY_SHARED_CLASSNAME =
  "bg-[#766CE4] text-white text-sm font-semibold leading-5 relative focus:before:border-[#544DA2] hover:bg-[#6B62CF] focus:bg-[#6B62CF] focus:bg-[#6B62CF] focus:shadow-ring before:rounded-xl before:content-[''] before:absolute before:inset-0 before:border before:border-[#766CE4] before:pointer-events-none before:transition-all before:duration-300";

const PRIMARY_LINK_SHARED_CLASSNAME =
  "py-0.5 px-3.5 bg-transparent rounded text-[#766CE4] hover:underline hover:text-[#6B62CF] focus:bg-[#F9F9FF] focus:text-[#544DA2] disabled:bg-transparent disabled:underline";

export const DISABLED_SHARED_CLASSNAME = "bg-[#E9EAEC] border-[#E9EAEC] text-[#C8CAD0] cursor-not-allowed pointer-events-none";

export const LINK_TEXT_SHARED_CLASSNAME = "w-fit font-semibold leading-default text-[#766CE4] underline inline-block";

export const STROKED_SHARED_CLASSNAME =
  "bg-[#FCFCFC] border border-[#D5D1F7] rounded-xl text-sm  font-semibold hover:border-[#9189E9] disabled:bg-[#E9EAEC] disabled:border-[#E9EAEC] disabled:text-[#C8CAD0] focus:shadow-ring1";

export const ButtonStyles: Record<ButtonVariants, string> = {
  [ButtonVariants.LARGE]: twMerge(PRIMARY_SHARED_CLASSNAME, "py-2.5 px-6 rounded-xl"),

  [ButtonVariants.LARGE_ICON]: twMerge(
    PRIMARY_SHARED_CLASSNAME,
    "p-2.5 rounded-full bg-[#766CE4] [&_svg]:w-5 [&_svg]:h-5 [&_svg]:mx-0 before:rounded-full",
  ),
  [ButtonVariants.LARGE_LINK]: twMerge(PRIMARY_LINK_SHARED_CLASSNAME, "text-base leading-6 font-bold"),
  [ButtonVariants.MEDIUM]: twMerge(PRIMARY_SHARED_CLASSNAME, "py-2.5 px-6 leading-4 rounded-xl [&_svg]:w-4 [&_svg]:h-4"),
  [ButtonVariants.MEDIUM_ICON]: twMerge(
    PRIMARY_SHARED_CLASSNAME,
    "p-[9px] rounded-full [&_svg]:w-[18px] [&_svg]:h-[18px] [&_svg]:mx-0 before:rounded-full",
  ),
  [ButtonVariants.MEDIUM_LINK]: twMerge(PRIMARY_LINK_SHARED_CLASSNAME, "text-sm leading-5 font-semibold"),
  [ButtonVariants.SMALL]: twMerge(
    PRIMARY_SHARED_CLASSNAME,
    "py-[7px] px-8 leading-[18px] rounded-lg [&_svg]:w-4 [&_svg]:h-4 before:rounded-lg",
  ),
  [ButtonVariants.SMALL_ICON]: twMerge(
    PRIMARY_SHARED_CLASSNAME,
    "p-[7px] rounded-full [&_svg]:w-[18px] [&_svg]:h-[18px] [&_svg]:mx-0 before:rounded-full",
  ),
  [ButtonVariants.BORDERED]:
    "py-[9px] px-6 rounded-xl border border-[#E9EAEC] text-[#2E323A] text-sm font-bold hover:border-[#C0BBF3] focus:shadow-ring disabled:border-[#E9EAEC] disabled:bg-[#F7F7F8] disabled:text-[#C8CAD0] [&_path]:disabled:fill-[#D9D9D9]",
  [ButtonVariants.MEDIUM_PREMIUM]: "py-2.5 px-6 text-sm font-semibold text-white bg-gradient-purple rounded-[10px] hover:shadow-ring",
  [ButtonVariants.MEDIUM_PREMIUM_LINK]: "text-sm font-semibold text-transparent bg-gradient-purple bg-clip-text",
  [ButtonVariants.EXTRA_SMALL]: twMerge(PRIMARY_SHARED_CLASSNAME, "py-1 px-8 text-xs font-medium rounded-lg"),
  [ButtonVariants.DISABLED]: twMerge(DISABLED_SHARED_CLASSNAME, "bg-[#E9EAEC] text-[#C8CAD0] before:border-[#E9EAEC]"),
  [ButtonVariants.LINK_DISABLED]: twMerge(DISABLED_SHARED_CLASSNAME, "bg-transparent underline"),
  [ButtonVariants.LINK_TEXT_LARGE]: twMerge(LINK_TEXT_SHARED_CLASSNAME, "text-base"),
  [ButtonVariants.LINK_TEXT_MEDIUM]: twMerge(LINK_TEXT_SHARED_CLASSNAME, "text-sm"),
  [ButtonVariants.LARGE_STROKED]: twMerge(STROKED_SHARED_CLASSNAME, "py-[9px] px-6"),
  [ButtonVariants.MEDIUM_STROKED]: twMerge(STROKED_SHARED_CLASSNAME, "py-[7px] px-6"),
};
