import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IChannelData } from "../../api/budget/types";

interface ChannelState {
  channels: IChannelData[];
}

const initialState: ChannelState = {
  channels: [],
};

export const channelsSlice = createSlice({
  name: "channels",
  initialState,
  reducers: {
    setChannels: (state, action: PayloadAction<IChannelData[]>) => {
      state.channels = action.payload;
    },
  },
});

export const { setChannels } = channelsSlice.actions;
export const channelsReducer = channelsSlice.reducer;
