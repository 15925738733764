import { useState } from "react";

export const useBoolean = (initialValue?: boolean): [boolean, (value: boolean) => void, VoidFunction, VoidFunction] => {
  const [booleanValue, setBooleanValue] = useState(initialValue || false);

  const handleSetTrue = () => setBooleanValue(true);

  const handleSetFalse = () => setBooleanValue(false);

  const handleSetValue = (value: boolean) => setBooleanValue(value);

  return [booleanValue, handleSetValue, handleSetTrue, handleSetFalse];
};
