import { Menu, MenuButton, MenuHeading, MenuItem, MenuItems, MenuSection } from "@headlessui/react";
import { MenuButtonProps } from "@headlessui/react/dist/components/menu/menu";
import cn from "classnames";
import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import { DropdownStyles, DropdownVariants } from "constants/shared/dropdown.constants";

import { DropdownItem } from "interfaces/shared/dropdown.interfaces";

import { DropdownContentWrapper } from "./DropdownContentWrapper";
import { DropdownItemWrapper } from "./DropdownItemWrapper";
import { DropdownMenuButton } from "./DropdownMenuButton";

import type { AnchorProps } from "@headlessui/react/dist/internal/floating";

interface Props {
  children?: MenuButtonProps<"button">["children"];
  items?: DropdownItem[];
  heading?: string;
  headingClass?: string;
  className?: string;
  placement?: AnchorProps;
  variant?: DropdownVariants;
  customContent?: ReactNode;
  activeId?: string;
  handleIsOpen?: (value: boolean) => void;
}

export const Dropdown = ({
  children,
  items,
  heading,
  headingClass,
  className,
  placement,
  variant = DropdownVariants.DEFAULT,
  customContent,
  activeId,
  handleIsOpen,
}: Props) => {
  return (
    <Menu>
      {({ open }) => {
        return (
          <DropdownContentWrapper open={open} handleIsOpen={handleIsOpen}>
            <MenuButton as={DropdownMenuButton}>{children}</MenuButton>
            {open && ((items?.length && !customContent) || customContent) ? (
              <MenuItems
                anchor={placement || "bottom"}
                className={twMerge(
                  "bg-white rounded-xl py-1.5 px-1 mb-8 shadow-[0px_4px_29.6px_0px_#00000012] z-[100]",
                  DropdownStyles[variant]?.menuItems,
                  className,
                )}
              >
                <MenuSection className={DropdownStyles[variant]?.menuSection}>
                  {heading && (
                    <MenuHeading className={"text-xs font-medium text-[#717684] py-1 px-2" + " " + headingClass}>{heading}</MenuHeading>
                  )}
                  <>
                    {customContent}
                    {items?.length
                      ? items.map(({ id, action, href, label, icon: Icon }) => (
                          <MenuItem key={id}>
                            <DropdownItemWrapper
                              id={id}
                              href={href}
                              action={action}
                              className={twMerge(
                                cn("flex justify-between transition-all duration-200", DropdownStyles[variant]?.menuItem, {
                                  "text-[#766CE4]": id === activeId,
                                }),
                              )}
                            >
                              {Icon && <Icon className='mt-1' />}
                              {label}
                            </DropdownItemWrapper>
                          </MenuItem>
                        ))
                      : null}
                  </>
                </MenuSection>
              </MenuItems>
            ) : null}
          </DropdownContentWrapper>
        );
      }}
    </Menu>
  );
};
