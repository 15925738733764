import dayjs from "dayjs";

export const getFullDateString = ({ years, days }: { years: number; days: number }) => {
  if (!years) return days;

  const endOfCurrentYear = dayjs().endOf("year");

  const daysLeftInCurrentYear = endOfCurrentYear.diff(dayjs(new Date()), "days");

  const yearsString = `${years} ${years > 1 ? "years" : "year"}`;

  if (daysLeftInCurrentYear) return `${yearsString}, ${daysLeftInCurrentYear} ${daysLeftInCurrentYear > 1 ? "days" : "day"}`;

  return yearsString;
};

export const getFormattedDateString = ({
  days,
  hours,
  minutes,
  seconds,
}: {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}) => {
  if (seconds <= 0) return null;

  if (minutes === 0) return "1 minute";

  if (hours < 1) return `${minutes} ${minutes === 1 ? "minute" : "minutes"}`;

  if (days < 1) return `${hours} ${hours === 1 ? "hour" : "hours"}`;

  if (days === 1) return `${days} day`;

  return `${days} days`;
};
