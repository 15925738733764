import React from "react";

import PlusIcon from "../../IconsComponents/PlusIcon";

export default function AddMoreItem({ clickHandler, disabled }: any) {
  return (
    <div
      onClick={disabled ? null : clickHandler}
      className={`relative w-fit flex items-center px-6 py-[10px] border-[1.5px] text-sm text-[#766CE4] font-medium gap-x-2.5 border-[#D5D1F7] rounded-[56px] border-dashed cursor-pointer hover:bg-[#F9F9FF]
        ${disabled ? "border-[#E9EAEC] cursor-not-allowed text-[#C8CAD0] hover:bg-[#fff]" : ""}
        `}
    >
      Add more <PlusIcon className='w-5 h-5' color={disabled ? "#C8CAD0" : "#766CE4"} />
    </div>
  );
}
