import { yupResolver } from "@hookform/resolvers/yup";
import cn from "classnames";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";

import { ROUTES } from "constants/routes.constants";
import { ButtonVariants } from "constants/shared/button.constants";
import { TypographyVariants } from "constants/shared/typography.constants";

import { useAppDispatch } from "hooks/appHooks";

import { createNewPassword } from "storage/actions/auth";

import { Button } from "shared/components/buttons/Button";
import { Input } from "shared/components/form-elements/Input";
import Alert from "shared/components/toasts";
import { Typography } from "shared/components/Typography";
import { AuthLayout } from "shared/layouts/AuthLayout";

import { ReactComponent as BackButton } from "assets/icons/BackButton.svg";
import { ReactComponent as BigLockIcon } from "assets/icons/BigLock.svg";
import { ReactComponent as CheckIcon } from "assets/icons/CheckIcon.svg";
import { ReactComponent as LockIcon } from "assets/icons/lock.svg";

const schema = yup.object().shape({
  newPassword: yup
    .string()
    .required("Password is required")
    .min(8, "Must be at least 8 characters")
    .matches(/[^A-Za-z0-9]/, "Must contain one special character"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), undefined], "Passwords must match")
    .nullable(),
});

export default function NewPassword() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<{ newPassword: string; confirmPassword?: string | null }>({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const [isLengthMet, setIsLengthMet] = useState(false);
  const [isSpecialCharMet, setIsSpecialCharMet] = useState(false);

  const confirmPassword = watch("confirmPassword");
  const newPassword = watch("newPassword");

  useEffect(() => {
    if (newPassword) {
      setIsLengthMet(newPassword.length >= 8);
      setIsSpecialCharMet(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(newPassword));
    } else if (confirmPassword) {
      setIsLengthMet(confirmPassword.length >= 8);
      setIsSpecialCharMet(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(confirmPassword));
    } else {
      setIsLengthMet(false);
      setIsSpecialCharMet(false);
    }
  }, [newPassword, confirmPassword]);

  const onSubmit = async (data: { newPassword: string; confirmPassword?: string | null }) => {
    const email = localStorage.getItem("email") || "";
    const code = localStorage.getItem("code") || "";
    dispatch(createNewPassword({ email, code, password: data.newPassword })).then((res: any) => {
      if (res?.success) {
        localStorage.removeItem("email");
        localStorage.removeItem("code");
        localStorage.removeItem("accessToken");
        Alert("success", "Password reset successfully", "Success");
        navigate(ROUTES.signIn);
      }
    });
  };

  return (
    <AuthLayout>
      <div className='grow px-5 min-h-screen  flex flex-col justify-center py-6'>
        <div className='h-fit sm:w-[464px] flex flex-col items-center mx-auto gap-6'>
          <div className='mb-6'>
            <div className='flex justify-center'>
              <BigLockIcon className='w-16 h-16' />
            </div>

            <Typography className='my-6 text-center' variant={TypographyVariants.H1}>
              Set <span className='text-[#2c1a497a]'>new password</span>
            </Typography>
            <Typography className='text-center text-[#2C1A49]'>
              Your new password must be different <br/> from your previously used ones.
            </Typography>
          </div>

          <form className='w-full' noValidate onSubmit={handleSubmit(onSubmit)}>
            <div className='space-y-6'>
              <Input
                type='password'
                {...register("newPassword", { required: true })}
                icon={LockIcon}
                label='Password'
                placeholder='New password'
                error={errors?.newPassword}
              />
              <Input
                type='password'
                {...register("confirmPassword", { required: true })}
                icon={LockIcon}
                label='Confirm Password'
                placeholder='Confirm password'
                error={errors?.confirmPassword}
              />

              <div className='flex flex-col gap-2'>
                <Typography
                  variant={TypographyVariants.MD_MEDIUM}
                  className={cn("text-[#9A91A8] flex items-center gap-x-3", { "text-[#1F2228]": isLengthMet })}
                >
                  <CheckIcon key={"check-1"} fill={isLengthMet ? "#6A5EEE" : "#A6A6A6"} className='pointer-events-none w-6 h-6' />
                  Must be at least 8 characters
                </Typography>
                <Typography
                  variant={TypographyVariants.MD_MEDIUM}
                  className={cn("text-[#9A91A8] flex items-center gap-x-3", { "text-[#1F2228]": isSpecialCharMet })}
                >
                  <CheckIcon fill={isSpecialCharMet ? "#6A5EEE" : "#A6A6A6"} key={"check-2"} className='pointer-events-none w-6 h-6' />
                  Must contain one special character
                </Typography>
              </div>
            </div>
            <Button className='mt-10' disabled={!!errors.confirmPassword || !!errors.newPassword} type='submit'>
              Reset Password
            </Button>
          </form>

          <div className='flex items-center justify-center text-sm text-center text-black hover:cursor-pointer'>
            <Link
              className='flex items-center justify-center underline-offset-1 gap-x-1 text-sm font-medium text-[#1F2228] hover:underline'
              to={ROUTES.signIn}
            >
              <BackButton />
              Back to Log In
            </Link>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}
