import { ReactNode } from "react";

import { IChannelData } from "api/budget/types";

import Alert from "shared/components/toasts";

type OriginalCustomItem = {
  child: string;
  parent: string;
  project_channel_id: string;
  channel_id: number;
};

type ConvertedItem = {
  id: string;
  content: string;
  channel_id: number;
};

type ConvertedGroup = {
  id: string;
  content: string;
  items: ConvertedItem[];
};

type MappingObject = {
  project_channel_id: number;
  channel_id: number;
  child: string;
};

type CategoryList = {
  mapping: MappingObject[];
};

type CategoryItem = {
  id: string;
  content: string;
  channel_id: number;
};

type ConvertedCategoryListInterim = {
  [project_channel_id: string]: CategoryItem[];
};

// Define the theme type and themeMappings
type Theme = {
  color: string;
  icon: ReactNode;
};

// Define the type for each item in a container
export interface Item {
  id: string;
  content: string;
  channel_id: number;
}

export interface Container {
  id: string;
  content: string;
  items: Item[];
}


export function convertCategoriesToDesiredFormat(categoriesList: CategoryList, channels: any) {
  const convertedCategoryList: ConvertedCategoryListInterim = {};
  convertedCategoryList["0"] = []; // un-assigned category
  Object.entries(channels).forEach(([key, value]: any) => {
    convertedCategoryList[value.channel_id] = [];
  });
  console.log("Converted Category List (0)", convertedCategoryList);

  // First pass: Build the convertedCategoryList dictionary
  categoriesList.mapping.forEach((item: MappingObject) => {
    const { channel_id, child, project_channel_id } = item;

    if (!convertedCategoryList[project_channel_id]) {
      convertedCategoryList[project_channel_id] = [];
    }

    convertedCategoryList[project_channel_id].push({ id: child, content: child, channel_id: channel_id });
  });
  console.log("Converted Category List (1)", convertedCategoryList);

  console.log("channels", channels);

  // Second pass: Convert the dictionary to the final format
  const convertedData = Object.keys(convertedCategoryList)
    .map(project_channel_id => ({
      id: project_channel_id,
      content: project_channel_id,
      display_order: channels[project_channel_id]?.display_order || 0,
      items: convertedCategoryList[project_channel_id],
    }))
    .sort((a, b) => a.display_order - b.display_order);
  console.log("convertedData", convertedData);

  return convertedData;
}

export const makeEmptyContainerForChannel = (channel: IChannelData): Container => {
  return {
    id: String(channel.channel_id),
    content: channel.name,
    items: [],
  };
};

export const getContainerFromProjectChannels = (channel: IChannelData, categories: Container[]): Container => {
  const el = categories?.find(item => item.id === String(channel.channel_id)) || makeEmptyContainerForChannel(channel);
  console.log("getContainerFromProjectChannels", el);
  return el;
};

export function convertCategoriesToOriginalFormat(convertedData: ConvertedGroup[]): {
  originalCustomData: OriginalCustomItem[];
  originalDataNameOnly: string[];
} {
  const originalCustomData: OriginalCustomItem[] = [];
  const originalDataNameOnly: string[] = [];
  convertedData.forEach(group => {
    group.items.forEach(item => {
      if (group?.id === "0") return;
      originalDataNameOnly.push(item.id);
      originalCustomData.push({
        child: item.content,
        parent: group.content, // Assuming 'parent' corresponds to 'content'
        project_channel_id: group.id,
        channel_id: item.channel_id, // Convert id back to integer
      });
    });
  });

  return { originalCustomData, originalDataNameOnly };
}

export const isCategoriesValid = (originalDataNameOnly: string[]) => {
  if (originalDataNameOnly?.length < 1) {
    Alert("info", "Please add a marketing channel to one of the channel categories before continuing", "No channels assigned");
    return false;
  }

  return true;
};

export interface Row {
  id: string;
  parentId?: string;
  name: string;
  project_channel_id?: number;
  expanded?: boolean;
  isParent?: boolean;
  [key: string]: any; // To capture dynamic time period properties
}

interface TimePeriods {
  [key: string]: number;
}

interface MarketingChannel {
  channel_id: string;
  name: string;
  timePeriods: TimePeriods;
}

interface ParentRow {
  project_channel_id: string;
  name: string;
  expanded: boolean;
  isParent: boolean;
  marketing_channels: MarketingChannel[];
}

export function convertBudgetReviewTableData(rows: Row[]): ParentRow[] {
  const result: ParentRow[] = [];
  const parentMap: { [key: string]: ParentRow } = {};
  console.log("Rows--------", rows);
  // First pass: Collect all parent rows
  rows.forEach(row => {
    if (row.isParent) {
      parentMap[row.id] = {
        project_channel_id: row.id,
        name: row.name,
        expanded: row.expanded || false,
        isParent: row.isParent || false,
        marketing_channels: [],
      };
      result.push(parentMap[row.id]);
    }
  });

  // Second pass: Collect all child rows and attach them to their respective parents
  rows.forEach(row => {
    if (!row.isParent && row.parentId) {
      const timePeriods: TimePeriods = {};
      // Collect time period data
      for (const key in row) {
        if (key !== "id" && key !== "parentId" && key !== "name") {
          timePeriods[key] = row[key];
        }
      }
      if (parentMap[row.parentId]) {
        parentMap[row.parentId].marketing_channels.push({
          channel_id: row.channel_id,
          name: row.name,
          timePeriods,
        });
      }
    }
  });

  return result;
}

export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const removeDashFromString = (x:string) =>{
  return x.replace(/-/g, "");
}