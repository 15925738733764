import cn from "classnames";

import { TypographyOption } from "interfaces/shared/typograpgy.interfaces";

export enum TypographyVariants {
  LARGE = "large",
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
  H5 = "h5",
  H6 = "h6",
  H7 = "h7",
  BODY_LARGE = "body_large",
  BODY_MEDIUM = "body_medium",
  BODY_SMALL = "body_small",
  LG_SEMIBOLD = "lg_semibold",
  LG_MEDIUM = "lg_medium",
  LG_REGULAR = "lg_regular",
  MD_BOLD = "md_bold",
  MD_SEMIBOLD = "md_semibold",
  MD_MEDIUM = "md_medium",
  MD_REGULAR = "md_regular",
  SM_BOLD = "sm_bold",
  SM_SEMIBOLD = "sm_semibold",
  SM_MEDIUM = "sm_medium",
  SM_REGULAR = "sm_regular",
  XS_MEDIUM = "xs_medium",
}

const LG_SHARED_CLASSNAME = "text-base leading-5";
const MD_SHARED_CLASSNAME = "text-sm leading-[18px] text-[#2E323A]";
const SM_SHARED_CLASSNAME = "text-xs leading-[15px]";

export const TypographyOptions: Record<TypographyVariants, TypographyOption> = {
  [TypographyVariants.LARGE]: { tag: "h1", className: "text-4xl font-semibold text-[#1F2228]" },
  [TypographyVariants.H1]: { tag: "h1", className: "text-4xl font-bold leading-[50px]" },
  [TypographyVariants.H2]: { tag: "h2", className: "text-[32px] font-bold leading-10" },
  [TypographyVariants.H3]: { tag: "h3", className: "text-[28px] font-semibold leading-[35px]" },
  [TypographyVariants.H4]: { tag: "h4", className: "text-2xl font-semibold leading-[30px]" },
  [TypographyVariants.H5]: { tag: "h5", className: "text-xl font-bold leading-[25px]" },
  [TypographyVariants.H6]: { tag: "h6", className: "text-lg font-bold leading-[23px]" },
  [TypographyVariants.H7]: { tag: "h6", className: "text-base font-bold leading-5" },
  [TypographyVariants.BODY_LARGE]: { className: "text-base font-medium leading-[170%]" },
  [TypographyVariants.BODY_MEDIUM]: { className: "text-sm font-medium leading-[160%]" },
  [TypographyVariants.BODY_SMALL]: { className: "text-xs font-normal leading-[155%]" },
  [TypographyVariants.LG_SEMIBOLD]: { className: cn(LG_SHARED_CLASSNAME, "font-semibold") },
  [TypographyVariants.LG_MEDIUM]: { className: cn(LG_SHARED_CLASSNAME, "font-medium") },
  [TypographyVariants.LG_REGULAR]: { className: cn(LG_SHARED_CLASSNAME, "font-normal") },
  [TypographyVariants.MD_BOLD]: { className: cn(MD_SHARED_CLASSNAME, "font-bold") },
  [TypographyVariants.MD_SEMIBOLD]: { className: cn(MD_SHARED_CLASSNAME, "font-semibold") },
  [TypographyVariants.MD_MEDIUM]: { className: cn(MD_SHARED_CLASSNAME, "font-medium") },
  [TypographyVariants.MD_REGULAR]: { className: cn(MD_SHARED_CLASSNAME, "font-norma") },
  [TypographyVariants.SM_BOLD]: { className: cn(SM_SHARED_CLASSNAME, "font-bold") },
  [TypographyVariants.SM_SEMIBOLD]: { className: cn(SM_SHARED_CLASSNAME, "font-semibold") },
  [TypographyVariants.SM_MEDIUM]: { className: cn(SM_SHARED_CLASSNAME, "font-medium") },
  [TypographyVariants.SM_REGULAR]: { className: cn(SM_SHARED_CLASSNAME, "font-normal") },
  [TypographyVariants.XS_MEDIUM]: { className: "text-[10px] font-medium leading-[13px]" },
};
