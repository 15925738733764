import React from "react";
import { components, DropdownIndicatorProps } from "react-select";

import { Option } from "interfaces/shared/form-elements.interfaces";

import { ReactComponent as ChevronIcon } from "assets/icons/chevron-down.svg";

export const DropdownIndicator = (props: DropdownIndicatorProps<Option, false>) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronIcon className='w-4 h-4' />
    </components.DropdownIndicator>
  );
};
