import { FC } from "react";

type Props = { className?: string; width?: string; height?: string; color?: string };

const InfluencerIcon: FC<Props> = ({ className, width = "22", height = "22", color = "#F185E9" }) => {
  return (
    <svg
      width={width}
      height={height}
      color={color}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M12.5855 3.21751L14.1988 6.44418C14.4188 6.89335 15.0055 7.32418 15.5005 7.40668L18.4247 7.89251C20.2947 8.20418 20.7347 9.56085 19.3872 10.8992L17.1138 13.1725C16.7288 13.5575 16.518 14.3 16.6372 14.8317L17.288 17.6458C17.8013 19.8733 16.6188 20.735 14.648 19.5708L11.9072 17.9483C11.4122 17.655 10.5963 17.655 10.0922 17.9483L7.35134 19.5708C5.38967 20.735 4.19801 19.8642 4.71134 17.6458L5.36217 14.8317C5.48134 14.3 5.27051 13.5575 4.88551 13.1725L2.61217 10.8992C1.27384 9.56085 1.70467 8.20418 3.57467 7.89251L6.49884 7.40668C6.98467 7.32418 7.57134 6.89335 7.79134 6.44418L9.40467 3.21751C10.2847 1.46668 11.7147 1.46668 12.5855 3.21751Z'
        stroke='currentColor'
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default InfluencerIcon;
