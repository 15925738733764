import api from "..";

import { IUserData } from "./types";

const BASE_API = process.env.REACT_APP_API_URL;

export const getUserData = async (): Promise<IUserData> => {
  const data: IUserData = await api(`${BASE_API}/users/my-data`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  return data;
};
