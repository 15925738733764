import { createSlice } from "@reduxjs/toolkit";

import { PersonalizationData, PersonalizationOptionsList } from "interfaces/personalization.interfaces";

import { getMyPersonalizationDataReducer, getPersonalizationOptionsListReducer } from "../reducers/personalization";

export interface PersonalizationState {
  data: PersonalizationData | null;
  options: PersonalizationOptionsList | null;
}

const initialState: PersonalizationState = {
  data: null,
  options: null,
};

export const personalizationSlice = createSlice({
  name: "personalization",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getMyPersonalizationDataReducer(builder);
    getPersonalizationOptionsListReducer(builder);
  },
});

export const personalizationReducer = personalizationSlice.reducer;
