export enum ModalVariants {
  PRIMARY = "primary",
  NARROW = "narrow",
  SMALL = "small",
  FIT_CONTENT = "fit_content"
}

export const ModalStyles: Record<ModalVariants, string> = {
  [ModalVariants.PRIMARY]: "max-w-[800px]",
  [ModalVariants.NARROW]: "max-w-xl",
  [ModalVariants.SMALL]: "max-w-[412px]",
  [ModalVariants.FIT_CONTENT]: "px-20 w-auto"
};
