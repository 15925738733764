import { ChangeEvent, useContext } from "react";
import { useDispatch } from "react-redux";

import { openPopup } from "storage/slices/popup-slice";

import Alert from "shared/components/toasts";

import { ConnectSourceContext } from "pages/budget/ConnectDataSource";

import { ReactComponent as CSVIcon } from "assets/icons/csv.svg";
import { ReactComponent as UploadIcon } from "assets/icons/fileUpload.svg";


const UploadCsv = ()=>{
  const dispatch = useDispatch()
    const {handleUploadFile, fileName, resetData} = useContext(ConnectSourceContext)
    const validFile = (file: File)=>{
      let valid = false
      if(file?.type === "text/csv"){
        valid = true
      }
      if(!valid) Alert("error","You can only upload a CSV file.","Invalid File")
      return valid
    }
    const handleUpload = (e:ChangeEvent<HTMLInputElement>)=>{
      const files = (e.target as HTMLInputElement).files as any;
      if (files?.length === 0) return; // Simple Existence Validation
      if(!validFile(files?.[0])) return
      handleUploadFile(files?.[0])
    }
    
    return <ol className='flex list-decimal ml-6 flex-col gap-4 text-base font-bold text-[#2E323A] leading-default '>
    <li>Upload the file</li>
    <input type='file' accept='.csv' className='hidden' onChange={handleUpload} id='csv-input' />
    {/* <button
      className='flex items-center gap-[10px] text-sm font-semibold text-[#1F2228] h-9 rounded-lg px-[42px] py-2 bg-[#FCFCFC] border-[1px] border-[#D5D1F7]'
      onClick={() => {
        document.getElementById("csv-input")?.click();
      }}
    >
      <UploadIcon />
      Upload
    </button> */}
    
        {!fileName ? (
          <button
            className='flex w-fit items-center gap-[10px] text-sm font-semibold text-[#1F2228] h-9 rounded-lg px-[42px] py-2 bg-[#FCFCFC] border-[1px] border-[#D5D1F7]'
            onClick={() => {
              document.getElementById("csv-input")?.click();
            }}
          >
            <UploadIcon />
            Upload
          </button>
        ) : (
          <div className="flex row items-center justify-between w-100 flex-wrap gap-4">
            <div className='flex row items-center'>
              <div className='p-[10px] border-[0.714px] border-[#DEE9FF] rounded-[11.429px]'>
                <CSVIcon className='h-4 w-4' />
              </div>
              <p className='underline underline-offset-4 text-sm font-normal ml-3'>{fileName}</p>
            </div>
            <button className="p-4 rounded-xl bg-purpleP0" onClick={()=>{dispatch(openPopup("CreateBudget"))}}>
                <p className="text-sm font-medium">Click here to upload a new budget file</p>
                <p className="text-sm font-semibold underline underline-offset-4 text-purple text-left">Change file</p>
            </button>
          </div>
        )}
      
  </ol>

  }

  export default UploadCsv
