import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

import { ROUTES } from "constants/routes.constants";
import { TypographyVariants } from "constants/shared/typography.constants";

import { Typography } from "shared/components/Typography";

import { ReactComponent as LogoIcon } from "assets/icons/full-logo.svg";
import SignUpJpg from "assets/png/sign-up.png";

interface Props {
  children: ReactNode;
}

export default function AuthPage({ children }: Props) {
  return (
    <main className='flex min-h-full'>
      <div className='hidden md:flex md:w-1/2 flex-col'>
        <div className='flex flex-col bg-white w-full h-full rounded-r-[36px] relative'>
          <div className='py-4 px-7 h-fit cursor-pointer'>
            <Link to={ROUTES.signIn}>
              <LogoIcon />
            </Link>
          </div>
          <div className='px-3 flex flex-col justify-center items-center h-[calc(100vh-64px)]'>
            <div className='flex justify-center h-fit pb-3'>
              <img className='max-w-[410px] max-h-[285px]' src={SignUpJpg} alt='sign-up-img' />
            </div>

            <Typography variant={TypographyVariants.H4}>
              Drive business value with AI-powered
              <br /> <span className='text-[#6A5EEE]'>financial and operational intelligence </span>
            </Typography>
          </div>
        </div>
      </div>
      {children}
    </main>
  );
}
