import cn from "classnames";
import React from "react";

import { getMembershipsPlansDetails } from "constants/memberships.constants";

import { useAppSelector } from "hooks/appHooks";
import { useLoadMembershipsPlans } from "hooks/subscriptions/useLoadMembershipsPlans";

import { selectIsMembershipPlanExists } from "storage/selectors/user";

import { MembershipCard } from "./MembershipCard";

interface Props {
  isYearly?: boolean;
  isSimplified?: boolean;
}

export const MembershipCardsContainer = ({ isYearly, isSimplified }: Props) => {
  const { membershipsPlans } = useLoadMembershipsPlans();
  const isMembershipPlanExists = useAppSelector(selectIsMembershipPlanExists);

  return (
    <div
      className={cn("flex items-end space-x-6 xl:space-x-8 w-80 min-w-full", { "space-x-4 xl:space-x-4": isSimplified })}
    >
      {membershipsPlans?.length
        ? membershipsPlans.map((plan, index) => (
            <MembershipCard
              key={plan.plan_id}
              isYearly={isYearly}
              isSimplified={isSimplified}
              isMembershipPlanExists={isMembershipPlanExists}
              isActive={index === 1}
              newPrice={index === 1 ? "75" : undefined}
              {...plan}
              {...getMembershipsPlansDetails(isMembershipPlanExists, isSimplified)[plan.name_tech]}
            />
          ))
        : null}
    </div>
  );
};
