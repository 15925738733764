import React from "react";

import { StringOrNull } from "interfaces/shared-types.interfaces";

import { CopyButton } from "shared/components/buttons/CopyButton";
import TextEditor from "shared/components/Editor/EditorComponent";
import { InfoSectionWithIcon } from "shared/components/InfoSectionWithIcon";

import { ReactComponent as GoalIcon } from "assets/icons/goal.svg";
import loaderGif from "assets/icons/loader.gif";

const CONTENT_CLASSNAME =
  "p-6 text-[#464A53] border-none text-sm [&_h4]:text-xl [&_h4]:text-black [&_h4]:font-bold [&_h4]:mb-4 [&_h5]:text-black [&_h5]:font-bold [&_h5]:mb-2 [&_ol]:mb-5 [&_ul]:mb-4 [&_ol]:list-disc [&_ol]:list-inside [&_ol]:space-y-3 [&_ul]:space-y-3 [&_p]:pb-5";

const CUSTOM_CLASSNAME =
  "p-6 text-[#464A53] border-none text-sm [&_h2]:text-xl [&_h2]:text-black [&_h2]:font-bold [&_h2]:mb-4 [&_h5]:text-black [&_h5]:font-bold [&_h5]:mb-2 [&_ol]:mb-5 [&_ul]:mb-4 [&_ol]:list-disc [&_ol]:list-inside [&_ol]:space-y-3 [&_ul]:space-y-3 [&_p]:pb-5";

interface Props {
  isLoading?: boolean;
  content?: StringOrNull;
  hidden?: boolean;
}

export const PerformancePlanContentWrapper = ({ isLoading, content, hidden }: Props) => {
  if (hidden) return null;

  if (isLoading)
    return (
      <InfoSectionWithIcon
        icon={<img className='w-10 h-10' src={loaderGif} alt='loader' />}
        title='Generating your content'
        description='This may take a few moments'
      />
    );

  if (!content)
    return (
      <InfoSectionWithIcon
        icon={<GoalIcon />}
        title='It looks like this workflow is empty.'
        description='Begin by entering your prompt to create a new workflow. This will help us tailor the steps to your specific needs.'
      />
    );

  return (
    <div>
      <CopyButton contentToCopy={content} />
      {/* <div className={CONTENT_CLASSNAME} dangerouslySetInnerHTML={{ __html: content.replaceAll("ul", "ol") }}></div> */}
      <TextEditor initialValue={content} />
    </div>
  );
};
