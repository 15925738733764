import React from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  error?: string;
  className?: string;
}

export const ErrorMessage = ({ error, className }: Props) => {
  if (!error) return null;

  return <p className={twMerge("mt-1 text-xs font-medium text-[#E36C74]", className)}>{error}</p>;
};
