import React from "react";

import { TypographyVariants } from "constants/shared/typography.constants";

import { getGreetingTitle } from "utils/titles.utils";

import { useAppSelector } from "hooks/appHooks";

import { Typography } from "shared/components/Typography";

export const DashboardGreeting = () => {
  const userName = useAppSelector(state => state.auth?.user?.name);

  if (!userName) return null;

  return (
    <div>
      <Typography className='mb-8' tag='h2' variant={TypographyVariants.H3}>
        {getGreetingTitle(userName)}
      </Typography>
    </div>
  );
};
