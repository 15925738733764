import { ChangeEvent, useContext } from "react";
import { useDispatch } from "react-redux";

import { openPopup } from "storage/slices/popup-slice";

import Alert from "shared/components/toasts";

import { ConnectSourceContext } from "pages/budget/ConnectDataSource";

import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-down.svg";
import { ReactComponent as ExcelSheetIcon } from "assets/icons/excelSheet.svg";
import { ReactComponent as UploadIcon } from "assets/icons/fileUpload.svg";

const UploadExcel = () => {
  const dispatch = useDispatch()
  const { handleUploadFile, selectedWorksheet, changeWorksheet, worksheetList, fileName, resetData } = useContext(ConnectSourceContext);
  const validFile = (file: File)=>{
    let valid = false
    if(file?.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ){
      valid = true
    }
    if(file?.type === "application/vnd.ms-excel"){
      valid = true
    }
    if(!valid) Alert("error","You can only upload an Excel file.","Invalid File")
    return valid
  }
  const handleUpload = (e:ChangeEvent<HTMLInputElement>)=>{
    const files = (e.target as HTMLInputElement).files as any;
    if (files?.length === 0) return; // Simple Existence Validation
    if(!validFile(files?.[0])) return
    handleUploadFile(files?.[0])
  }
  
  return (
    <ol className='flex list-decimal  ml-6 flex-col gap-4 text-base font-bold text-[#2E323A] leading-default'>
      <li>Upload the file</li>
      <input type='file' accept='.xlsx' className='hidden' onChange={handleUpload} id='excel-input' />
      <div>
        {!fileName ? (
          <button
            className='flex w-fit items-center gap-[10px] text-sm font-semibold text-[#1F2228] h-9 rounded-lg px-[42px] py-2 bg-[#FCFCFC] border-[1px] border-[#D5D1F7]'
            onClick={() => {
              document.getElementById("excel-input")?.click();
            }}
          >
            <UploadIcon />
            Upload
          </button>
        ) : (
          <div className="flex row items-center justify-between flex-wrap gap-4">
            <div className='flex row items-center'>
              <div className='p-[10px] border-[0.714px] border-[#DEE9FF] rounded-[11.429px]'>
                <ExcelSheetIcon className='h-4 w-4' />
              </div>
              <p className='underline underline-offset-4 text-sm font-normal ml-3'>{fileName}</p>
            </div>
            <button className="p-4 rounded-xl bg-purpleP0" onClick={()=>{dispatch(openPopup("CreateBudget"))}}>
                <p className="text-sm font-medium">Click here to upload a new budget file</p>
                <p className="text-sm font-semibold underline underline-offset-4 text-purple text-left">Change file</p>
            </button>
          </div>
        )}
      </div>
      <li className='mt-4'>Select the worksheet</li>
      <div className=' w-full relative'>
        <select
          className='custom-select w-full  text-sm font-medium leading-default text-[#9EA2AD] py-[7px] px-[14px] outline-none mr-6 border-[1px] border-[#E9EAEC] rounded-lg'
          aria-placeholder='Select a  worksheets'
          name=''
          id=''
          value={selectedWorksheet ? selectedWorksheet : ""}
          onChange={e => {
            changeWorksheet(e.target.value);
          }}
        >
          <option value='' disabled>
            Select a worksheet
          </option>
          {worksheetList.map((item: string) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </select>
        <ArrowDownIcon className='absolute right-[14px] top-[10px]' />
      </div>
    </ol>
  );
};
export default UploadExcel;
