import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { FULL_DATE_FORMAT_WITH_COMMA } from "constants/date-formats.constants";

import { WorkflowListItem } from "interfaces/workflows.interfaces";

import { getPerformancePlanUrlById } from "utils/url.utils";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import { useBoolean } from "hooks/useBoolean";
import { useSorting } from "hooks/useSorting";
import { useString } from "hooks/useString";

import { getAllWorkflows } from "storage/actions/workflows";
import { selectIsFreemiumPlan } from "storage/selectors/user";
import { selectWorkflows } from "storage/selectors/workflows";
import { removeSelectedWorkflow } from "storage/slices/workflows-slice";

import { Box } from "shared/components/Box";
import { Button } from "shared/components/buttons/Button";
import Checkbox from "shared/components/checkbox/Checkbox";
import { ColoredLabel } from "shared/components/ColoredLabel";
import { SearchForm } from "shared/components/SearchForm";
import { SortDropdown } from "shared/components/SortDropdown";
import { Table } from "shared/components/table/Table";

import { ButtonVariants } from "../../../constants/shared/button.constants";

interface Props {
  handleOpenCreateModal: VoidFunction;
  hidden?: boolean;
}

export const AllWorkflows = ({ handleOpenCreateModal, hidden }: Props) => {
  const [isChecked, setIsChecked] = useBoolean();

  const sortingData = useSorting();
  const [search, setSearch] = useString();

  // TODO - uncomment
  // const [isDeleteModalOpen, _, handleOpenDeleteModal, handleCloseDeleteModal] = useBoolean();

  const dispatch = useAppDispatch();

  const workflows = useAppSelector(selectWorkflows);
  const isFreemiumPlan = useAppSelector(selectIsFreemiumPlan);

  const columnHelper = createColumnHelper<WorkflowListItem>();

  const columns = [
    // columnHelper.accessor("workflow_id", {
    //   header: "S. No.",
    //   cell: info => (
    //     <div className='flex items-center'>
    //       {isFreemiumPlan && (
    //         <Checkbox
    //           className='[&_p]:hidden'
    //           checked={isChecked}
    //           title={!isChecked}
    //           setChecked={setIsChecked}
    //           checkboxWidth={5}
    //           checkboxHeight={5}
    //         />
    //       )}
    //       <p className='ml-3'>{info.renderValue()}</p>
    //     </div>
    //   ),
    // }),
    columnHelper.accessor("name", {
      header: "Workflow Name",
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor("project_channel", {
      header: "Channels",
      cell: info => (
        <ColoredLabel
          backgroundColor={info.getValue()?.color?.light}
          color={info.getValue()?.color?.dark}
          content={info.getValue()?.name}
        />
      ),
    }),
    // columnHelper.accessor("date_created", {
    //   id: "last_modified",
    //   header: "Last modified",
    //   cell: info => dayjs(info.getValue()).format(FULL_DATE_FORMAT_WITH_COMMA),
    // }),
    columnHelper.accessor("date_created", {
      header: "Created",
      cell: info => dayjs(info.getValue()).format(FULL_DATE_FORMAT_WITH_COMMA),
    }),
    columnHelper.accessor("workflow_id", {
      id: "action",
      header: "Action",
      enableSorting: true,
      cell: info => (
        <Link className='rounded-xl text-center text-sm font-semibold text-[#766CE4]' to={getPerformancePlanUrlById(info.getValue())}>
          View
        </Link>
      ),
    }),
  ];

  const table = useReactTable({
    data: workflows || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    dispatch(removeSelectedWorkflow());
  }, []);

  useEffect(() => {
    const { sort_order, sort_by } = sortingData?.sorting;

    dispatch(getAllWorkflows({ sort_order, sort_by, search }));
  }, [dispatch, setSearch, search, sortingData?.sorting]);

  if (hidden) return null;

  return (
    <div className='px-6 py-4'>
      <div className='space-y-3'>
        <div className='flex items-center space-x-10'>
          <SearchForm placeholder='Search workflow' handleFilter={setSearch} />
          <Button className='w-fit px-[42px]' variant={ButtonVariants.MEDIUM} onClick={handleOpenCreateModal}>
            Create
          </Button>
        </div>

        <Box className='rounded-[20px]'>
          <div className='flex justify-between items-center'>
            <div className='flex items-center space-x-6 mb-4'>
              <h4 className='text-lg font-bold text-[#1F2228]'>Recent Workflows</h4>
              {/*TODO - uncomment*/}
              {/*<div className='h-[23px] w-px bg-[#E8E9F0]' />*/}
              {/*<button className='bg-white border border-[#E9EAEC] rounded-full p-2' onClick={handleOpenDeleteModal}>*/}
              {/*  <TrashIcon className='text-[#717684]' />*/}
              {/*</button>*/}
            </div>
            <SortDropdown {...sortingData} />
          </div>

          <Table table={table} />
        </Box>
      </div>
      {/*TODO - uncomment*/}
      {/*<Modal*/}
      {/*  isOpen={isDeleteModalOpen}*/}
      {/*  onClose={handleCloseDeleteModal}*/}
      {/*  title='Are you sure?'*/}
      {/*  description='Deleting this item is permanent and cannot be undone.'*/}
      {/*  variant={ModalVariants.NARROW}*/}
      {/*>*/}
      {/*  <ModalControlButtons submitButtonText='Yes, delete' handleSubmit={() => ""} handleCancel={handleCloseDeleteModal} />*/}
      {/*</Modal>*/}
    </div>
  );
};
