import { StringOrNull } from "../interfaces/shared-types.interfaces";

export const getMembershipPlanMonthlyPrice = ({
  priceMonthly,
  priceYearly,
  isYearly,
  ignoreNull,
  talkToSales,
}: {
  priceMonthly: StringOrNull;
  priceYearly: StringOrNull;
  isYearly?: boolean;
  ignoreNull?: boolean;
  talkToSales?: boolean;
}) => {
  if (talkToSales) return "Let's talk";

  const numericPrice = isYearly ? Number(priceYearly) / 12.0 : Number(priceMonthly);

  if (!numericPrice && ignoreNull) return null;

  const formattedNumber = new Intl.NumberFormat("en", { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(numericPrice);

  return numericPrice === 0 ? "Free" : `$${formattedNumber}`;
};

export const getMembershipYearOrMonthLabel = (isYearly?: boolean) => `${isYearly ? "month" : "month"}`;
