import { MembershipPlan } from "interfaces/memberships.interfaces";

import api from "../index";

// TODO - move api logic to classes

const BASE_API = process.env.REACT_APP_API_URL;

export const apiGetMembershipsPlans = async (): Promise<MembershipPlan[]> => {
  return api(`${BASE_API}/plans`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};
