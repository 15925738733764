export enum BoxVariants {
  NORMAL = "normal",
  MINIFIED = "minified",
  SIMPLIFIED = "simplified",
  SHADOWED = "shadowed",
}

export const BoxStyles: Record<BoxVariants, string> = {
  [BoxVariants.NORMAL]: "p-6 rounded-2xl",
  [BoxVariants.MINIFIED]: "py-8 px-16 max-w-[760px] mx-auto rounded-[32px] border border-[#E9EAEC]",
  [BoxVariants.SIMPLIFIED]: "p-4 rounded-none",
  [BoxVariants.SHADOWED]: "p-6 rounded-xl shadow-[0px_2px_15px_0px_#0000001A]",
};
