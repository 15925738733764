import dayjs from "dayjs";

import { StringOrNull } from "../interfaces/shared-types.interfaces";

export const checkIsViewAvailableBySubscription = ({
  currentBudgetDate,
  budgetDateFrom,
}: {
  currentBudgetDate: string;
  budgetDateFrom: StringOrNull;
}) => {
  const startOfCurrentMonth = dayjs(new Date()).startOf("month");

  const startOfCurrentBudgetMonth = dayjs(currentBudgetDate).startOf("month");

  const startOfBudgetFromMonth = dayjs(budgetDateFrom).startOf("month");

  const oneMoreLaterThanBudgetFrom = startOfBudgetFromMonth.add(1, "month");

  const isCurrentDateBeforeThanBudgetFrom = startOfCurrentMonth.isBefore(startOfBudgetFromMonth);

  // if currentDate before than budgetDateFrom and currentBudgetMonth isAfter(2 months)
  if (isCurrentDateBeforeThanBudgetFrom && startOfCurrentBudgetMonth.isAfter(oneMoreLaterThanBudgetFrom)) {
    return false;
  }

  const oneMonthLater = startOfCurrentMonth.add(1, "month");

  return !(!isCurrentDateBeforeThanBudgetFrom && startOfCurrentBudgetMonth.isAfter(oneMonthLater));
};
