import React from "react";

interface Props {
  classes?: string;
}

const BetaLabel = ({ classes }: Props) => {
  return (
    <div className={"py-[3px] px-2 rounded-[22px] bg-[#FFE493] font-semibold text-[9px] text-[#8F6E0A] tracking-[0.18px]" + " " + classes}>
      BETA
    </div>
  );
};

export default BetaLabel;
