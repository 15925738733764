import React from "react";
import { twMerge } from "tailwind-merge";

import { ColoredLabelStyles, ColoredLabelVariants } from "constants/shared/colored-label.constants";

interface Props {
  color: string;
  backgroundColor?: string;
  content: string;
  className?: string;
  variant?: ColoredLabelVariants;
}

export const ColoredLabel = ({ backgroundColor, color, content, className, variant = ColoredLabelVariants.PRIMARY }: Props) => {
  return (
    <div
      style={{ backgroundColor, color }}
      className={twMerge("w-fit select-none font-plusJakartaSans whitespace-nowrap", ColoredLabelStyles[variant], className)}
    >
      {content}
    </div>
  );
};
