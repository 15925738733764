import { getMaxLengthValidationRules, getMinLengthValidationRules } from "utils/validation.utils";

import { ONLY_ENGLISH_VALIDATION_PATTERN, REQUIRED_VALIDATION_RULE } from "./validation.constants";

export const DESCRIBE_PROJECT_DESCRIPTION_FIELD_VALIDATION = {
  pattern: ONLY_ENGLISH_VALIDATION_PATTERN,
  ...REQUIRED_VALIDATION_RULE,
  ...getMaxLengthValidationRules(500),
  ...getMinLengthValidationRules(120),
};
