import { Meta } from "interfaces/meta.interfaces";

import { removeEmptyValuesFromObject } from "./global.utils";

export const convertMetaToQueryParams = (data?: Meta) => {
  if (!Object.keys(data || {})?.length) return "";

  const preparedMeta = removeEmptyValuesFromObject(data || {});

  return new URLSearchParams(preparedMeta);
};
