// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { useAppSelector } from "hooks/appHooks";

import { generateUniqueId } from "utils";

const EditableCell = ({ value: initialValue, row: { index }, column: { id }, updateData, secondKey, disableEdit }: any) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (e: any) => {
    e.preventDefault();
    if (isNaN(e.target.value)) return;
    setValue(e.target.value);
    updateData(index, id, e.target.value);
  };

  return (
    <div className='font-medium text-sm'>
      {" "}
      $
      <input
        disabled={disableEdit}
        value={Math.floor(value)}
        type='tel'
        onChange={onChange}
        className={`w-fit max-w-16 font-medium outline-none text-sm disabled:bg-white ${secondKey === 5 ? "first-step" : ""} `}
      />
    </div>
  );
};

function getMonthsBetweenDates(start, end) {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  let startMonth = startDate.getMonth();
  const endMonth = endDate.getMonth();
  let startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();
  const result = [];

  while (startYear < endYear || (startYear === endYear && startMonth <= endMonth)) {
    result.push({ month: months[startMonth], year: startYear });
    startMonth++;
    if (startMonth > 11) {
      startMonth = 0;
      startYear++;
    }
  }
  return result;
}

const generateYearData = (startYear: number, endYear: number, currenChannelData: any, channel: string) => {
  const months = getMonthsBetweenDates(startYear, endYear);

  const data = { Channel: channel };

  for (let year = startYear; year <= endYear; year++) {
    months.forEach(({ month, year }, i) => {
      const currentEl = currenChannelData?.row_data?.find((item: any) => item?.month === month && item?.year === year);
      data[`${month}_${currentEl?.year || year}`] = currentEl?.value || 0;
    });
  }
  data["Total"] = 0;

  return data;
};

const generateColumns = (startYear: number, endYear: number) => {
  const months = getMonthsBetweenDates(startYear, endYear);
  const columns = [{ Header: "Channel", accessor: "Channel" }];
  for (let year = startYear; year <= endYear; year++) {
    months.forEach(({ month, year }) => {
      columns.push({ Header: `${month}`, accessor: `${month}_${year}` });
    });
  }

  columns.push({ Header: "Total", accessor: "Total" });
  return columns;
};

const BudgetPlanner = ({ disableEdit, setNewBudget, newBudget }: any) => {
  const location = useLocation();
  const projectData = useAppSelector(state => state.project.newProjectForm);
  const existProjectData = useAppSelector(state => state.project.project);
  const dataFromFile = useAppSelector(state => state.project.uploadedProject);
  const channelsFromState = useAppSelector(state => state.channels.channels);
  useEffect(() => {
    if (setNewBudget) {
      setNewBudget(projectData);
    }
  }, []);

  const [openProject, setOpenProject] = useState(existProjectData || dataFromFile || projectData);

  const startYear = new Date(openProject?.budget_date_from)?.toISOString().slice(0, 10);
  const endYear = new Date(openProject?.budget_date_to)?.toISOString().slice(0, 10);

  // TODO - check
  const channels = openProject?.channels?.map((channel: any, index: number) => {
    const currentChannel = channelsFromState?.find(el => el?.channel_id === channel?.channel_id);

    const { light, dark } = currentChannel?.color || {};

    const sharedValues = {
      style: {
        color: dark,
        backgroundColor: light,
      },
    };

    if (!channel?.name)
      return {
        id: channel,
        name: currentChannel?.name,
        ...sharedValues,
      };

    return {
      id: channel?.channel_id,
      name: channel?.name,
      ...sharedValues,
    };
  });

  function parseMonthYear(key: string) {
    const [month, year] = key.split("_");
    return { month, year: Number(year) };
  }

  const calculateTotal = (channelData: any) => {
    const res = Object.keys(channelData)
      .filter(key => key !== "Channel" && !key.includes("Total"))
      .reduce((total, key) => {
        const value = Number(channelData[key]);
        return isNaN(value) ? total : total + Math.floor(value);
      }, 0);
    return Math.floor(res);
  };

  const [data, setData] = useState(
    channels?.map(channel => {
      const currentData = openProject?.budget_data?.find(el => el.channel_id === channel.id);
      const channelData = generateYearData(startYear, endYear, currentData, channel.name);
      return {
        ...channelData,
        Total: calculateTotal(channelData),
      };
    }),
  );
  const columns = useMemo(() => generateColumns(startYear, endYear), []);

  const updateData = (rowIndex: any, columnId: any, value: any) => {
    const updatedBudget = { ...newBudget };
    let finalData: any;
    const { month, year } = parseMonthYear(columnId);
    const channelId = channels[rowIndex]?.id;

    let budgetDataItem = updatedBudget?.budget_data?.find((item: any) => item.channel_id === channelId);

    if (!budgetDataItem) {
      budgetDataItem = { channel_id: channelId, row_data: [] };
      if (Array.isArray(updatedBudget?.budget_data)) {
        updatedBudget.budget_data = [...updatedBudget?.budget_data, budgetDataItem];
      } else {
        updatedBudget.budget_data = [budgetDataItem];
      }
    }

    const rowDataItem = budgetDataItem.row_data.find((item: any) => item.month === month && item.year === Number(year));

    if (rowDataItem) {
      rowDataItem.value = Number(value);
    } else {
      budgetDataItem = {
        ...budgetDataItem,
        row_data: [...budgetDataItem.row_data, { month, year: Number(year), value: Number(value) }],
      };
    }
    const budgetDataItemIndex = updatedBudget.budget_data.findIndex((item: any) => item.channel_id === budgetDataItem.channel_id);

    if (budgetDataItemIndex !== -1) {
      let budgetDataItem = updatedBudget.budget_data[budgetDataItemIndex];
      const rowDataIndex = budgetDataItem.row_data.findIndex((item: any) => item.month === month && item.year === Number(year));

      if (rowDataIndex !== -1) {
        budgetDataItem.row_data[rowDataIndex].value = Number(value);
      } else {
        budgetDataItem = {
          ...budgetDataItem,
          row_data: [...budgetDataItem.row_data, { month, year: Number(year), value: Number(value) }],
        };
      }

      finalData = {
        ...updatedBudget,
        budget_data: updatedBudget.budget_data.map((item, index) =>
          item.channel_id === budgetDataItem.channel_id ? budgetDataItem : item,
        ),
      };
    }

    setNewBudget(prev => ({ ...prev, ...finalData }));
    setData((old: any) =>
      old.map((row: any, index: any) => {
        if (index === rowIndex) {
          const updatedRow = {
            ...old[rowIndex],
            [columnId]: Number(value),
          };
          updatedRow["Total"] = calculateTotal(updatedRow);
          return updatedRow;
        }
        return row;
      }),
    );
  };
  const isMarketingPaln = location.pathname.includes("budget-intelligence");

  return (
    <div
      className={`bg-[#fff] w-full ${
        isMarketingPaln ? "" : "border border-[#D7DBFE]"
      }  rounded-3xl overflow-auto [&::-webkit-scrollbar]:h-0 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300`}
    >
      <div className='w-full min-w-full inline-block align-middle'>
        <table className='min-w-full divide-y divide-[#EAECF0]'>
          <thead>
            <tr>
              {columns.map((item, i) => {
                return (
                  <th
                    key={generateUniqueId()}
                    scope='col'
                    className={`${i === 0 ? "pl-4 sticky-column-left z-50" : "pl-4"} ${item.Header === "Total" ? "sticky-column " : ""}`}
                    style={{
                      position: "sticky",
                      top: 0,
                      background: "#fff",
                      zIndex: 40,
                      left: i === 0 ? 0 : undefined,
                    }}
                  >
                    <div className={"pe-7 py-3 text-start flex items-center gap-x-1 text-sm font-bold text-[#475467]"}>{item.Header}</div>
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody className='divide-y divide-[#EAECF0] relative'>
            {data?.map((row, i) => (
              <tr key={i}>
                {Object.keys(row)?.map((key: any, j) => (
                  <td
                    className={`size-px whitespace-nowrap px-4 py-4 ${
                      key === "Channel" ? "sticky-column-left" : key === "Total" ? "sticky-column" : ""
                    }`}
                    key={j}
                  >
                    <div className='w-full flex items-center gap-x-3'>
                      <div className='w-fit'>
                        {key === "Channel" ? (
                          <div
                            style={channels?.find(channel => channel?.name === row[key])?.style}
                            className='text-xs font-bold rounded-2xl w-24 text-center px-2 py-1'
                          >
                            {row[key]}
                          </div>
                        ) : key === "Total" ? (
                          <span className='text-sm font-medium text-gray-800 min-w-[100px]'>${row[key]}</span>
                        ) : (
                          <EditableCell
                            value={row[key]}
                            row={{ index: i }}
                            column={{ id: key }}
                            updateData={updateData}
                            secondKey={j}
                            disableEdit={disableEdit}
                          />
                        )}
                      </div>
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BudgetPlanner;
