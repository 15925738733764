import { useEffect } from "react";

import { getProjectsList } from "storage/actions/project";

import { useAppDispatch, useAppSelector } from "../appHooks";

interface Props {
  ignoreLoading?: boolean;
}

export const useLoadBudgets = ({ ignoreLoading }: Props) => {
  const dispatch = useAppDispatch();

  const projectsList = useAppSelector(({ project }) => project?.projectList);

  useEffect(() => {
    if (ignoreLoading) return;

    dispatch(getProjectsList({ page: 1 }));
  }, [dispatch, ignoreLoading, projectsList?.length]);

  return { projectsList };
};
