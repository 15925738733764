import cn from "classnames";
import React, { ButtonHTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

import { ButtonStyles, ButtonVariants, SHARED_BUTTON_CLASSNAME } from "constants/shared/button.constants";

import { ButtonSharedProps } from "interfaces/shared/button.interfaces";

export type Props = ButtonHTMLAttributes<HTMLButtonElement> & ButtonSharedProps;

export const Button = ({
  className,
  children,
  isReversedIcon,
  iconClassName,
  variant = ButtonVariants.LARGE,
  icon: Icon,
  ...props
}: Props) => {
  return (
    <button
      className={twMerge(
        cn(
          SHARED_BUTTON_CLASSNAME,
          "disabled:bg-[#E9EAEC] disabled:text-[#C8CAD0] disabled:cursor-not-allowed before:disabled:border-[#E9EAEC]",
          { "flex-row-reverse [&_svg]:mr-0 [&_svg]:ml-2.5": isReversedIcon },
          ButtonStyles[variant],
          className,
        ),
      )}
      {...props}
    >
      {Icon && <Icon className={iconClassName} />}
      {children}
    </button>
  );
};
