import cn from "classnames";
import React from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  title: string;
  caption: string;
  selected?: boolean;
  handleSelected: VoidFunction;
  bgImage: string;
  disabled?: boolean;
  width?: string;
}

const CreateBudgetCardNew = ({ bgImage, width, title, caption, selected, handleSelected }: Props) => {
  const handleClickCard = () => {
    handleSelected();
  };
  return (
    <div
      className={twMerge(
        cn(
          "relative flex flex-col gap-2 justify-end border rounded-[25px] bg-[#FCFCFC] border-[#C8CAD0] p-6 bg-cover bg-no-repeat h-[242px] cursor-pointer",
          {
            "border-[#766CE4] bg-[#F9F9FF]": selected,
          },
        ),
      )}
      style={{
        backgroundImage: `url(${bgImage})`,
        boxShadow: `${selected ? "0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #ECEAFF" : ""}`,
        width,
      }}
      onClick={handleClickCard}
    >
      <h3 className='font-bold text-lg leading-default text-[#1F2228]'>{title}</h3>
      <p className='font-medium text-sm text-[#717684]'>{caption}</p>
    </div>
  );
};

export default CreateBudgetCardNew;
