import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  icon: ReactNode;
  title: string;
  description?: string;
  className?: string;
}

export const InfoSectionWithIcon = ({ icon, title, description, className }: Props) => {
  return (
    <div className={twMerge("flex flex-col justify-center items-center h-full", className)}>
      <div className='max-w-md space-y-4 flex flex-col items-center text-center'>
        {icon}
        <h5 className='text-[#5E636E] font-semibold'>{title}</h5>
        {description && <p className='text-[#9EA2AD] font-medium'>{description}</p>}
      </div>
    </div>
  );
};
