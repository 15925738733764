import React from "react";
import { useDispatch } from "react-redux";

import { openPopup } from "storage/slices/popup-slice";

import { UpgradeToProButton } from "shared/components/buttons/UpgradeToProButton";

import BlurOverlay from "../../BlurOverlay";

const MonthBudgetCardOverlay = () => {
  const dispatch = useDispatch();
  const handleOpenMembershipsModal = () => dispatch(openPopup("MembershipsModal"));

  return (
    <BlurOverlay className='flex flex-col justify-center items-center p-4' isShown>
      <h5 className='text-black1 text-base font-bold'>To unlock full workflows</h5>
      <p className='mt-2 text-sm text-grey2 text-center'>Click now to maximize your business potential.</p>

      <UpgradeToProButton handleClick={handleOpenMembershipsModal} />
    </BlurOverlay>
  );
};

export default MonthBudgetCardOverlay;
