export function formatEmail(email: string) {
  const [user, domain] = email.split("@");
  const hiddenUser = user.slice(0, 3) + "*".repeat(user.length - 3);
  return `${hiddenUser}@${domain}`;
}

export function openEmailProvider(email: string) {
  const [, domain] = email.split("@");
  window.open(`http://${domain}`, "_blank");
}

export function generateUniqueId() {
  return Date.now().toString(36) + Math.random().toString(36).slice(2);
}
