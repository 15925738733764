import { useEffect } from "react";

import { getMembershipsPlans } from "storage/actions/memberships";
import { selectMembershipsPlans } from "storage/selectors/memberships";

import { useAppDispatch, useAppSelector } from "../appHooks";

export const useLoadMembershipsPlans = () => {
  const dispatch = useAppDispatch();
  const membershipsPlans = useAppSelector(selectMembershipsPlans);

  useEffect(() => {
    if (membershipsPlans) return;

    dispatch(getMembershipsPlans());
  }, [dispatch, membershipsPlans]);

  return { membershipsPlans };
};
