import React, { useMemo } from "react";

import { IChannelData } from "api/budget/types";

import { ReactComponent as PlusIcon } from "assets/icons/addIcon.svg";

import { ColoredLabel } from "../ColoredLabel";
import { Dropdown } from "../dropdown/Dropdown";


interface Props {
  channels: IChannelData[];
}

export const ChannelsGroup = ({ channels }: Props) => {
  const { mainChannel, restChannels, restChannelsCount } = useMemo(() => {
    if (!channels?.length) return {};

    const mainChannel = channels[0];

    const restChannels = channels?.filter(({ channel_id }) => channel_id !== mainChannel?.channel_id);

    return { mainChannel, restChannels, restChannelsCount: restChannels?.length };
  }, [channels]);

  if (!channels?.length || !mainChannel) return null;

  return (
    <div className='flex items-center'>
      <div
        className='mr-1 py-[3px] px-3 rounded-full text-xs font-semibold'
        style={{ backgroundColor: mainChannel?.color?.light, color: mainChannel?.color?.dark }}
      >
        {mainChannel?.name}
      </div>
      {restChannelsCount ? (
        <Dropdown
          className='py-2.5 px-3 rounded-[9px]'
          customContent={
            <div className='flex items-center gap-2'>
              {restChannels?.map(({ channel_id, name, color: { light, dark } }) => (
                <ColoredLabel key={channel_id} color={dark} backgroundColor={light} content={name} />
              ))}
            </div>
          }
        >
          <div className='py-[3px] px-[7px] h-fit flex items-center gap-[2px] rounded-xl border border-[#E9EAEC] text-xs font-semibold text-[#717684]'>
            <PlusIcon />
            {restChannelsCount}
          </div>
        </Dropdown>
      ) : null}
    </div>
  );
};
