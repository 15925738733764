import { ROUTES } from "constants/routes.constants";

import { getAccessToken } from "utils/auth.utils";

import { setAuthData } from "storage/actions/user";
import { store } from "storage/store";

import { clearPendoBrowserData } from "../utils/pendo.utils";

type Options = {
  method?: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  headers?: HeadersInit;
  body?: BodyInit | null;
};

const BASE_API = process.env.REACT_APP_API_URL;

// TODO - use axios instead of fetch + create interceptors to handle 401(etc...) and send refreshToken

export async function refreshToken() {
  const token = localStorage.getItem("accessToken");
  const response = await fetch(`${BASE_API}/auth/refresh`, {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await response.json();
  if (data.accessToken) {
    localStorage.setItem("accessToken", data.accessToken);
  } else {
    localStorage.removeItem("accessToken");
    window.location.href = ROUTES.signIn;
  }
}

async function api<T>(url: string, options?: Options, queryParams?: Record<string, string>): Promise<T> {
  const token = localStorage.getItem("accessToken");

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    ...options?.headers,
  };
  const urlObj = new URL(url);

  if (queryParams) {
    Object.entries(queryParams).forEach(([key, value]) => {
      urlObj.searchParams.append(key, value);
    });
  }
  const response = await fetch(url, {
    ...options,
    headers,
    credentials: "include",
    cache: "no-store",
    mode: "cors",
  });

  if (response.ok) return response.json() as Promise<T>;

  const body = await response.json();
  const accessToken = getAccessToken();

  if (response.status === 401 && window.location.pathname !== ROUTES.signIn && accessToken) {
    await refreshToken();

    headers.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;

    const retryResponse = await fetch(url, { ...options, headers });

    if (!retryResponse?.ok) {
      throw new Error(retryResponse.statusText);
    }
    return retryResponse.json() as Promise<T>;
  }

  if (response.status === 401) {
    store.dispatch(setAuthData({ isAuthenticated: false, user: null }));
    clearPendoBrowserData();
  }

  throw new Error(body.errors[0] || response.statusText);
}

export default api;
