import { flexRender, Table as TableType } from "@tanstack/react-table";
import { twMerge } from "tailwind-merge";

import { NumberOrNull } from "interfaces/shared-types.interfaces";

import { TableData } from "./TableData";
import { TableHeader } from "./TableHeader";
import { TableRow } from "./TableRow";

interface Props<T> {
  table: TableType<T>;
  handleClickRow?: (value: T) => void;
  selectedId?: NumberOrNull;
  idKey?: string;
  className?: string;
  hover?: boolean;
}

export const Table = <T,>({ table, handleClickRow, selectedId, idKey, className, hover }: Props<T>) => {
  return (
    <div className={twMerge("overflow-x-auto w-full overflow-y-auto", className)}>
      <table className='whitespace-nowrap w-full border-separate border-spacing-0	'>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id} className='bg-[#F7F7F8]'>
              {headerGroup.headers.map(header => (
                <TableHeader key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHeader>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, ind) => {
            return <TableRow
              // isSelected={selectedId ? selectedId === row.getValue(String(idKey)) : undefined}
              isSelected={selectedId ? selectedId === (row?.original as any)?.project_id : undefined}
              key={row.id}
              hover={hover}
              onClick={!!handleClickRow ? () => handleClickRow(row?.original) : undefined}
            >
              {row.getVisibleCells().map(cell => (
                <TableData key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableData>
              ))}
            </TableRow>
          })}
        </tbody>
      </table>
    </div>
  );
};
