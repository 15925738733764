import qs from "query-string";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

import { capitalizeFirstLetter } from "utils/shared.utils";

import { getPaymentStatus } from "storage/actions/payments";

import Alert from "shared/components/toasts";

import { useAppDispatch } from "../appHooks";

export const usePaymentStatus = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { session_id: paymentSessionId, ...queryParams } = qs.parse(location?.search);
  useEffect(() => {
    if (!paymentSessionId) return;
    dispatch(getPaymentStatus({ sessionId: String(paymentSessionId) }))
      .unwrap()
      .then(({ status, message }) => {
        Alert(status, message, capitalizeFirstLetter(status));
      })
      .catch(err => Alert("error", err?.message, "Error"))
      .finally(() => navigate({ pathname: location.pathname, search: qs.stringify(queryParams) }));
  }, [paymentSessionId, dispatch]);

  return null;
};
