import { useContext } from "react";
import DataGrid from "react-data-grid";

import { ColumnData, Row } from "utils/sheets.utils";

import { ConnectSourceContext } from "pages/budget/ConnectDataSource";



// Data Sheet Table Preview
const DataSheetTablePV = () => {
  function rowHeight() {
    return 28;
  }
  const { row, column } = useContext(ConnectSourceContext);
  if(row.length === 0 || column.length === 0) return <></>
  return (
    <div className='px-6 py-4'>
      <div className='flex gap-6 p-6 rounded-[22px] bg-white'>
        <ol className='text-base font-bold text-[#2E323A] w-full min-h-96'>
          <li>Data Preview</li>
          <div className='pt-4 min-h-96'>
            <DataGrid
              className='min-h-600 rounded-lg'
              columns={[{ key: "index", name: "", width: 50, resizable: true },...column]}
              rows={row.map((row: Row, index: number) => ({
                ...row,
                index: index + 1,
              }))}
              rowHeight={rowHeight}
              renderers={{}}
             
            />
          </div>
        </ol>
      </div>
    </div>
  );
};

export default DataSheetTablePV;
