import { useAppSelector } from "hooks/appHooks";

import DataSheetTablePV from "./components/DataSheetTable_PV";
import UploadCsv from "./components/UploadCSV";
import UploadExcel from "./components/UploadExcel";
import UploadGoogle from "./components/UploadGoogle";

const ConnectSource = () => {
  const { dataType } = useAppSelector(state => state.uploadedBudget);

  return (
    <>
      <div className='px-6 py-4'>
        <div className='flex flex-col gap-6 p-6 rounded-[22px] bg-white'>
          {dataType === "CSV file" ? <UploadCsv /> : <></>}
          {dataType === "Excel file" ? <UploadExcel /> : <></>}
          {dataType === "Google Sheets" ? <UploadGoogle /> : <></>}
        </div>
      </div>

      <DataSheetTablePV />
    </>
  );
};

export default ConnectSource;
