import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { IChannelData, IGoalData } from "api/budget/types";
import { createProject } from "api/projects";

import { ROUTES } from "constants/routes.constants";

import { useAppDispatch } from "hooks/appHooks";

import { openPopup } from "storage/slices/popup-slice";

import Button from "shared/components/auth-buttons/Button";
import BudgetPlanner from "shared/components/budgetTable/BudgetTable";
import Alert from "shared/components/toasts";
import { DashboardLayout } from "shared/layouts/DashboardLayout";

import { ReactComponent as UploadFileIcon } from "assets/icons/uploadButtonIcon.svg";

export default function BudgetPlannerTable() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [newBudget, setNewBudget] = useState<any>({});

  const createBudgetHandler = async () => {
    const newBudgetCopy = { ...newBudget };
    newBudgetCopy.goals = newBudget?.goals?.map((item: IGoalData) => item);
    newBudgetCopy.channels = newBudget?.channels?.map((item: IChannelData) => item.channel_id);
    createProject(newBudgetCopy)
      .then(res => {
        if (res) {
          navigate(ROUTES.budget);
          Alert("success", "Project created successfully", "Success");
        }
      })
      .catch(err => {
        Alert("error", "Something went wrong", "Error");
      });
    return;
  };
  return (
    <DashboardLayout>
      <div className='h-full w-full p-6 bg-[#F7F9FB] flex flex-col '>
        <div className='flex flex-col items-center justify-center rounded-3xl border border-[#766CE4] bg-[#F1F0FC] gap-y-1 p-6'>
          <h1 className=' font-bold text-[32px]'>Verify your budget</h1>
          <p className='font-medium text-base text-[#9A91A8]'>
            Please take a time to verify your uploaded budget is correct to procede with strategy generation.
          </p>
        </div>
        <div className=' flex items-center justify-end w-full pt-6 pb-4'>
          <Button
            onClick={() => dispatch(openPopup("UploadNewBudget"))}
            classes='max-w-[156px] h-[50px] py-5 !text-[#1F2228] rounded-2xl bg-transparent text-[#1F2228] !border-2 !border-[#D7DBFE] hover:bg-transparent'
          >
            <UploadFileIcon /> Upload file
          </Button>
        </div>
        <BudgetPlanner setNewBudget={setNewBudget} newBudget={newBudget} />
        <div className='flex flex-col w-full flex-1 justify-end items-end'>
          <Button classes='max-w-[186px] py-5 px-[14px]' onClick={createBudgetHandler}>
            Continue
          </Button>
        </div>
      </div>
    </DashboardLayout>
  );
}
